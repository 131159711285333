import wNumb from "wnumb";

export const capitalize = (s: string) => (s ? s[0].toUpperCase() + s.slice(1).toLowerCase() : "");

export function currencyFormat(amt: any) {
  return wNumb({
    mark: ".",
    thousand: " ",
    prefix: "R ",
    decimals: 2,
  }).to(parseFloat(amt));
}

export function massFormat(amt: any) {
  return wNumb({
    mark: ".",
    thousand: " ",
    suffix: " kg",
    decimals: 1,
  }).to(parseFloat(amt));
}
