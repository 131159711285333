






































import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import Header from "@/data-table-header";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Veterinary extends Vue {
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.push({ name: "home" });
      },
    });
  }
}
