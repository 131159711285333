import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as RtcClient from "@gigalot/rtc-client";
import ReconnectingWebSocket from "reconnecting-websocket";
import Vuelidate from 'vuelidate';
import mitt from "mitt";
const VueGoogleMaps = require("vue2-google-maps");

Vue.config.productionTip = false;

let firebaseConfig: any;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAnm2wf7gkEjHhNi59Fv2-U8-isAZhD6UA",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:aab3748829fff1480e3695",
    measurementId: "G-EEN10ZXMDQ"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:0a7d14c3b4a3ff26137021",
    measurementId: "G-MTMD0GKERV"
  };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

type RtcEvents = {
  connectionEvent: "connected-cloud" | "connected-local" | "disconnected" | "connecting";
};
export const rtcEmitter = mitt<RtcEvents>();

export function initRtc() {
  try {
    if (!(store.state as any)?.user?.location?.guid) {
      console.error("Can not init WebRTC, no locationGuid found");
      return;
    }
    store.commit("rtcSignalling", "connecting");
    RtcClient.initialize({
      locationGuid: (store.state as any).user.location.guid,
      firebaseConfig: firebaseConfig,
      server: "office",
      //debug: true,
      debug: {
        messaging: {
          sending: false,
          receiving: false
        },
        signalling: false,
        iceCandidates: true,
        offers: false,
        answers: false
      },
      onConnectionChange: (connected, signalling, state) => {
        console.log("onConnectionChange");
        console.log("connected", connected);
        console.log("signalling", signalling);

        if (state === "connecting") {
          store.commit("rtcSignalling", "connecting");
        } else if (state === "connected-local") {
          store.commit("rtcSignalling", "local");
          rtcEmitter.emit("connectionEvent", "connected-local");
        } else if (state === "connected-cloud") {
          store.commit("rtcSignalling", "cloud");
          rtcEmitter.emit("connectionEvent", "connected-cloud");
        } else if (state === "disconnected") {
          store.commit("rtcSignalling", "disconnected");
          rtcEmitter.emit("connectionEvent", "disconnected");
        }
      },
      getJwt: () => store.dispatch("user/getOnlineIdToken", undefined, { root: true }),
      project: store.state.environment === "production" ? "gigalot-cloud" : "gigalot-testing",
      reconnect: {
        retryTime: 5000,
        maxRetryTime: 30 * 1000
      }
    });

    //RtcClient.findRoomId();
  } catch (err) {
    console.error(err);
  }
}



const webSocketConnectedToProxy = new ReconnectingWebSocket(`wss://${store.getters["urlHsl"]()}:9000/webSocketConnectedToProxy`);
webSocketConnectedToProxy.addEventListener("open", event => {
  console.log("webSocketConnectedToProxy open");
  if (store.state.connectedToProxy !== true) store.commit("connectedToProxy", true);
  startMessageInterval();

});
webSocketConnectedToProxy.addEventListener("message", event => {
  console.log("webSocketConnectedToProxy message: ", event.data);
  // const numInstances = parseInt(event.data);
  // if (!isNaN(numInstances)) {
  //   store.commit("isSingleInstance", numInstances === 1);
  // }
});
webSocketConnectedToProxy.addEventListener("close", event => {
  console.log("webSocketConnectedToProxy close");
  if (store.state.connectedToProxy !== false) store.commit("connectedToProxy", false);
  stopMessageInterval();
});

let t: any;

function startMessageInterval() {
  if (t) return;
  t = setInterval(() => {
    try {
      webSocketConnectedToProxy.send("x");
    } catch (err) {
      console.error("failed to send heartbeat message:", err);
    }
  }, 10 * 1000);
}

function stopMessageInterval() {
  clearInterval(t);
  t = undefined;
}



store.commit("rtcSignalling", "disconnected");
store.dispatch("user/addFirebaseCallback", () => {
  initRtc();
});

firebase.auth().onAuthStateChanged(function (user) {
  store.dispatch("user/firebaseOnAuthStateChanged", user);
});

store.commit("version");
//store.commit("download/queryNamesAndObjectStores");

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    mapId: "4796866cc28f5c9b"
    //libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.use(Vuelidate);