import { default as Vuex, Module } from "vuex";

type PopupMode = "ok" | "yesNo" | "wait";

class PopupState {
  [key: string]: any;
  visible: boolean = false;
  popupMessage: string = "";
  mode: PopupMode = "ok";
  yesAction: Function = () => { };
}

class Popup implements Module<PopupState, any> {
  namespaced = true;
  state: PopupState = new PopupState();
  mutations = {
    hide(state: PopupState) {
      state.visible = false;
      state.popupMessage = "";
      /*
      Deciding to keep yesAction after hiding because I found a case where I need to hide a yes no popup first and then run the yesAction.
      The yesAction may result in an OK popup being displayed, but the OK popup is not displayed if the yes no popup is still being displayed.
      The popup module could probably do with some queue system but this is a much easier fix for now.

      So no the v-dialog in App.vue first hides the popup and then runs the yesAction (it used to be the other way round).
      */
      //state.yesAction = () => {};
    },
    displayOk(state: PopupState, payload: string | { message: string; }) {
      let message = typeof payload === "string" ? payload : payload.message;
      state.visible = true;
      state.mode = "ok";
      state.popupMessage = message;
    },
    displayYesNo(state: PopupState, payload: { message: string; yesAction: Function; }) {
      state.visible = true;
      state.mode = "yesNo";
      state.popupMessage = payload.message;
      state.yesAction = payload.yesAction;
    },
    displayWait(state: PopupState, payload: { message: string; }) {
      let message = typeof payload === "string" ? payload : payload.message;
      state.visible = true;
      state.mode = "wait";
      state.popupMessage = message;
    }
  };
}

const popupDialogModule: Popup = new Popup();

export default popupDialogModule;
