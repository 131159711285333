import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import list from "./modules/list";
import popup from "./modules/popup";
import user from "./modules/user";
import scanner from "./modules/scanner";
import moment from "moment";
import "moment/locale/en-gb";
import * as packageJson from "../../package.json";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import { rtcEmitter } from "@/main";

const vuexMapFields = require("vuex-map-fields");
const getField = vuexMapFields.getField;
const updateField = vuexMapFields.updateField;
const createHelpers = vuexMapFields.createHelpers;

export const { mapFields, mapMultiRowFields } = createHelpers({
  getterType: "getUserLocationField",
  mutationType: "updateUserLocationField"
});

Vue.use(Vuex);

type EnvType = "production" | "staging" | "testing" | "undetermined";

let productionUrl: string = "https://office.gigalot.co.za";
let stagingUrl: string = "https://office.gigalot.systems";

//const dataChannelPromises: { [messageId: string]: { resolve: any; reject: any; }; } = {};
const connectionErrorPromises: Promise<void>[] = [];

async function graphQl(jwt: string, url: string, query: string, variables: any, useRtc: boolean, o?: { timeout?: number; }) {
  if (!useRtc) {
    let options: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + jwt },
      body: JSON.stringify({ query: query, variables: variables })
    };
    let response = await fetch(url, options);

    if (response.ok) {
      let json = await response.json();

      console.log("json received");
      return json;
    } else {
      let errorJson = await response.json();
      let s = "";
      for (let error of errorJson.errors) {
        s += `\n${error.message}`;
      }
      throw Error("Response not ok: " + s);
    }
  } /*useRtc*/ else {
    const graphQLRetryPrematureConnection = async () => {
      const attemptGraphQL = () => new Promise((resolve, reject) => {
        let result: any;
        const client = createClient(reject);
        if (!client) return; //reject is called if no data channel found (causing client to return as undefined)
        client.subscribe(
          {
            query: query,
            variables: variables,
          },
          {
            next: (data: any) => {
              if (data.errors) for (const err of data.errors) console.error(err.message);
              if (data.data === null && data.errors?.length)
                reject(data.errors.map((e: { message: string; }) => e.message).join("\n"));
              result = data;
            },
            error: (err) => { console.error("ERROR REJECT", err); reject(err); },
            complete: () => resolve(result)
          }
        );
      });

      let tries = 0;
      const MAX_TRIES = 3;
      let e;
      while (tries < MAX_TRIES) {
        try {
          const r = await attemptGraphQL();
          return r;
        } catch (err) {
          //ignore and try again
          e = err;
          console.warn(`try number ${tries + 1} graphql error: ${err}`);
          tries++;
        }
      }
      throw e;
    };
    if (o === undefined) o = {};
    if (o.timeout === undefined) o.timeout = 5 * 60 * 1000;
    if (o?.timeout !== undefined) {
      const timeout: number = o?.timeout as number;
      const timeoutPromise = new Promise((_, reject) => { setTimeout(() => reject("request timeout"), timeout); });
      let onRtcConnectionChange: (ev: any) => void = (_) => { };
      const connectionErrorPromise = new Promise((_, reject) => {
        onRtcConnectionChange = (ev: any) => {
          console.log("onRtcConnectionChange", ev);
          if (ev === "disconnected") reject("disconnected during request");
          //rtcEmitter.off("connectionEvent", onRtcConnectionChange);
        };
        rtcEmitter.on("connectionEvent", onRtcConnectionChange);

      });

      const p = await Promise.race([graphQLRetryPrematureConnection(), timeoutPromise, connectionErrorPromise]);
      rtcEmitter.off("connectionEvent", onRtcConnectionChange);
      return p;
    } else {
      return await graphQLRetryPrematureConnection();
    }
  }
}

function getUrl(environment: string) {
  switch (environment) {
    case "production":
      return "https://europe-west1-gigalot-cloud.cloudfunctions.net";
    case "staging":
      return "https://europe-west1-gigalot-testing.cloudfunctions.net";
    case "testing":
      return "http://localhost:5001/gigalot-testing/europe-west1";
    case "undetermined":
    default:
      throw Error("No environment detected! (Expected to be production, staging, or testing)");
  }
}

class State {
  constructor() {
    if (window.origin === productionUrl || window.origin.startsWith("https://gigalot-cloud-office-app--preview")) {
      console.log("Production environment detected.");
      this.environment = "production";
    } else if (window.origin === stagingUrl) {
      console.log("Staging (cloud) environment detected.");
      this.environment = "staging";
    } else {
      console.log("Testing (local) environment detected.");
      this.environment = "testing";
    }
  }

  environment: EnvType;
  moment: any = moment;
  storage: { [uid: string]: { [guid: string]: any; }; } = {};
  lightDarkMode: "light" | "dark" = "dark";
  version: string = packageJson.version;
  snackbar?: { visible: boolean; message: string; timeout?: number; };
  rtcSignalling: "disconnected" | "local" | "cloud" = "disconnected";
  navFuncs: { save?: () => void; back?: () => void; } = {};
  appBarText: string = "";
  connectedToProxy: boolean = false;
}

export default new Vuex.Store<State>({
  state: new State(),
  mutations: {
    updateUserLocationField(state: any, field: { path: string; value: any; }) {
      let uid = state.user.user.uid;
      let guid = state.user.location.guid;
      state.storage[uid] = state.storage[uid] || {};
      state.storage[uid][guid] = state.storage[uid][guid] || {};

      /*
        This is done to try fix the "doesn't-work-on-first-load" issue.
        Before setting variable, check if "root" variable exists (i.e "obj" of "obj.x.y.z").
        
        If not then update storage, but afterwards set storage to a copy if itself.
        This seems to wake Vue up and rebuild its reactive value tree.
      */
      const t = field.path.split(/[.[\]]+/); //regex copied from https://github.com/maoberlehner/vuex-map-fields/blob/master/src/index.js
      const updateStorage = t.length && state.storage[uid][guid][t[0]] === undefined;

      updateField(state.storage[uid][guid], field);

      if (updateStorage) state.storage = { ...state.storage };
    },
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    },
    snackbar(state: State, payload: { visible?: boolean; message?: string; timeout?: number; }) {
      if (!state.snackbar) state.snackbar = { message: "", visible: false, timeout: -1 };
      if (!payload) return;
      if (payload.message !== undefined) {
        state.snackbar.visible = true;
        state.snackbar.message = payload.message;
        state.snackbar.timeout = payload.timeout === undefined ? -1 : payload.timeout;
      } else if (payload.visible === false) {
        state.snackbar.visible = false;
        state.snackbar.message = "";
        state.snackbar.timeout = -1;
      }
    },
    rtcSignalling(state: State, payload: "disconnected" | "local" | "cloud") {
      state.rtcSignalling = payload;
    },
    navFuncs(state: State, payload: { save?: () => void; back?: () => void; }) {
      state.navFuncs = payload;
    },
    appBarText(state: State, payload: string) {
      state.appBarText = payload;
    },
    connectedToProxy(state: any, payload: boolean) {
      state.connectedToProxy = payload;
    },
  },
  actions: {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async graphQl(
      context: ActionContext<State, any>,
      o: { gql: string; variables?: { [key: string]: any; }; jwt: string; destination?: "feeder-server" | "node" | "cloud"; timeout?: number }
    ) {
      if (!o.jwt) o.jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });
      if (!o.destination) o.destination = "node";
      let url = o.destination === "cloud" ? context.getters["urlCloud"]() : context.getters["urlNode"]();
      url += "/office";

      if (o.destination === "feeder-server") {
        if (context.state.environment === "production") url = "https://pi.gigalot.systems:7777/feeder";
        else if (context.state.environment === "staging") {
          console.log("graphQl: staging environment detected, redirecting feeder-server destination to https://pi.gigalot.systems:7766/office");
          url = "https://pi.gigalot.systems:7766/office";
        } else {
          console.log(`graphQl: testing environment detected, redirecting feeder-server destination to ${context.getters["urlNode"]()}/office`);
          url = `${context.getters["urlNode"]()}/office`;
        }
      }

      console.log(`graphQl url: ${url}`);
      console.log(o.gql.split("\n")[0]);

      if (o.destination === "cloud") {
        return graphQl(o.jwt, url, o.gql, o.variables, false);
      } else {
        if (context.state.rtcSignalling === "disconnected") throw Error("Not connected");
        return graphQl(o.jwt, url, o.gql, o.variables, true, { timeout: o.timeout });
      }
    }
  },
  getters: {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    getUserLocationField(state: any): any {
      let uid = state.user.user.uid;
      let guid = state.user.location.guid;
      state.storage[uid] = state.storage[uid] || {};
      state.storage[uid][guid] = state.storage[uid][guid] || {};
      return getField(state.storage[uid][guid]);
    },
    storage(state: any) {
      return () => {
        let uid = state.user.user.uid;
        let guid = state.user.location.guid;
        state.storage[uid] = state.storage[uid] || {};
        state.storage[uid][guid] = state.storage[uid][guid] || {};
        return state.storage[uid][guid];
      };
    },
    urlNode(state: State) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://pi.gigalot.systems:7766"; //must be https
          case "staging":
            return "https://pi.gigalot.systems:7766"; //must be https
          case "testing":
            return "https://pi.gigalot.systems:7766"; //can be http, office-server run locally
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    urlHsl(state: State) {
      return () => {
        switch (state.environment) {
          case "production":
            return "orca.gigalot.systems"; //must be https
          case "staging":
            return "orca.gigalot.systems"; //must be https
          case "testing":
            return "orca.gigalot.systems"; //can be http, office-server run locally
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    urlCloud(state: State) {
      return () => {
        return `${getUrl(state.environment)}/officeAppResolver`;
      };
    },
    urlTownsProvincesCountries(state: State) {
      return () => {
        return `${getUrl(state.environment)}/townsProvincesCountries`;
      };
    },
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    }
  },
  modules: {
    list,
    popup,
    user,
    scanner
  },
  plugins: [createPersistedState()]
});
