import { default as Vuex, Module } from "vuex";
import router from "@/router";

import firebase from "firebase/app";
import "firebase/auth";

import * as Models from "@gigalot/data-models";
import { initRtc } from "@/main";
import * as RtcClient from "@gigalot/rtc-client";

/*
The idea behind hasfirebaseOnAuthStateChangedFired and callbacks:

The Vue components's lifecycle hook "mounted()" fires before firebase.auth().onAuthStateChanged fires
So that means mounted() can not have code that accesses firebase, which is limiting and annoying

To fix this we can use addCallback(func) that will call func after firebase has completed onAuthStateChanged
*/
let hasfirebaseOnAuthStateChangedFired: boolean = false;
let callbacks: (() => void)[] = [];

function addCallback(callback: () => void) {
  if (hasfirebaseOnAuthStateChangedFired) callback();
  else callbacks.push(callback);
}

function fireCallbacks() {
  hasfirebaseOnAuthStateChangedFired = true;
  let callback: (() => void) | undefined;
  while ((callback = callbacks.shift())) callback();
}

class UserState {
  /*
  some of the fields of firebase.User (user):

  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
  */
  //user?: firebase.User;
  user?: any;
  authorizationLevel?: number;
  gcp?: string = ""; //I think this is becoming obsolete
  accessToken?: string;
  location?: { guid: string; description: string; gcp: string; }; //new location, guid of location set in admin app
  locations: { guid: string; description: string; gcp: string; }[] = [];
}

export default new (class User implements Module<UserState, any> {
  namespaced = true;
  state: UserState = new UserState();
  mutations = {
    setAuthorizationLevel(state: UserState, payload: number) {
      state.authorizationLevel = payload;
    },
    setUser(state: UserState, payload: any) {
      state.user = payload;
      if (!payload) {
        state.authorizationLevel = undefined;
        state.gcp = undefined;
        state.location = undefined;
      }
    },
    // setUserInfo(state: UserState, payload: { authorizationLevel: number; gcp: string; location: string }) {
    //   state.authorizationLevel = payload.authorizationLevel;
    //   state.gcp = payload.gcp;
    //   state.location = payload.location;
    // },
    updateAccessToken(state: UserState, payload: { jwt: string; }) {
      state.accessToken = payload.jwt;
    },
    locations(state: UserState, locations: { guid: string; description: string; gcp: string; }[]) {
      state.locations = locations;
    },
    location(state: UserState, location: { guid: string; description: string; gcp: string; }) {
      RtcClient.disconnect();
      state.location = location;
      initRtc();
    }
  };
  actions = {
    async getOfflineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any; }) {
      return state.accessToken;
    },
    async getOnlineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any; }) {
      let user = firebase.auth().currentUser;
      let jwt;
      if (!user) {
        throw Error("No user");
      }
      jwt = await user.getIdToken();
      if (!jwt) {
        throw Error("No jwt");
      }
      commit("updateAccessToken", { jwt: jwt });
      return jwt;
    },
    async signInWithEmailPassword(
      { state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any; },
      obj: { email: string; password: string; }
    ) {
      let result = await firebase.auth().signInWithEmailAndPassword(obj.email, obj.password); //try catch happening outside method
      let user = result.user;
      if (!user) {
        throw Error("signInWithEmailAndPassword no result.user");
      }

      let jwt = await user.getIdToken();

      let gql = `query userOfficeAdminLocations($uid: String!) {
        userOfficeAdminLocations(uid: $uid) {
          guid
          gcp
          description
        }
      }`;

      try {
        let json = await dispatch("graphQl", { gql, variables: { uid: user.uid }, jwt, destination: "cloud" }, { root: true });

        let locations = json.data.userOfficeAdminLocations;

        if (locations.length === 0) throw Error("User is not authorized to use this app.");
        else {
          commit("locations", locations);
        }
      } catch (err) {
        throw err;
      }

      //Use uid to query if user has any office_administrator roles
      //If no locations found then deny access
      //If 1 location found then set that location
      //If more than location then let user choose which location to log into

      commit("setUser", JSON.parse(JSON.stringify(user)));
      commit("updateAccessToken", { jwt: jwt });

      router.push({ name: "location" });
    },
    signOut({ state, commit, rootState }: { state: UserState; commit: any; rootState: any; }) {
      firebase
        .auth()
        .signOut()
        .then(function () {
          RtcClient.disconnect();
          commit("setUser", undefined);
          router.push({ path: "/login" });
          console.log("user signed out");
        })
        .catch(function (error) {
          console.log("error signing out");
        });
    },
    firebaseOnAuthStateChanged({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any; }, user: firebase.User) {
      console.log("login sub module firebaseOnAuthStateChanged");
      //commit("setUser", user);

      /*
        Remember that this method gets called shortly after every reload.
        If there is a user then do nothing since they're already logged in.
        If there is no user then log out. This shouldn't really be needed, but it's here for just in case.
      */
      if (!user) {
        console.log("no user");
        commit("setUser", undefined);
        commit("updateAccessToken", { jwt: undefined });
        if (router.currentRoute.path !== "/login") router.push({ path: "/login" });
      }

      fireCallbacks();
    },
    addFirebaseCallback({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any; }, callback: () => void) {
      addCallback(callback);
    }
  };
  getters = {
    isUserRegistered(state: UserState, getters: any, rootState: any, rootGetters: any) {
      /*
        Returning a function, because for some reason just returning the boolean didn't work.
        calling `if (store.getters["user/isUserRegistered"])` still gets called if isUserRegistered is spelled wrong.
        rather use `if (store.getters["user/isUserRegistered"]())` because then if isUserRegistered is spelled wrong we'll get an error saying so.
      */
      return () => {
        let isUserRegistered: boolean = state.user ? true : false;
        return isUserRegistered;
      };
    },
    getUpstreamMetadata(state: UserState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        if (!state.user) throw Error("getUpstreamMetadata, no user");

        let userName: string = state.user.displayName;
        let userId: string = state.user.uid;
        let userEmail: string = state.user.email;
        let feedlot: string = state.location ? state.location.description : "";
        let gcp: string = state.location ? state.location.gcp : "";
        let location: string = state.location ? state.location.guid : "";

        let metadata: Models.UpstreamMetadata = new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
        return metadata;
      };
    }
  };
})();
