











































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import EarTag from "@/components/shared/EarTag.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { getTagColour, tagId, visualNumber } from "@/helpers/visual-tag";
import * as sgtinH from "@/helpers/sgtin";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listAilment } from "@/helpers/graphql-list-items";
import { decimal } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { listAnimal } from "@/helpers/graphql-list-items";

@Component({
  components: {
    DimssaButton,
    EarTag,
  },
  mixins: [validationMixin],
  validations: {
    mass: { decimal, positive: (val) => val > 0 },
    cost: { decimal },
  },
})
export default class Mortality extends Vue {
  //selectedAnimal: any = "";
  selectedKraalId: any = "";

  tagColour: string = "green";

  changed: boolean = false;

  animalListItemSearch: string = "";

  @Watch("animalListItemSearch")
  onAnimalListItemSearchChanged(val: any) {
    console.log("onAnimalListItemSearchChanged", this.animalListItemSearch);

    const NUM_CHARS = 3;
    if (this.animalListItemSearch?.length == NUM_CHARS) {
      this.refreshAnimalListItems();
    } else if (this.animalListItemSearch?.length < NUM_CHARS) {
      this.refreshAnimalListItemsButtonState = "disabled";
      this.animalListItems = [];
    }
  }

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "mortality", value: {} });
        this.$router.push({ path: "list/mortalities" });
      },
    });
    //this.ailments = (await this.$store.dispatch("data/get", { objectStore: "Ailment" })) as Models.Ailment[];
    this.selectedAilments = this.mortality.ailments;
    if (this.mortality.time !== undefined && this.mortality.time !== null) {
      this.dateString = this.moment(this.mortality.time).format("YYYY-MM-DD");
    }
    this.massChange(this.mortality.mass);
    this.costChange(this.mortality.cost);
    if (!this.mortality.notes.length) this.mortality.notes = [""];
    else this.notes = this.mortality.notes[0];

    // this.animals = await this.$store.dispatch("data/get", { objectStore: "Animal" });
    // this.animals = this.animals.map((a: any) => Object.assign(a, { visualSgtin: sgtinH.visual(a.sgtin) }));
    // if (this.mortality.sgtin) this.animals = [{ sgtin: this.mortality.sgtin, visualSgtin: sgtinH.visual(this.mortality.sgtin) }, ...this.animals];

    //this.kraalIds = ((await this.$store.dispatch("data/get", { objectStore: "Kraal" })) as Models.Kraal[]).map(k => k.kraalId);
    this.getKraals();

    this.selectedKraalId = this.mortality.sourceKraalId ?? "";

    this.determineTagColour();

    for (const selectedAilment of this.selectedAilments) {
      if (selectedAilment) {
        //this.selectedAilmentListItem = { guid: selectedAilment.guid, text: selectedAilment.ailment ?? "" };
        this.selectedAilmentsListItems.push({ guid: selectedAilment.guid, text: selectedAilment.ailment ?? "" });
        this.getSelectedAilmentsButtonState = "success";
      }
    }
    this.getAilmentListItems();

    //await this.refreshAnimalListItems();

    //this.selectedAnimal = this.mortality.sgtin ? this.animalListItems.find(a => a.sgtin === this.mortality.sgtin) : undefined;
    this.selectedAnimalListItem = this.mortality.sgtin ? this.animalListItems.find((a) => a.sgtin === this.mortality.sgtin) : "";
    if (!this.selectedAnimalListItem) this.selectedAnimalListItem = "";
  }

  determineTagColour() {
    this.tagColour = this.mortality.sgtin ? getTagColour(this.mortality.sgtin as string)?.name : "green";
  }

  @Watch("selectedKraalId")
  onSourceKraalIdChanged(selectedKraalId: any) {
    console.log("selectedKraalId: " + selectedKraalId);
    this.$store.commit("updateUserLocationField", { path: "mortality.sourceKraalId", value: selectedKraalId });
  }

  dateString: string = "";
  dateMenu: boolean = false;

  //animals: any[] = [];

  ////////
  // Ailments

  ailmentListItems: { guid: string; text: string }[] = [];
  selectedAilmentsListItems: ({ guid: string; text: string } | "")[] = [];
  getAilmentListItemsButtonState: ButtonState = "ready";
  getSelectedAilmentsButtonState: ButtonState = "ready";

  uiUpdate = 0;

  get modelSelectedAilmentsListItems(): ({ guid: string; text: string } | "")[] {
    return this.selectedAilmentsListItems;
  }

  set modelSelectedAilmentsListItems(selectedAilmentsListItems: ({ guid: string; text: string } | "")[]) {
    if (selectedAilmentsListItems === undefined) selectedAilmentsListItems = [];

    this.selectedAilmentsListItems = selectedAilmentsListItems;
    this.getAilmentsFromServer();
  }

  async getAilmentsFromServer() {
    if (!this.selectedAilmentsListItems.length) return;
    try {
      this.getSelectedAilmentsButtonState = "busy";
      const ailments = [];
      for (const selectedAilmentListItem of this.selectedAilmentsListItems)
        if (selectedAilmentListItem) ailments.push(await getItemFromServer("Ailment", selectedAilmentListItem.guid));

      this.ailmentChange(ailments);
      this.getSelectedAilmentsButtonState = "success";
    } catch (err) {
      this.getSelectedAilmentsButtonState = "error";
      console.error(err);
      this.ailmentChange([]);
    }
  }

  async getAilmentListItems() {
    try {
      this.getAilmentListItemsButtonState = "busy";
      this.ailmentListItems = [];
      let items = await listAilment(true);
      this.ailmentListItems = items.map((i: any) => ({ guid: i.guid, text: i.ailment }));
      this.getAilmentListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getAilmentListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    for (const selectedAilment of this.selectedAilments) {
      if (selectedAilment && !this.ailmentListItems.find((t) => t.guid === (selectedAilment as Models.Ailment).guid)) {
        const a = selectedAilment as Models.Ailment;
        this.ailmentListItems.push({ guid: a.guid, text: a.ailment ?? "" });
      }
    }
  }

  //ailments: Models.Ailment[] = [];
  selectedAilments: Models.Ailment[] = [];
  ailmentChange(ailments: any) {
    //console.log(ailments);
    this.$store.commit("updateUserLocationField", { path: "mortality.ailments", value: ailments });
  }

  // Ailments
  ////////
  // Animals

  selectedAnimalListItem: any = "";
  selectedAnimal: Models.Animal | "" = "";
  refreshAnimalListItemsButtonState: ButtonState = "disabled";
  refreshAnimalListItemsErrorMessage: string = "";
  getAnimalErrorMessage: string = "";

  animalListItems: {
    typename: string;
    guid: string;
    sgtin?: string;
    owner?: string;
    breed?: string;
    gender?: string;
    tagId?: string;
  }[] = [];

  @Watch("selectedAnimal")
  onSelectedAnimalChanged(selectedAnimal: any) {
    console.log("selectedAnimal: " + JSON.stringify(selectedAnimal));
    let updateRequired = selectedAnimal?.sgtin !== this.mortality?.sgtin;
    this.$store.commit("updateUserLocationField", { path: "mortality.sgtin", value: selectedAnimal ? selectedAnimal.sgtin : undefined });
    if (updateRequired) {
      this.$store.commit("updateUserLocationField", { path: "mortality.customFeeder", value: selectedAnimal ? selectedAnimal.customFeeder : undefined });
      this.$store.commit("updateUserLocationField", { path: "mortality.breed", value: selectedAnimal ? selectedAnimal.breed : undefined });
      this.$store.commit("updateUserLocationField", { path: "mortality.gender", value: selectedAnimal ? selectedAnimal.gender : undefined });
    }
    this.uiUpdate++;
    this.determineTagColour();
  }

  @Watch("selectedAnimalListItem")
  async onSelectedAnimalListItemChanged(selectedAnimalListItem: any) {
    console.log("onSelectedAnimalListItemChanged");
    console.dir(selectedAnimalListItem);

    if (selectedAnimalListItem) {
      try {
        this.selectedAnimal = "";
        this.getAnimalErrorMessage = "";
        this.selectedAnimal = await this.getAnimalFromServer();
      } catch (err) {
        console.error("error getting animal from server:");
        console.error(err);
        this.getAnimalErrorMessage = (err as any)?.toString() ?? "no error message found";
      }
    } else this.selectedAnimal = "";
  }

  async getAnimalFromServer(): Promise<Models.Animal> {
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: Models.gql.queries.getAnimal,
        variables: {
          guid: this.$store.state.user.location.guid,
          itemGuid: this.selectedAnimalListItem.guid,
        },
      },
      { root: true }
    );
    console.log("graphQL:");
    console.dir(json);

    return json.data.getAnimal as Models.Animal;
  }

  async refreshAnimalListItems() {
    try {
      this.refreshAnimalListItemsButtonState = "busy";
      this.refreshAnimalListItemsErrorMessage = "";

      this.animalListItems = await listAnimal(this.animalListItemSearch);

      this.refreshAnimalListItemsButtonState = "success";
    } catch (err) {
      this.animalListItems = [];
      console.error("refreshAnimalListItems() error:");
      console.error(err);
      this.refreshAnimalListItemsErrorMessage = (err as any)?.toString() ?? "no error message found";
      this.refreshAnimalListItemsButtonState = "error";
    }
  }

  async scanTag() {
    let sgtin: string = await this.$store.dispatch("scanner/scanSgtin");
    if (sgtin.startsWith("ERROR")) {
      this.$store.commit("popup/displayOk", sgtin);
    } else {
      sgtin = sgtin.replace(/"/g, "");
      //get guid for animal from animal list
      let animalToFind = this.animalListItems.find((a) => a.sgtin === sgtin);
      if (animalToFind) {
        this.selectedAnimalListItem = animalToFind;
        await this.onSelectedAnimalListItemChanged(animalToFind);
      } else {
        //animal not found
        this.$store.commit("popup/displayOk", "Animal not found");
      }
    }
  }

  // Animals
  ////////
  // Kraals

  kraalIds: string[] = [];
  getKraalsButtonState: ButtonState = "ready";

  async getKraals() {
    try {
      this.getKraalsButtonState = "busy";
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `query Kraals($guid: String!) {
          Kraals(guid: $guid) {
            kraalId
          }
        }`,
          variables: {
            guid: this.$store.state.user.location.guid,
          },
        },
        { root: true }
      );
      console.log("graphQL:");
      console.dir(json);
      this.kraalIds = json.data.Kraals.map((k: any) => k.kraalId);
      this.getKraalsButtonState = "success";
    } catch (err) {
      this.getKraalsButtonState = "error";
    }
  }

  // Kraals
  ////////

  get connectedToProxyButtonState() {
    if (this.$store.state.scanner.busyScanning) {
      this.getConnectedToProxyButtonState = "busy";
    } else if (this.$store.state.connectedToProxy) {
      this.getConnectedToProxyButtonState = "ready";
    } else {
      this.getConnectedToProxyButtonState = "disabled";
    }
    return this.getConnectedToProxyButtonState;
  }

  getConnectedToProxyButtonState: ButtonState = "error";

  get mortality(): Models.Mortality {
    return this.$store.getters["storage"]().mortality;
  }

  get moment() {
    return this.$store.state.moment;
  }

  rules = {
    validNumber: (value: string) => /^[0-9]*\.{0,1}[0-9]*$/.test(value) || "Invalid input.",
  };

  mass: string = "";
  massChange(mass: any) {
    this.mass = mass === undefined || mass === null ? "" : mass;
    console.log("mass: " + mass);
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(mass) !== true) {
      //console.log("invalid input: " + mass);
      this.$store.commit("updateUserLocationField", { path: "mortality.mass", value: undefined });
      return;
    }
    this.$store.commit("updateUserLocationField", { path: "mortality.mass", value: isNaN(parseFloat(mass)) ? undefined : parseFloat(mass) });
  }

  cost: string = "";
  costChange(cost: any) {
    this.cost = cost === undefined || cost === null ? "" : cost;
    //console.log("cost: " + cost);
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(cost) !== true) {
      //console.log("invalid input: " + cost);
      this.$store.commit("updateUserLocationField", { path: "mortality.cost", value: undefined });
      return;
    }
    this.$store.commit("updateUserLocationField", { path: "mortality.cost", value: isNaN(parseFloat(cost)) ? undefined : parseFloat(cost) });
  }

  notes = "";
  notesChange(notes: any) {
    this.notes = notes;
    this.$store.commit("updateUserLocationField", { path: "mortality.notes", value: [notes] });
  }

  save() {
    //TODO: data validation before saving
    //if (!this.selectedAnimal) {
    if (!this.mortality.sgtin) {
      this.$store.commit("popup/displayOk", `Can not save. No Tag ID selected.`);
      return;
    }

    //TODO: popup and block saving if another mortality with the same sgtin (different guid) is found

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().mortality);
          let mortalityClone: Models.Mortality = lodash.cloneDeep(this.$store.getters["storage"]().mortality);
          mortalityClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation mortality($guid: String!, $mortality: MortalityInput!) {
                mortality(guid: $guid, mortality: $mortality) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: {
                guid: this.$store.state.user.location.guid,
                mortality: mortalityClone as Models.Mortality,
              },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let mortality = this.$store.getters["storage"]().mortality as Models.Mortality;
          mortality.changeTracking = json.data.mortality;
          //await this.$store.dispatch("data/set", { objectStore: "Mortality", items: [lodash.cloneDeep(mortality)] });
          this.$router.push({ path: "list/mortalities" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "mortality", value: {} });
        this.$router.push({ path: "list/mortalities" });
      },
    });
  }

  tagIdFunc(sgtin: string) {
    return tagId(sgtin);
  }
}
