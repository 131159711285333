









































































































































































































import { Component, Vue } from "vue-property-decorator";
import wb from "./registerServiceWorker";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

@Component
export default class App extends Vue {
  busyLoading: boolean = false;
  navigationDrawer: boolean = false;

  beforeCreate() {
    this.$store.commit("snackbar"); //just to init snackbar variables
    this.$store.commit("popup/hide");
  }

  async created() {
    if (wb)
      wb.addEventListener("waiting", async () => {
        //Update is ready, waiting
        this.$store.commit("popup/displayYesNo", {
          message: "An update is available. Would you like to update?",
          yesAction: async () => {
            if (wb) {
              await wb.messageSW({ type: "SKIP_WAITING" });
              window.location.reload();
            } else throw Error("No wb to messageSW SKIP_WAITING");
          },
        });
      });
  }

  async mounted() {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
    this.busyLoading = true;
    this.$store.commit("list/clearItemsToFreeQuota");
    //this.$store.dispatch("data/initialize");
    this.busyLoading = false;
    //setTimeout(function() { self.busyLoading = false}, 5000);
  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  logout() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to log out?",
      yesAction: () => {
        this.$store.dispatch("user/signOut");
      },
    });
  }
}
