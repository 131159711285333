





















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listDispensaryItem } from "@/helpers/graphql-list-items";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["vaccination.code", "vaccination.description", "vaccination.withdrawal", "vaccination.active"]),
  },
})
export default class Vaccination extends Vue {
  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "vaccination", value: {} });
        this.$router.push({ path: "list/vaccinations" });
      },
    });
    let vaccination = this.$store.getters["storage"]().vaccination;
    for (let dose of vaccination.doses) {
      if (!dose.unit) dose.unit = "";
      if (!dose.unit && dose.item && dose.item.unit) dose.unit = dose.item.unit; //apply default unit
    }
    this.doses = vaccination.doses;

    for (let i = 0; i < this.doses.length; ++i) {
      const dose = this.doses[i];
      if (dose.item) {
        this.selectedDispensaryItemListItem[i] = { guid: dose.item.guid, text: dose.item.description };
        this.getSelectedDispensaryItemButtonState[i] = "success";
      } else {
        this.selectedDispensaryItemListItem[i] = "";
        this.getSelectedDispensaryItemButtonState[i] = "ready";
      }
    }

    this.getDispensaryItemListItems();
  }

  @Watch("doses")
  onDosesChanged(doses: any) {
    this.$store.commit("updateUserLocationField", {
      path: "vaccination.doses",
      value: doses,
    });
  }

  dispensaryItemListItems: { guid: string; text: string }[] = [];
  selectedDispensaryItemListItem: ({ guid: string; text: string } | "")[] = [];
  getDispensaryItemListItemsButtonState: ButtonState = "ready";
  getSelectedDispensaryItemButtonState: ButtonState[] = [];

  getSelectedDispensaryItemListItem(index: number): { guid: string; text: string } | "" {
    return this.selectedDispensaryItemListItem[index];
  }

  setSelectedDispensaryItemListItem(index: number, listItem: { guid: string; text: string } | "") {
    if (listItem === undefined) listItem = "";

    if (
      this.selectedDispensaryItemListItem[index] &&
      listItem &&
      (this.selectedDispensaryItemListItem[index] as { guid: string; text: string }).guid &&
      (this.selectedDispensaryItemListItem[index] as { guid: string; text: string }).guid === listItem.guid
    )
      return;

    this.selectedDispensaryItemListItem[index] = listItem;
    this.getDispensaryItemFromServer(index);
  }

  async getDispensaryItemFromServer(index: number) {
    if (!this.selectedDispensaryItemListItem[index] || !(this.selectedDispensaryItemListItem[index] as { guid: string; text: string }).guid) return;
    try {
      Vue.set(this.getSelectedDispensaryItemButtonState, index, "busy");
      const dispensaryItem = await getItemFromServer("DispensaryItem", (this.selectedDispensaryItemListItem[index] as { guid: string; text: string }).guid);

      const doseClone = lodash.cloneDeep(this.doses[index]);
      doseClone.item = dispensaryItem;
      doseClone.unit = dispensaryItem.unit;
      Vue.set(this.doses, index, doseClone);

      Vue.set(this.getSelectedDispensaryItemButtonState, index, "success");
    } catch (err) {
      Vue.set(this.getSelectedDispensaryItemButtonState, index, "error");
      console.error(err);
      const doseClone = lodash.cloneDeep(this.doses[index]);
      (doseClone as any).item = "";
      doseClone.unit = "";
      Vue.set(this.doses, index, doseClone);
    }
  }

  async getDispensaryItemListItems() {
    try {
      this.getDispensaryItemListItemsButtonState = "busy";
      this.dispensaryItemListItems = [];
      let items = await listDispensaryItem(true);
      this.dispensaryItemListItems = items.map((i: any) => ({ guid: i.guid, text: i.description }));
      this.getDispensaryItemListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getDispensaryItemListItemsButtonState = "error";
    }

    for (const dose of this.doses) {
      //If selected is not found in list then add it
      if (dose.item && !this.dispensaryItemListItems.find((d) => d.guid === dose.item?.guid))
        this.dispensaryItemListItems.push({ guid: dose.item.guid, text: dose.item.description });
    }
  }

  doses: Models.Dose[] = [];

  addDose() {
    this.changed = true;

    let dose = new Models.Dose();
    dose.type = "constant";
    dose.amount = 0.0;
    (dose as any).item = "";
    this.doses.push(dose);

    this.selectedDispensaryItemListItem.push("");
    this.getSelectedDispensaryItemButtonState.push("ready");
  }

  doseTypeItems() {
    return [
      {
        text: "Constant",
        value: "constant",
      },
      {
        text: "Mass Dependent",
        value: "cc-mass-dependent",
      },
    ];
  }

  save() {
    let vaccination = this.$store.getters["storage"]().vaccination;
    if (!(vaccination as Models.Vaccination).description) {
      this.$store.commit("popup/displayOk", `Can not save. Vaccination must have a description.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => !d.item)) {
      this.$store.commit("popup/displayOk", `Can not save. All doses must have a dispensary item selected.`);
      return;
    }

    if (
      (vaccination as Models.Vaccination).doses.find(
        (d) => d.item && ([null, undefined, ""].includes((d.item as any).withdrawal) || (d.item as any).withdrawal < 0)
      )
    ) {
      this.$store.commit("popup/displayOk", `Can not save. All dispensary items selected must have a valid value for withdrawal days.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => [null, undefined, ""].includes((d as any).amount))) {
      this.$store.commit("popup/displayOk", `Can not save. All dispensary must have a valid dose amount set.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && d.amount && d.amount < 0)) {
      this.$store.commit("popup/displayOk", `Can not save. Doses may not be negative.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && ![null, undefined, ""].includes((d as any).amount) && d.amount === 0)) {
      this.$store.commit("popup/displayOk", `Can not save. Doses may not be 0.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && !d.unit)) {
      this.$store.commit("popup/displayOk", `Can not save. All doses must have a unit set.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && d.type === "cc-mass-dependent" && [null, undefined, ""].includes((d as any).kgPerDose))) {
      this.$store.commit("popup/displayOk", `Can not save. All doses must have valid mass increment.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && d.type === "cc-mass-dependent" && d.kgPerDose === 0)) {
      this.$store.commit("popup/displayOk", `Can not save. Mass increments may not be 0.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d && d.type === "cc-mass-dependent" && d.kgPerDose && d.kgPerDose < 0)) {
      this.$store.commit("popup/displayOk", `Can not save. Mass increments may not be negative.`);
      return;
    }

    if ((vaccination as Models.Vaccination).doses.find((d) => d.item && [null, undefined, ""].includes((d.item as any).withdrawal))) {
      this.$store.commit("popup/displayOk", `Can not save. All dispensary items selected must have a valid value for withdrawal days.`);
      return;
    }

    let withdrawal = vaccination.withdrawal;
    if (![null, undefined, ""].includes(withdrawal)) {
      //check if any of the dose's withdrawals are higher
      let dose = (vaccination as Models.Vaccination).doses.find(
        (d) => d.item && d.item.withdrawal !== null && d.item.withdrawal !== undefined && (d.item as any).withdrawal !== "" && d.item.withdrawal > withdrawal
      );
      if (dose) {
        this.$store.commit("popup/displayOk", `Can not save. Doses can not have a higher withdrawal than vaccination's withdrawal.`);
        return;
      }
    }

    //TODO: popup and block saving if another vaccination with the same description (different guid) is found

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          let vaccinationClone = lodash.cloneDeep(this.$store.getters["storage"]().vaccination);
          if ([null, undefined, ""].includes(vaccinationClone.withdrawal)) vaccinationClone.withdrawal = undefined;
          vaccinationClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation vaccination($guid: String!, $vaccination: VaccinationInput!) {
                vaccination(guid: $guid, vaccination: $vaccination) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, vaccination: vaccinationClone as Models.Vaccination },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let vaccination = this.$store.getters["storage"]().vaccination as Models.Vaccination;
          vaccination.changeTracking = json.data.vaccination;
          //await this.$store.dispatch("data/set", { objectStore: "Vaccination", items: [lodash.cloneDeep(vaccination)] });
          this.$router.push({ path: "list/vaccinations" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "vaccination", value: {} });
        this.$router.push({ path: "list/vaccinations" });
      },
    });
  }
}
