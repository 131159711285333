

































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["customFeeder.name", "customFeeder.active"]),
    ...mapFields({ companyName: "customFeeder.companies[0].name" }),
  },
})
export default class CustomFeeder extends Vue {
  get customFeeder() {
    return this.$store.getters["storage"]().customFeeder;
  }

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "customFeeder", value: {} });
        this.$router.push({ path: "list/custom-feeders" });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "customFeeder", value: {} });
        this.$router.push({ path: "list/custom-feeders" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another customFeeder with the same name (different guid) is found
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().customFeeder);
          let customFeederClone = lodash.cloneDeep(this.$store.getters["storage"]().customFeeder);
          customFeederClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation customFeeder($guid: String!, $customFeeder: CustomFeederInput!) {
                customFeeder(guid: $guid, customFeeder: $customFeeder) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, customFeeder: customFeederClone as Models.CustomFeeder },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          /*
          graphQL: {
            "data": {
              "customFeeder": {
                "current": {
                  "guid":null,
                  "hash":null
                },
                "previous": {
                  "guid":"1592890260683-1480f927-3ccb-4e82-ba0e-587eb9df658c",
                  "hash":"c06e44a971a14e9f76c6e4d89874a5dda1002d84"
                },
                "serverSaveTime":null,
                "save": {
                  "uid":null,
                  "time":null
                }
              }
            }
          }
          */
          let customFeeder = this.$store.getters["storage"]().customFeeder as Models.CustomFeeder;
          customFeeder.changeTracking = json.data.customFeeder;
          //await this.$store.dispatch("data/set", { objectStore: "CustomFeeder", items: [lodash.cloneDeep(customFeeder)] });

          // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
          // for (let intakeBatch of intakeBatches) {
          //   if (intakeBatch.transport.length && intakeBatch.transport[0].customFeeder && intakeBatch.transport[0].customFeeder.guid === customFeeder.guid) {
          //     intakeBatch.transport[0].customFeeder = lodash.cloneDeep(customFeeder);
          //     await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //   }
          // }

          this.$router.push({ path: "list/custom-feeders" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
