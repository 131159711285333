

















































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["dispensaryItem.code", "dispensaryItem.description", "dispensaryItem.withdrawal", "dispensaryItem.unit", "dispensaryItem.active"]),
  },
})
export default class DispensaryItem extends Vue {
  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "dispensaryItem", value: {} });
        this.$router.push({ path: "list/dispensary-items" });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "dispensaryItem", value: {} });
        this.$router.push({ path: "list/dispensary-items" });
      },
    });
  }

  save() {
    let dispensaryItem: Models.DispensaryItem | undefined = this.$store.getters["storage"]().dispensaryItem;

    if (!dispensaryItem) throw Error("DispensaryItem.save(): No dispensaryItem found in vuex storage when saving.");

    //DispensaryItem must have a description
    if (!dispensaryItem.description) {
      this.$store.commit("popup/displayOk", `Can not save. Dispensary item must have a description.`);
      return;
    }

    //DispensaryItem must have a valid withdrawal set
    if ([null, undefined, ""].includes((dispensaryItem as any).withdrawal) || (dispensaryItem.withdrawal && dispensaryItem.withdrawal < 0)) {
      this.$store.commit("popup/displayOk", `Can not save. Dispensary item must have a valid withdrawal.`);
      return;
    }

    //TODO: popup and block saving if another dispensary item with the same description (different guid) is found

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().dispensaryItem);
          let dispensaryItemClone = lodash.cloneDeep(this.$store.getters["storage"]().dispensaryItem);
          dispensaryItemClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          if (dispensaryItemClone.withdrawal === "") dispensaryItemClone.withdrawal = undefined;
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation dispensaryItem($guid: String!, $dispensaryItem: DispensaryItemInput!) {
                dispensaryItem(guid: $guid, dispensaryItem: $dispensaryItem) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, dispensaryItem: dispensaryItemClone as Models.DispensaryItem },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let dispensaryItem = this.$store.getters["storage"]().dispensaryItem as Models.DispensaryItem;
          dispensaryItem.changeTracking = json.data.dispensaryItem;
          //await this.$store.dispatch("data/set", { objectStore: "DispensaryItem", items: [lodash.cloneDeep(dispensaryItem)] });

          // let treatments = (await this.$store.dispatch("data/get", { objectStore: "Treatment" })) as Models.Treatment[];
          // for (let treatment of treatments)
          //   for (let dose of treatment.doses)
          //     if (dose.item && dose.item.guid === dispensaryItem.guid) {
          //       dose.item = lodash.cloneDeep(dispensaryItem);
          //       await this.$store.dispatch("data/set", { objectStore: "Treatment", items: [lodash.cloneDeep(treatment)] });
          //     }

          // let vaccinations = (await this.$store.dispatch("data/get", { objectStore: "Vaccination" })) as Models.Vaccination[];
          // for (let vaccination of vaccinations)
          //   for (let dose of vaccination.doses)
          //     if (dose.item && dose.item.guid === dispensaryItem.guid) {
          //       dose.item = lodash.cloneDeep(dispensaryItem);
          //       await this.$store.dispatch("data/set", { objectStore: "Vaccination", items: [lodash.cloneDeep(vaccination)] });
          //     }

          this.$router.push({ path: "list/dispensary-items" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
