








































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
    Keypress: () => import("vue-keypress"),
  },
})
export default class Login extends Vue {
  email: string = "";
  password: string = "";
  errorMessage: string = "";
  show: boolean = false; //show password

  signInButtonState: ButtonState = "ready";

  // rules: { [ruleName: string]: (value: string) => boolean | string } = {
  //   required: (value: string) => !!value || "Required.",
  //   min: (v: string) => v.length >= 6 || "Min 6 characters",
  //   //emailMatch: () => "The email and password you entered don't match"
  // };
  passwordUrl() {
    if (this.$store.state.environment === "production") {
      return "https://password.gigalot.co.za";
    } else return "https://password.gigalot.systems";
  }

  async signInWithEmailPassword() {
    //TODO: use rules used in text fields to test email and password. Use error message in popup
    try {
      this.errorMessage = "";
      this.signInButtonState = "busy";
      await this.$store.dispatch("user/signInWithEmailPassword", {
        email: this.email.trim(),
        password: this.password,
      });
      this.signInButtonState = "ready";
    } catch (err) {
      this.signInButtonState = "error";
      console.log("signInWithEmailPassword error: " + err);
      this.errorMessage = err;
    }
  }
}
