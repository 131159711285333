

































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["creditor.name", "creditor.active"]),
    ...mapFields({ companyName: "creditor.companies[0].name" }),
  },
})
export default class Creditor extends Vue {
  get creditor(): Models.Creditor {
    return this.$store.getters["storage"]().creditor;
  }

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "creditor", value: {} });
        this.$router.push({ path: "list/creditors" });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "creditor", value: {} });
        this.$router.push({ path: "list/creditors" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another creditor with the same name (different guid) is found
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().creditor);
          let creditorClone = lodash.cloneDeep(this.$store.getters["storage"]().creditor);
          creditorClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation creditor($guid: String!, $creditor: CreditorInput!) {
                creditor(guid: $guid, creditor: $creditor) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, creditor: creditorClone as Models.Creditor },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let creditor = this.$store.getters["storage"]().creditor as Models.Creditor;
          creditor.changeTracking = json.data.creditor;
          //await this.$store.dispatch("data/set", { objectStore: "Creditor", items: [lodash.cloneDeep(creditor)] });

          // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
          // for (let intakeBatch of intakeBatches) {
          //   if (intakeBatch.transport.length && intakeBatch.transport[0].creditor && intakeBatch.transport[0].creditor.guid === creditor.guid) {
          //     intakeBatch.transport[0].creditor = lodash.cloneDeep(creditor);
          //     await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //   }
          // }

          this.$router.push({ path: "list/creditors" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
