
















































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { tagId } from "@/helpers/visual-tag";
import wNumb from "wnumb";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listCustomFeeder, listTransporter } from "@/helpers/graphql-list-items";
import { capitalize } from "@/helpers";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["dispatchToSiteBatch.reference", "dispatchToSiteBatch.description", "dispatchToSiteBatch.notes", "dispatchToSiteBatch.settled"]),
  },
  mixins: [validationMixin],
  validations: {
    reference: { required },
    transportQuantityExpected: { required },
    modelSelectedCustomFeederListItem: { required },
    selectedDestinationFeedlot: { required },
  },
})
export default class DispatchToSiteBatch extends Vue {
  buttonStateGetDispatchedAnimals: ButtonState = "ready";

  moment = this.$store.state.moment;

  capitalize = capitalize;

  changed: boolean = false;

  tab: any = null;

  @Watch("changed")
  onChangeChanged(val: any) {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch", value: {} });
          this.$router.push({ path: "list/dispatch-to-site-batches" });
        },
      });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch", value: {} });
        this.$router.push({ path: "list/dispatch-to-site-batches" });
      },
    });

    if (this.dispatchToSiteBatch.reference === undefined) {
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.reference", value: "" });
    }

    if (!this.dispatchToSiteBatch.dispatchedAnimals) this.dispatchToSiteBatch.dispatchedAnimals = [];
    if (!this.dispatchToSiteBatch.transport) this.dispatchToSiteBatch.transport = [];
    if (!this.dispatchToSiteBatch.transport.length) {
      this.dispatchToSiteBatch.transport[0] = new Models.Transport();
    }

    const weighbridges = this.dispatchToSiteBatch.transport[0].weighbridges;
    if (!weighbridges.find((wb) => wb.location === "dispatch")) weighbridges.push({ location: "dispatch", reference: "", mass: undefined });
    //if (!weighbridges.find((wb) => wb.location === "abattoir")) weighbridges.push({ location: "abattoir", reference: "", mass: undefined });

    if (!this.dispatchToSiteBatch.transport[0].origin) {
      //this can be ignored, we should have left it out but it's too much effort to remove now
      this.dispatchToSiteBatch.transport[0].origin = new Models.Origin();
      this.dispatchToSiteBatch.transport[0].origin.country = "";
      this.dispatchToSiteBatch.transport[0].origin.province = "";
      this.dispatchToSiteBatch.transport[0].origin.town = "";
      this.dispatchToSiteBatch.transport[0].origin.code = "";
    }

    this.transportCostChange(this.dispatchToSiteBatch.transport[0].cost);
    this.transportQuantityDeadChange(this.dispatchToSiteBatch.transport[0].quantityDead);
    //this.transportDistanceChange(this.dispatchToAbattoirBatch.transport[0].distance);
    if (this.dispatchToSiteBatch.transport && this.dispatchToSiteBatch.transport.length)
      this.transportQuantityExpectedChange(this.dispatchToSiteBatch.transport[0].quantityExpected);

    if (!this.dispatchToSiteBatch.dispatchType) this.dispatchToSiteBatch.dispatchType = "single";

    //table headers
    // const dispatchedAnimalsTableHeaders = this.$store.getters["getUserLocationField"]("dispatchedAnimalsTableHeaders");
    // if (dispatchedAnimalsTableHeaders) {
    //   for (const dath of dispatchedAnimalsTableHeaders) {
    //     const th = this.tableHeaders.find((_th) => _th.value === dath.value);
    //     if (th) th.visible = dath.visible;
    //   }
    //   //this.tableHeaders = dispatchedAnimalsTableHeaders;
    // } else {
    //   this.$store.commit("updateUserLocationField", {
    //     path: `dispatchedAnimalsTableHeaders`,
    //     value: this.tableHeaders,
    //   });
    // }

    if (this.transporter) {
      //try to get drivers from server. If not successful then just use selected [driver] in this.transporter.drivers
      try {
        let t = await getItemFromServer("Transporter", this.transporter.guid);
        this.drivers = t.drivers;
      } catch (err) {
        this.drivers = this.transporter.drivers;
      }
    } else {
      this.drivers = [];
      this.driver = "";
    }

    if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";

    if (this.customFeeder) {
      const cf = this.customFeeder;
      this.selectedCustomFeederListItem = { guid: cf.guid, text: `${cf.name}${cf.companies[0]?.name ? ` - ${cf.companies[0]?.name}` : ``}` };
      this.getSelectedCustomFeederButtonState = "success";
    }
    this.getCustomFeederListItems();

    if (this.transporter) {
      const t = this.transporter;
      this.selectedTransporterListItem = { guid: t.guid, text: t.name };
      this.getSelectedTransporterButtonState = "success";
    }
    this.getTransporterListItems();

    //this.destinationAbattoirs = (await this.$store.dispatch("data/get", { objectStore: "DestinationAbattoir" })) as Models.Abattoir[];
    await this.getDestinationFeedlots();
    if (this.dispatchToSiteBatch.transport[0]?.destination?.guid) {
      const guid = this.dispatchToSiteBatch.transport[0]?.destination?.guid;
      const df = this.destinationFeedlots.find((df) => df.guid === guid);
      if (df) this.selectedDestinationFeedlot = df;
      else {
        //add destination abattoir to list if not found
        const destination = {
          guid: this.dispatchToSiteBatch.destinationGuid,
          name: this.dispatchToSiteBatch.destinationName,
          gcp: this.dispatchToSiteBatch.destinationGcp,
        };
        if (destination.guid && destination.name && destination.gcp) {
          const t = { guid: destination.guid, name: destination.name, gcp: destination.gcp };
          this.destinationFeedlots.push(t);
          this.selectedDestinationFeedlot = t;
        }
      }
    }
  }

  calculateDispatchedAnimalsDispatchWeighbridgeMass() {
    const quantity = this.dispatchToSiteBatch.transport[0].quantityExpected;
    if (quantity === undefined || quantity <= 0) {
      for (const dispatchedAnimal of this.dispatchToSiteBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = undefined;
      return;
    }
    let dispatchWeighbridge = lodash.find(this.dispatchToSiteBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    const weighbridgeMass = dispatchWeighbridge?.mass;
    if (weighbridgeMass === undefined) {
      for (const dispatchedAnimal of this.dispatchToSiteBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = undefined;
      return;
    }
    const avgMass = weighbridgeMass / quantity;
    for (const dispatchedAnimal of this.dispatchToSiteBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = avgMass;
  }

  // calculateDispatchedAnimalAppliedPrice(index: number) {
  //   const dispatchedAnimal = this.dispatchToAbattoirBatch.dispatchedAnimals[index];
  //   const abattoirPrice = dispatchedAnimal.abattoirPrice;
  //   const abattoirMassColdCarcass = dispatchedAnimal.abattoirMassColdCarcass;
  //   if (abattoirPrice === undefined || abattoirMassColdCarcass === undefined) {
  //     this.$store.commit("updateUserLocationField", {
  //       path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].appliedPrice`,
  //       value: undefined
  //     });
  //     return;
  //   }
  //   const appliedPrice = dispatchedAnimal.appliedPrice;
  //   if (appliedPrice !== abattoirPrice * abattoirMassColdCarcass)
  //     this.$store.commit("updateUserLocationField", {
  //       path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].appliedPrice`,
  //       value: abattoirPrice * abattoirMassColdCarcass
  //     });
  // }

  currencyFormat(amt: any) {
    return wNumb({
      mark: ".",
      thousand: " ",
      prefix: "R ",
      decimals: 2,
    }).to(parseFloat(amt));
  }

  massFormat(amt: any) {
    return wNumb({
      mark: ".",
      thousand: " ",
      suffix: " kg",
      decimals: 2,
    }).to(parseFloat(amt));
  }

  // get grades() {
  //   let ret: string[] = [];
  //   let t0 = ["A", "B", "AB", "C"];
  //   let t1: string[] = [];
  //   for (let i = 0; i <= 6; ++i) t1.push(`${i}`);
  //   for (const prefix of t0) for (const suffix of t1) ret.push(`${prefix}${suffix}`);
  //   return ret;
  // }

  tagId = tagId; //imported function as a local function so that it can be used in Vue template

  // dispatchedAnimalGradeInput(item: Models.DispatchedAnimal, grade: any) {
  //   const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex(da => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
  //   if (index === -1) throw Error("index for dispatched animal not found!");
  //   this.$store.commit("updateUserLocationField", {
  //     path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].grade`,
  //     value: grade
  //   });
  // }

  // dispatchedAnimalAbattoirPriceInput(item: Models.DispatchedAnimal, abattoirPrice: any) {
  //   const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex(da => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
  //   if (index === -1) throw Error("index for dispatched animal not found!");
  //   abattoirPrice = parseFloat(abattoirPrice);
  //   if (isNaN(abattoirPrice)) abattoirPrice = undefined;
  //   this.$store.commit("updateUserLocationField", {
  //     path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirPrice`,
  //     value: abattoirPrice
  //   });
  //   //look for any other animals with the same grade, update their prices
  //   const grade = this.dispatchToAbattoirBatch.dispatchedAnimals[index].grade;
  //   if (grade) {
  //     for (let i = 0; i < this.dispatchToAbattoirBatch.dispatchedAnimals.length; ++i) {
  //       if (i === index) continue;
  //       if (this.dispatchToAbattoirBatch.dispatchedAnimals[i].grade === grade) {
  //         this.$store.commit("updateUserLocationField", {
  //           path: `dispatchToAbattoirBatch.dispatchedAnimals[${i}].abattoirPrice`,
  //           value: abattoirPrice
  //         });
  //         //Vue.set(this.dispatchToAbattoirBatch.dispatchedAnimals[i], "abattoirPrice", abattoirPrice);
  //         this.calculateDispatchedAnimalAppliedPrice(i);
  //       }
  //     }
  //   }
  //   this.calculateDispatchedAnimalAppliedPrice(index);
  // }

  // dispatchedAnimalAbattoirMassColdCarcassInput(item: Models.DispatchedAnimal, abattoirMassColdCarcass: any) {
  //   const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex(da => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
  //   if (index === -1) throw Error("index for dispatched animal not found!");
  //   abattoirMassColdCarcass = parseFloat(abattoirMassColdCarcass);
  //   if (isNaN(abattoirMassColdCarcass)) abattoirMassColdCarcass = undefined;
  //   this.$store.commit("updateUserLocationField", {
  //     path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirMassColdCarcass`,
  //     value: abattoirMassColdCarcass
  //   });
  //   this.calculateDispatchedAnimalAppliedPrice(index);
  // }

  async getDispatchedAnimals() {
    this.buttonStateGetDispatchedAnimals = "busy";
    try {
      const gql = `
        query dispatchedAnimals($guid: String!, $dispatchBatchGuid: String!, $dispatchBatchReference: String!, $type: String!) {
          dispatchedAnimals(
            guid: $guid,
            dispatchBatchGuid: $dispatchBatchGuid,
            dispatchBatchReference: $dispatchBatchReference,
            type: $type)
          {
            sgtin
            breed
            gender
            owner
            dispatchTime
            dispatchMassIndividual
            dispatchMassWeighbridge
            dispatchType
            dispatchReference
            referenceGuid
          }
        }
      `;
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: gql,
          variables: {
            guid: this.$store.state.user.location.guid,
            dispatchBatchGuid: this.dispatchToSiteBatch.guid,
            dispatchBatchReference: this.dispatchToSiteBatch.reference,
            type: "site",
          },
        },
        { root: true }
      );
      //console.log("graphQL: " + JSON.stringify(json));
      if (!json.data || !json.data.dispatchedAnimals) throw Error("No dispatchedAnimals returned.");

      let fromServerDispatchedAnimals: Models.DispatchedAnimal[] = json.data.dispatchedAnimals;
      console.dir(fromServerDispatchedAnimals);
      let dispatchedAnimals = this.dispatchToSiteBatch.dispatchedAnimals;

      //For now changed = true only if new animal added, not if existing animal changes somehow
      for (const fromServerDispatchedAnimal of fromServerDispatchedAnimals) {
        const dispatchedAnimalIndex = dispatchedAnimals.findIndex((da) => da.sgtin === fromServerDispatchedAnimal.sgtin);
        if (dispatchedAnimalIndex > -1) {
          //...dispatchedAnimals[dispatchedAnimalIndex] on right so that no entered values are lost
          dispatchedAnimals[dispatchedAnimalIndex] = { ...fromServerDispatchedAnimal, ...dispatchedAnimals[dispatchedAnimalIndex] };
        } else {
          dispatchedAnimals.push(fromServerDispatchedAnimal);
          this.changed = true;
        }
      }

      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.dispatchedAnimals", value: dispatchedAnimals });
      this.buttonStateGetDispatchedAnimals = "success";
      this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
      //this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
    } catch (err) {
      console.log("getDispatchedAnimals error: ", err);
      this.buttonStateGetDispatchedAnimals = "error";
    }
  }

  //This gets overwritten because user's settings can persist
  tableHeaders = [
    {
      text: "Tag ID",
      value: "sgtin",
      visible: true,
    },
    {
      text: "Breed",
      value: "breed",
      visible: false,
    },
    {
      text: "Gender",
      value: "gender",
      visible: false,
    },
    {
      text: "Owner",
      value: "owner",
      visible: false,
    },
    {
      text: "Dispatch Time",
      value: "dispatchTime",
      visible: true,
    },
    // {
    //   text: "Stunbox Time",
    //   value: "stunboxTime",
    //   visible: false,
    // },
    // {
    //   text: "Slaughter Time",
    //   value: "slaughterTime",
    //   visible: false,
    // },
    {
      text: "Dispatch Mass Individual",
      value: "dispatchMassIndividual",
      visible: true,
    },
    {
      text: "Dispatch Mass Weighbridge",
      value: "dispatchMassWeighbridge",
      visible: true,
    },
    // {
    //   text: "Abattoir Mass Weighbridge",
    //   value: "abattoirMassWeighbridge",
    //   visible: true,
    // },
    // {
    //   text: "Abattoir Mass Stunbox",
    //   value: "abattoirMassStunbox",
    //   visible: false,
    // },
    // {
    //   text: "Abattoir Mass Cold Carcass",
    //   value: "abattoirMassColdCarcass",
    //   visible: true,
    // },
    // {
    //   text: "Damage",
    //   value: "damage",
    //   visible: false,
    // },
    // {
    //   text: "Grade",
    //   value: "grade",
    //   visible: true,
    // },
    // {
    //   text: "Abattoir Price",
    //   value: "abattoirPrice",
    //   visible: true,
    // },
    // {
    //   text: "Weekly Price",
    //   value: "weeklyPrice",
    //   visible: true,
    // },
    // {
    //   text: "Applied Price",
    //   value: "appliedPrice",
    //   visible: true,
    // },
    // {
    //   text: "Abattoir Sales Price",
    //   value: "abattoirSalesPrice",
    //   visible: true,
    // },
    // {
    //   text: "dispatchType",
    //   value: "dispatchType",
    //   visible: true
    // },
    // {
    //   text: "dispatchReference",
    //   value: "dispatchReference",
    //   visible: true
    // },
    // {
    //   text: "referenceGuid",
    //   value: "referenceGuid",
    //   visible: true
    // }
  ];

  // tableHeaderChange() {
  //   this.$store.commit("updateUserLocationField", {
  //     path: `dispatchedAnimalsTableHeaders`,
  //     value: this.tableHeaders,
  //   });
  // }

  // dispatchAnimalGradeChange(item: Models.DispatchedAnimal) {
  //   const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex((da) => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
  //   if (index === -1) throw Error("index for dispatched animal not found!");
  //   console.log("dispatchAnimalGradeChange: " + index);
  //   const dispatchedAnimal = this.dispatchToAbattoirBatch.dispatchedAnimals[index];
  //   const grade = dispatchedAnimal.grade;
  //   console.log(grade);
  //   if (!grade) return;
  //   //find a different with same grade, use that animal's abattoirPrice
  //   //if anotherAbattoirPrice is undefined then no price has been set yet
  //   const anotherAbattoirPrice = this.dispatchToAbattoirBatch.dispatchedAnimals.find((da, i) => da.grade === grade && index !== i)?.abattoirPrice;
  //   this.$store.commit("updateUserLocationField", {
  //     path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirPrice`,
  //     value: anotherAbattoirPrice,
  //   });
  //   this.calculateDispatchedAnimalAppliedPrice(index);
  // }

  destinationFeedlotChange(destination: any) {
    console.log(destination);
    if (destination) {
      const d = {
        type: "Feedlot",
        guid: destination.guid,
        description: destination.name,
      };
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.transport[0].destination", value: d });
    } else this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.transport[0].destination", value: undefined });

    if (destination && destination.guid && destination.gcp) {
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationGcp", value: destination.gcp });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationGuid", value: destination.guid });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationType", value: "feedlot" });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationName", value: destination.name });
    } else {
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationGcp", value: undefined });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationGuid", value: undefined });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationType", value: undefined });
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.destinationName", value: undefined });
    }
  }

  transportCost: string = "";
  transportCostChange(transportCost: any) {
    this.transportCost = transportCost;
    if (isNaN(transportCost)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToSiteBatch.transport[0].cost`, value: undefined });
      this.transportCost = "";
    } else this.$store.commit("updateUserLocationField", { path: `dispatchToSiteBatch.transport[0].cost`, value: parseFloat(transportCost) });
  }

  transportQuantityDead: string = "";
  transportQuantityDeadChange(transportQuantityDead: any) {
    this.transportQuantityDead = transportQuantityDead;
    if (transportQuantityDead === "" || transportQuantityDead === undefined || isNaN(transportQuantityDead)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToSiteBatch.transport[0].quantityDead`, value: undefined });
      this.transportQuantityDead = "";
    } else this.$store.commit("updateUserLocationField", { path: `dispatchToSiteBatch.transport[0].quantityDead`, value: parseInt(transportQuantityDead) });
    //this.calculateQuantityAlive();
  }

  transportQuantityExpected: string = "";
  transportQuantityExpectedChange(transportQuantityExpected: any) {
    this.transportQuantityExpected = transportQuantityExpected;
    if (isNaN(transportQuantityExpected)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToSiteBatch.transport[0].quantityExpected`, value: undefined });
      this.transportQuantityExpected = "";
    } else
      this.$store.commit("updateUserLocationField", {
        path: `dispatchToSiteBatch.transport[0].quantityExpected`,
        value: parseInt(transportQuantityExpected),
      });
    //this.calculateQuantityAlive();
    this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
    // this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
  }

  get dispatchToSiteBatch(): Models.DispatchToSiteBatch {
    return this.$store.getters["storage"]().dispatchToSiteBatch;
  }

  mounted() {
    this.getDispatchedAnimals();
    //this.calculateSlaughterPercentage();
  }

  save() {
    if (!this.dispatchToSiteBatch.reference) {
      this.$store.commit("popup/displayOk", `Can not save, Reference required.`);
      return;
    }
    if (!this.dispatchToSiteBatch.transport[0].customFeeder) {
      this.$store.commit("popup/displayOk", `Can not save, Owner / Custom Feeder required.`);
      return;
    }
    if (!this.selectedDestinationFeedlot) {
      this.$store.commit("popup/displayOk", `Can not save, Destination Site required.`);
      return;
    }
    if (!this.dispatchToSiteBatch.transport[0].quantityExpected) {
      this.$store.commit("popup/displayOk", `Can not save, Quantity required.`);
      return;
    }
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().dispatchToSiteBatch);

          let dispatchToSiteBatchClone: Models.DispatchToSiteBatch = lodash.cloneDeep(this.$store.getters["storage"]().dispatchToSiteBatch);
          //Remove animals that have a different reference (reference changed after querying animals).
          dispatchToSiteBatchClone.dispatchedAnimals = dispatchToSiteBatchClone.dispatchedAnimals.filter(
            (da) => da.dispatchReference === dispatchToSiteBatchClone.reference
          );

          // if (!dispatchToSiteBatchClone.destinationGcp) throw Error("Invalid destination, gcp not found.");
          // if (!dispatchToSiteBatchClone.destinationGuid) throw Error("Invalid destination, guid not found.");

          //if ((dispatchToSiteBatchClone.totalColdCarcassMass as any) === "") dispatchToSiteBatchClone.totalColdCarcassMass = undefined;

          if (dispatchToSiteBatchClone && dispatchToSiteBatchClone.transport && dispatchToSiteBatchClone.transport.length) {
            let transport = dispatchToSiteBatchClone.transport[0];
            if ((transport.quantityExpected as any) === "") transport.quantityExpected = undefined;
            if ((transport.quantityAlive as any) === "") transport.quantityAlive = undefined;
            if ((transport.quantityDead as any) === "") transport.quantityDead = undefined;
          }

          if (
            dispatchToSiteBatchClone &&
            dispatchToSiteBatchClone.transport &&
            dispatchToSiteBatchClone.transport.length &&
            dispatchToSiteBatchClone.transport[0].transporter &&
            this.driver
          ) {
            dispatchToSiteBatchClone.transport[0].transporter.drivers = [this.driver];
          }

          dispatchToSiteBatchClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();

          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation setDispatchToSiteBatch($guid: String!, $dispatchToSiteBatch: DispatchToSiteBatchInput!) {
                setDispatchToSiteBatch(guid: $guid, dispatchToSiteBatch: $dispatchToSiteBatch) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, dispatchToSiteBatch: dispatchToSiteBatchClone },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let dispatchToSiteBatch = this.$store.getters["storage"]().dispatchToSiteBatch as Models.DispatchToSiteBatch;
          dispatchToSiteBatch.changeTracking = json.data.dispatchToSiteBatch;
          //TODO: remove code that saves models to indexedDB.
          //await this.$store.dispatch("data/set", { objectStore: "DispatchToSiteBatch", items: [lodash.cloneDeep(dispatchToSiteBatch)] });
          //this.$router.push({ path: "list/dispatch-to-site-batches" });
          this.changed = false;
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch", value: {} });
        this.$router.push({ path: "list/dispatch-to-site-batches" });
      },
    });
  }

  ////////
  // Custom Feeders

  customFeederListItems: { guid: string; text: string }[] = [];
  selectedCustomFeederListItem: { guid: string; text: string } | "" = "";
  getCustomFeederListItemsButtonState: ButtonState = "ready";
  getSelectedCustomFeederButtonState: ButtonState = "ready";

  get modelSelectedCustomFeederListItem(): { guid: string; text: string } | "" {
    return this.selectedCustomFeederListItem;
  }

  set modelSelectedCustomFeederListItem(selectedCustomFeederListItem: { guid: string; text: string } | "") {
    if (selectedCustomFeederListItem === undefined) selectedCustomFeederListItem = "";

    if (
      this.selectedCustomFeederListItem &&
      selectedCustomFeederListItem &&
      selectedCustomFeederListItem.guid &&
      this.selectedCustomFeederListItem.guid === selectedCustomFeederListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedCustomFeederListItem = selectedCustomFeederListItem;
    this.getCustomFeederFromServer();
  }

  async getCustomFeederFromServer() {
    if (!this.selectedCustomFeederListItem || !this.selectedCustomFeederListItem.guid) return;
    try {
      this.getSelectedCustomFeederButtonState = "busy";
      const customFeeder = await getItemFromServer("CustomFeeder", this.selectedCustomFeederListItem.guid);
      this.customFeeder = customFeeder;
      this.getSelectedCustomFeederButtonState = "success";
    } catch (err) {
      this.getSelectedCustomFeederButtonState = "error";
      console.error(err);
      this.customFeeder = "";
    }
  }

  async getCustomFeederListItems() {
    try {
      this.getCustomFeederListItemsButtonState = "busy";
      this.customFeederListItems = [];
      let items = await listCustomFeeder(true);
      this.customFeederListItems = items.map((i: any) => ({ guid: i.guid, text: `${i.name}${i.companyName ? ` - ${i.companyName}` : ``}` }));
      this.customFeederListItems = this.customFeederListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getCustomFeederListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getCustomFeederListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.customFeeder && !this.customFeederListItems.find((c) => c.guid === (this.customFeeder as Models.CustomFeeder).guid)) {
      const cf = this.customFeeder as Models.CustomFeeder;
      this.customFeederListItems.push({ guid: cf.guid, text: cf.name });
    }
  }

  get customFeeder(): Models.CustomFeeder | "" {
    let ret: Models.CustomFeeder | "" =
      this.dispatchToSiteBatch &&
      this.dispatchToSiteBatch.transport &&
      this.dispatchToSiteBatch.transport.length &&
      this.dispatchToSiteBatch.transport[0].customFeeder
        ? this.dispatchToSiteBatch.transport[0].customFeeder
        : "";
    return ret;
  }

  set customFeeder(customFeeder: Models.CustomFeeder | "") {
    // if (customFeeder) {
    //   (this.$refs["input-owner"] as any).error = false;
    //   (this.$refs["input-owner"] as any).errorMessages = [];
    // } else {
    //   (this.$refs["input-owner"] as any).error = false;
    //   (this.$refs["input-owner"] as any).errorMessages = ["Required."];
    // }
    this.$store.commit("updateUserLocationField", {
      path: "dispatchToSiteBatch.transport[0].customFeeder",
      value: customFeeder === "" ? undefined : customFeeder,
    });
  }

  // Custom Feeders
  ////////
  // Destination Feedlots

  destinationFeedlots: { guid: string; name: string; gcp: string }[] = [];
  selectedDestinationFeedlot: any = null;

  buttonStateGetDestinationFeedlots: ButtonState = "ready";
  isFetchingDestinationFeedlots: boolean = false;

  async getDestinationFeedlots() {
    this.isFetchingDestinationFeedlots = true;
    this.buttonStateGetDestinationFeedlots = "busy";
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `query destinationFeedlots($guid: String!) {
            destinationFeedlots(guid: $guid) {
              typename
              guid
              gcp
              name
            }
          }`,
          variables: {
            guid: this.$store.state.user.location.guid,
          },
        },
        { root: true }
      );
      console.log("graphQL:");
      console.dir(json);
      this.destinationFeedlots = json.data.destinationFeedlots;
      this.destinationFeedlots = this.destinationFeedlots.sort((p1, p2) => (p1.name > p2.name ? 1 : -1));
      this.buttonStateGetDestinationFeedlots = "success";
    } catch (err) {
      this.buttonStateGetDestinationFeedlots = "error";
      console.error(err);
    }
    this.isFetchingDestinationFeedlots = false;
  }

  // Destination Feedlots
  ////////
  // Transporters, Drivers

  transporterListItems: { guid: string; text: string }[] = [];
  selectedTransporterListItem: { guid: string; text: string } | "" = "";
  getTransporterListItemsButtonState: ButtonState = "ready";
  getSelectedTransporterButtonState: ButtonState = "ready";

  get modelSelectedTransporterListItem(): { guid: string; text: string } | "" {
    return this.selectedTransporterListItem;
  }

  set modelSelectedTransporterListItem(selectedTransporterListItem: { guid: string; text: string } | "") {
    if (selectedTransporterListItem === undefined) selectedTransporterListItem = "";

    if (
      this.selectedTransporterListItem &&
      selectedTransporterListItem &&
      selectedTransporterListItem.guid &&
      this.selectedTransporterListItem.guid === selectedTransporterListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedTransporterListItem = selectedTransporterListItem;
    this.getTransporterFromServer();
  }

  async getTransporterFromServer() {
    if (!this.selectedTransporterListItem || !this.selectedTransporterListItem.guid) return;
    try {
      this.getSelectedTransporterButtonState = "busy";
      const transporter = await getItemFromServer("Transporter", this.selectedTransporterListItem.guid);
      this.transporter = transporter;
      this.getSelectedTransporterButtonState = "success";
    } catch (err) {
      this.getSelectedTransporterButtonState = "error";
      console.error(err);
      this.transporter = "";
    }
  }

  async getTransporterListItems() {
    try {
      this.getTransporterListItemsButtonState = "busy";
      this.transporterListItems = [];
      let items = await listTransporter(true);
      this.transporterListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.transporterListItems = this.transporterListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getTransporterListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getTransporterListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.transporter && !this.transporterListItems.find((c) => c.guid === (this.transporter as Models.Transporter).guid)) {
      const t = this.transporter as Models.Transporter;
      this.transporterListItems.push({ guid: t.guid, text: t.name });
    }
  }

  get transporter(): Models.Transporter | "" {
    return this.dispatchToSiteBatch.transport && this.dispatchToSiteBatch.transport.length && this.dispatchToSiteBatch.transport[0].transporter
      ? this.dispatchToSiteBatch.transport[0].transporter
      : "";
  }

  set transporter(transporter: Models.Transporter | "") {
    this.$store.commit("updateUserLocationField", {
      path: "dispatchToSiteBatch.transport[0].transporter",
      value: transporter === "" ? undefined : lodash.cloneDeep(transporter),
    });
    if (transporter) {
      this.drivers = lodash.cloneDeep(transporter.drivers);
      if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";
    } else {
      this.drivers = [];
      this.driver = "";
    }
  }

  drivers: Models.Driver[] = [];

  set driver(driver: Models.Driver | "") {
    this.$store.commit("updateUserLocationField", { path: "selectedDriver", value: driver ? driver : undefined });
  }

  get driver(): Models.Driver | "" {
    const driver = this.$store.getters["getUserLocationField"]("selectedDriver");
    return driver ? driver : "";
  }

  // Transporters, Drivers
  ////////

  // drivers: Models.Driver[] = [];

  // set driver(driver: Models.Driver | "") {
  //   if (this.transporter) {
  //     // if (driver) this.transporter.drivers = [driver];
  //     // else this.transporter.drivers = [];
  //     this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].transporter.drivers", value: driver ? [driver] : [] });
  //   }
  // }

  // get driver(): Models.Driver | "" {
  //   let dispatchToAbattoirBatch = this.dispatchToAbattoirBatch;
  //   return dispatchToAbattoirBatch &&
  //     dispatchToAbattoirBatch.transport.length &&
  //     dispatchToAbattoirBatch.transport[0].transporter &&
  //     dispatchToAbattoirBatch.transport[0].transporter.drivers &&
  //     dispatchToAbattoirBatch.transport[0].transporter.drivers.length
  //     ? dispatchToAbattoirBatch.transport[0].transporter.drivers[0]
  //     : "";
  // }

  // get transporter(): Models.Transporter | "" {
  //   return this.dispatchToAbattoirBatch.transport && this.dispatchToAbattoirBatch.transport.length && this.dispatchToAbattoirBatch.transport[0].transporter
  //     ? this.dispatchToAbattoirBatch.transport[0].transporter
  //     : "";
  // }

  // set transporter(transporter: Models.Transporter | "") {
  //   this.$store.commit("updateUserLocationField", {
  //     path: "dispatchToAbattoirBatch.transport[0].transporter",
  //     value: transporter === "" ? undefined : lodash.cloneDeep(transporter)
  //   });
  //   if (transporter) {
  //     let storedTransporter = lodash.find(this.transporters, { guid: transporter.guid });
  //     this.drivers = storedTransporter ? lodash.cloneDeep(storedTransporter.drivers).filter(d => d.active) : [];
  //     if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";
  //   } else {
  //     this.drivers = [];
  //     this.driver = "";
  //   }
  // }

  get dispatchWeighbridgeMass() {
    //console.log("get dispatchWeighbridgeMass");
    if (!this.dispatchToSiteBatch.transport || !this.dispatchToSiteBatch.transport.length) return "";
    let dispatchWeighbridge = lodash.find(this.dispatchToSiteBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    if (dispatchWeighbridge) return dispatchWeighbridge.mass;
    else return "";
  }

  set dispatchWeighbridgeMass(dispatchWeighbridgeMass) {
    //console.log("set dispatchWeighbridgeMass");
    let weighbridges = this.dispatchToSiteBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "dispatch" });
    if (dispatchWeighbridgeMass === "") dispatchWeighbridgeMass = undefined;
    if (i >= 0) {
      weighbridges[i].mass = dispatchWeighbridgeMass;
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(dispatchWeighbridgeMass);
    this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
    //this.calculateSlaughterPercentage();
  }

  get dispatchWeighbridgeReference() {
    //console.log("get dispatchWeighbridgeReference");
    if (!this.dispatchToSiteBatch.transport || !this.dispatchToSiteBatch.transport.length) return "";
    let dispatchWeighbridge = lodash.find(this.dispatchToSiteBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    if (dispatchWeighbridge) return dispatchWeighbridge.reference;
    else return "";
  }

  set dispatchWeighbridgeReference(dispatchWeighbridgeReference) {
    //console.log("set dispatchWeighbridgeReference");
    let weighbridges = this.dispatchToSiteBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "dispatch" });
    if (dispatchWeighbridgeReference === "") dispatchWeighbridgeReference = undefined;
    if (i >= 0) {
      weighbridges[i].reference = dispatchWeighbridgeReference;
      this.$store.commit("updateUserLocationField", { path: "dispatchToSiteBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(dispatchWeighbridgeReference);
  }

  // get abattoirWeighbridgeMass() {
  //   //console.log("get abattoirWeighbridgeMass");
  //   if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
  //   let abattoirWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "abattoir");
  //   if (abattoirWeighbridge) return abattoirWeighbridge.mass;
  //   else return "";
  // }

  // set abattoirWeighbridgeMass(abattoirWeighbridgeMass) {
  //   //console.log("set abattoirWeighbridgeMass");
  //   let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
  //   let i = lodash.findIndex(this.dispatchToAbattoirBatch.transport[0].weighbridges, { location: "abattoir" });
  //   if (abattoirWeighbridgeMass === "") abattoirWeighbridgeMass = undefined;
  //   if (i >= 0) {
  //     weighbridges[i].mass = abattoirWeighbridgeMass;
  //     this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
  //   }
  //   //console.log(abattoirWeighbridgeMass);
  //   this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
  // }

  // get abattoirWeighbridgeReference() {
  //   //console.log("get abattoirWeighbridgeReference");
  //   if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
  //   let abattoirWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "abattoir");
  //   if (abattoirWeighbridge) return abattoirWeighbridge.reference;
  //   else return "";
  // }

  // set abattoirWeighbridgeReference(abattoirWeighbridgeReference) {
  //   //console.log("set abattoirWeighbridgeReference");
  //   let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
  //   let i = lodash.findIndex(this.dispatchToAbattoirBatch.transport[0].weighbridges, { location: "abattoir" });
  //   if (abattoirWeighbridgeReference === "") abattoirWeighbridgeReference = undefined;
  //   if (i >= 0) {
  //     weighbridges[i].reference = abattoirWeighbridgeReference;
  //     this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
  //   }
  //   //console.log(abattoirWeighbridgeReference);
  // }
}
