import store from "@/store";
import * as Models from "@gigalot/data-models";

export async function getItemFromServer(typename: string, itemGuid: string) {
  let queryName: string = `get${typename}`;
  const gql = (Models.gql.queries as any)[queryName]; //i.e Models.gql.buyer
  if (!gql) throw Error(`getItemFromServer() error: Models.gql.queries for ${queryName} not found.`);
  let json = await store.dispatch(
    "graphQl",
    {
      gql: gql,
      variables: {
        guid: (store as any).state.user.location.guid,
        itemGuid: itemGuid
      }
    },
    { root: true }
  );
  return json.data[queryName]; //i.e getBuyer query returns data.getBuyer which contains a Buyer model
}
