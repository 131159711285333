import * as Models from "@gigalot/data-models";
import store from "@/store";

export async function getIncomingBatches(incomingBatchGuids?: string[]): Promise<Models.IncomingBatch[]> {
  console.log("getIncomingBatches");
  let json = await store.dispatch(
    "graphQl",
    {
      gql: `query incomingBatches($guid: String!, $incomingBatchGuids: [String!]) {
          incomingBatches(guid: $guid, incomingBatchGuids: $incomingBatchGuids) {
            typename
            guid
            originGuid
            originGcp
            originType
            originName
            dispatchType
            dispatchReference
            dispatchTime
            animals {
              sgtin
              breed
              gender
              dispatchTime
            }
            active
            processedSgtins
          }
        }`,
      variables: {
        guid: (store.state as any).user.location.guid,
        incomingBatchGuids: incomingBatchGuids,
      },
    },
    { root: true }
  );
  console.log("graphQL:");
  console.dir(json);
  return json.data.incomingBatches;
}