var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" Lost Tags ")])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Notes"},on:{"input":function($event){_vm.changed = true}},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Reference Type","items":_vm.referenceTypes,"clearable":""},on:{"input":function($event){_vm.changed = true}},model:{value:(_vm.refType),callback:function ($$v) {_vm.refType=$$v},expression:"refType"}})],1)],1),(_vm.selectReferenceOrDate === 'ref')?_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 2}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.fromDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fromDate=$event},"update:return-value":function($event){_vm.fromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"From Date","prepend-icon":"event","readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},on))]}}],null,false,1174340781),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.changed = true;
            _vm.$refs.fromDateMenu.save(_vm.fromDate);}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 2}},[_c('v-menu',{ref:"toDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.toDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.toDate=$event},"update:return-value":function($event){_vm.toDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"To Date","prepend-icon":"event","readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},on))]}}],null,false,4207181901),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.changed = true;
            _vm.$refs.toDateMenu.save(_vm.toDate);}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 8}},[_c('v-autocomplete',{attrs:{"disabled":_vm.referenceDisabled,"label":"Reference","items":_vm.referenceItems,"item-key":"guid","return-object":"","item-text":"reference","clearable":""},on:{"input":function($event){_vm.changed = true}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.getReferenceListItemsButtonState},on:{"onclick":function($event){return _vm.getReferenceListItems()}}},[_vm._v(" Refresh ")])],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference)+" - "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference)+" - "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}}],null,false,3499583746),model:{value:(_vm.selectedReferenceItem),callback:function ($$v) {_vm.selectedReferenceItem=$$v},expression:"selectedReferenceItem"}})],1)],1):(_vm.selectReferenceOrDate === 'date')?_c('v-row',[_c('v-col',[_c('v-menu',{ref:"lostTagsDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.lostTagsDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.lostTagsDate=$event},"update:return-value":function($event){_vm.lostTagsDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date","prepend-icon":"event","readonly":""},model:{value:(_vm.lostTagsDate),callback:function ($$v) {_vm.lostTagsDate=$$v},expression:"lostTagsDate"}},on))]}}]),model:{value:(_vm.lostTagsDateMenu),callback:function ($$v) {_vm.lostTagsDateMenu=$$v},expression:"lostTagsDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.changed = true;
            _vm.$refs.lostTagsDateMenu.save(_vm.lostTagsDate);}},model:{value:(_vm.lostTagsDate),callback:function ($$v) {_vm.lostTagsDate=$$v},expression:"lostTagsDate"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Tags")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{key:_vm.uiKey,attrs:{"label":"Tag ID","items":_vm.animalListItems.filter(function (a) { return !_vm.lostTags.lostTags.find(function (t) { return t.sgtin === a.sgtin; }); }),"item-key":"guid","item-text":"tagId","return-object":"","clearable":"","search-input":_vm.animalListItemSearch,"no-data-text":"Start typing to search"},on:{"update:searchInput":function($event){_vm.animalListItemSearch=$event},"update:search-input":function($event){_vm.animalListItemSearch=$event}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.refreshAnimalListItemsButtonState},on:{"onclick":function($event){return _vm.refreshAnimalListItems()}}},[_vm._v(" Refresh ")])],1)],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.breed || item.gender ? ((_vm.tagId(item.sgtin)) + " - " + (item.breed) + " - " + (item.gender[0].toUpperCase() + item.gender.slice(1).toLowerCase()) + " - " + (item.owner)) : ("" + (_vm.tagId(item.sgtin))))+" ")]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.breed || item.gender ? ((_vm.tagId(item.sgtin)) + " - " + (item.breed) + " - " + (item.gender[0].toUpperCase() + item.gender.slice(1).toLowerCase()) + " - " + (item.owner)) : ("" + (_vm.tagId(item.sgtin))))+" ")]}}]),model:{value:(_vm.selectedAnimalListItem),callback:function ($$v) {_vm.selectedAnimalListItem=$$v},expression:"selectedAnimalListItem"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":_vm.connectedToProxyButtonState},on:{"onclick":function($event){return _vm.scanTag()}}},[_vm._v(" Scan ")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.addTag()}}},[_vm._v("Add")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{key:_vm.uiKey + 1,attrs:{"hide-default-footer":"","headers":[
                  { text: 'Tag ID', value: 'visualTag' },
                  { text: 'Breed', value: 'breed' },
                  { text: 'Gender', value: 'gender' },
                  { text: 'Owner', value: 'owner' },
                  { text: 'Actions', value: 'actions', sortable: false } ],"items":_vm.lostTags.lostTags},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No Tags ")]},proxy:true},{key:"item.visualTag",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tagId(item.sgtin))+" ")]}},{key:"item.gender",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.gender ? item.gender[0].toUpperCase() + item.gender.slice(1).toLowerCase() : "")+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeTag(item)}}},[_vm._v(" delete ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }