








































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { tagId } from "@/helpers/visual-tag";
import wNumb from "wnumb";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listCustomFeeder, listTransporter } from "@/helpers/graphql-list-items";
import { capitalize } from "@/helpers";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields([
      "dispatchToAbattoirBatch.reference",
      "dispatchToAbattoirBatch.description",
      "dispatchToAbattoirBatch.notes",
      "dispatchToAbattoirBatch.settled",
      "dispatchToAbattoirBatch.totalColdCarcassMass",
      "dispatchToAbattoirBatch.totalAbattoirSalesPrice",
    ]),
  },
  mixins: [validationMixin],
  validations: {
    reference: { required },
    transportQuantityExpected: { required },
    modelSelectedCustomFeederListItem: { required },
    selectedDestinationAbattoir: { required },
  },
})
export default class DispatchToAbattoirBatch extends Vue {
  buttonStateGetDispatchedAnimals: ButtonState = "ready";

  get abattoirSalesPrice(): string {
    // const totalColdCarcassMass = this.dispatchToAbattoirBatch.totalColdCarcassMass;
    // const totalAbattoirSalesPrice = this.dispatchToAbattoirBatch.totalAbattoirSalesPrice
    if ([0, "", undefined, null].includes(this.dispatchToAbattoirBatch.totalColdCarcassMass)
      || ["", undefined, null].includes(this.dispatchToAbattoirBatch.totalAbattoirSalesPrice as any)) {
      return ""
    }
    return ((this.dispatchToAbattoirBatch.totalAbattoirSalesPrice as number) / (this.dispatchToAbattoirBatch.totalColdCarcassMass as number)).toFixed(2);
    
  }

  gradePrices: { grade: string; price: number | "" }[] = [{ grade: "", price: "" }];

  get availableGrades() {
    //Filter out grades that are not already selected in gradePrices
    return this.grades.filter((g) => !this.gradePrices.map((gp) => gp.grade).includes(g));
  }

  get numGradePricesLeft() {
    return this.availableGrades.length - this.gradePrices.filter((gp) => !gp.grade).length;
  }

  // get isGradePricesAvailable() {
  //   //Filter out grades that are not already selected in gradePrices
  //   return this.grades.filter((g) => !this.gradePrices.map((gp) => gp.grade).includes(g)).length > 0;
  // }

  addGradePrice() {
    this.gradePrices.push({ grade: "", price: "" });
  }

  dispatchedAnimalTableUpdateKey = 0;

  applyGradePrice(item: { grade: string; price: number }) {
    if (!item.grade) {
      console.error("Invalid grade.");
    }
    if (item.price.toString() === "" || isNaN(item.price)) {
      console.error("Invalid price.");
      return;
    }

    const dispatchedAnimals = this.dispatchToAbattoirBatch.dispatchedAnimals;

    for (let i = 0; i < dispatchedAnimals.length; ++i) {
      const da = dispatchedAnimals[i];

      if (da.grade === item.grade) {
        this.$store.commit("updateUserLocationField", {
          path: `dispatchToAbattoirBatch.dispatchedAnimals[${i}].abattoirPrice`,
          value: item.price,
        });

        this.dispatchedAnimalTableUpdateKey++;
      }
    }
  }

  moment = this.$store.state.moment;

  capitalize = capitalize;

  slaughterPercentage: number = NaN;

  changed: boolean = false;

  tab: any = null;

  tagIdFilter: string | null = "";

  defaultGrade: string = "";

  defaultGradeChange() {
    if (!this.defaultGrade) return;
    const dispatchedAnimals = this.dispatchToAbattoirBatch.dispatchedAnimals;
    for (let i = 0; i < dispatchedAnimals.length; ++i) {
      const da = dispatchedAnimals[i];
      if (!da.grade) {
        this.$store.commit("updateUserLocationField", {
          path: `dispatchToAbattoirBatch.dispatchedAnimals[${i}].grade`,
          value: this.defaultGrade,
        });
      }
    }
  }

  @Watch("changed")
  onChangeChanged(val: any) {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch", value: {} });
          this.$router.push({ path: "list/dispatch-to-abattoir-batches" });
        },
      });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch", value: {} });
        this.$router.push({ path: "list/dispatch-to-abattoir-batches" });
      },
    });

    if (this.dispatchToAbattoirBatch.reference === undefined) {
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.reference", value: "" });
    }

    if (!this.dispatchToAbattoirBatch.dispatchedAnimals) this.dispatchToAbattoirBatch.dispatchedAnimals = [];
    if (!this.dispatchToAbattoirBatch.transport) this.dispatchToAbattoirBatch.transport = [];
    if (!this.dispatchToAbattoirBatch.transport.length) {
      this.dispatchToAbattoirBatch.transport[0] = new Models.Transport();
    }

    const weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
    if (!weighbridges.find((wb) => wb.location === "dispatch")) weighbridges.push({ location: "dispatch", reference: "", mass: undefined });
    if (!weighbridges.find((wb) => wb.location === "abattoir")) weighbridges.push({ location: "abattoir", reference: "", mass: undefined });

    if (!this.dispatchToAbattoirBatch.transport[0].origin) {
      //this can be ignored, we should have left it out but it's too much effort to remove now
      this.dispatchToAbattoirBatch.transport[0].origin = new Models.Origin();
      this.dispatchToAbattoirBatch.transport[0].origin.country = "";
      this.dispatchToAbattoirBatch.transport[0].origin.province = "";
      this.dispatchToAbattoirBatch.transport[0].origin.town = "";
      this.dispatchToAbattoirBatch.transport[0].origin.code = "";
    }

    this.transportCostChange(this.dispatchToAbattoirBatch.transport[0].cost);
    this.transportQuantityDeadChange(this.dispatchToAbattoirBatch.transport[0].quantityDead);
    //this.transportDistanceChange(this.dispatchToAbattoirBatch.transport[0].distance);
    if (this.dispatchToAbattoirBatch.transport && this.dispatchToAbattoirBatch.transport.length)
      this.transportQuantityExpectedChange(this.dispatchToAbattoirBatch.transport[0].quantityExpected);

    if (!this.dispatchToAbattoirBatch.dispatchType) this.dispatchToAbattoirBatch.dispatchType = "single";

    ////////
    // table headers
    // dispatchedAnimalsTableHeaders is not being used anymore, clearing it out if found
    // this code can be remove later
    if (this.$store.getters["getUserLocationField"]("dispatchedAnimalsTableHeaders"))
      this.$store.commit("updateUserLocationField", {
        path: `dispatchedAnimalsTableHeaders`,
        value: undefined,
      });
    ////////

    if (this.transporter) {
      //try to get drivers from server. If not successful then just use selected [driver] in this.transporter.drivers
      try {
        let t = await getItemFromServer("Transporter", this.transporter.guid);
        this.drivers = t.drivers;
      } catch (err) {
        this.drivers = this.transporter.drivers;
      }
    } else {
      this.drivers = [];
      this.driver = "";
    }

    if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";

    if (this.customFeeder) {
      const cf = this.customFeeder;
      this.selectedCustomFeederListItem = { guid: cf.guid, text: `${cf.name}${cf.companies[0]?.name ? ` - ${cf.companies[0]?.name}` : ``}` };
      this.getSelectedCustomFeederButtonState = "success";
    }
    this.getCustomFeederListItems();

    if (this.transporter) {
      const t = this.transporter;
      this.selectedTransporterListItem = { guid: t.guid, text: t.name };
      this.getSelectedTransporterButtonState = "success";
    }
    this.getTransporterListItems();

    //this.destinationAbattoirs = (await this.$store.dispatch("data/get", { objectStore: "DestinationAbattoir" })) as Models.Abattoir[];
    await this.getDestinationAbattoirs();
    if (this.dispatchToAbattoirBatch.transport[0]?.destination?.guid) {
      const guid = this.dispatchToAbattoirBatch.transport[0]?.destination?.guid;
      const da = this.destinationAbattoirs.find((da) => da.guid === guid);
      if (da) {
        this.selectedDestinationAbattoir = da;
      } else {
        //add destination abattoir to list if not found
        const destination = this.dispatchToAbattoirBatch.transport[0]?.destination;
        if (destination.guid && destination.description) {
          const t = { guid: destination.guid, name: destination.description };
          this.destinationAbattoirs.push(t);
          this.selectedDestinationAbattoir = t;
        }
      }
    }
    //this.getDestinationAbattoirs();
  }

  calculateSlaughterPercentage() {
    let dispatchWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    const dispatchWeighbridgeMass = dispatchWeighbridge?.mass;
    const totalColdCarcassMass = this.$store.getters["getUserLocationField"]("dispatchToAbattoirBatch.totalColdCarcassMass");
    if ([0, undefined, ""].includes(dispatchWeighbridgeMass as any)) {
      this.slaughterPercentage = NaN;
    } else if ([undefined, ""].includes(totalColdCarcassMass as any)) {
      this.slaughterPercentage = NaN;
    } else this.slaughterPercentage = (totalColdCarcassMass / (dispatchWeighbridgeMass ?? 1)) * 100;
  }

  totalColdCarcassMassChange() {
    this.calculateSlaughterPercentage();
  }

  calculateDispatchedAnimalsDispatchWeighbridgeMass() {
    const quantity = this.dispatchToAbattoirBatch.transport[0].quantityExpected;
    if (quantity === undefined || quantity <= 0) {
      for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = undefined;
      return;
    }
    let dispatchWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    const weighbridgeMass = dispatchWeighbridge?.mass;
    if (weighbridgeMass === undefined) {
      for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = undefined;
      return;
    }
    const avgMass = weighbridgeMass / quantity;
    for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.dispatchMassWeighbridge = avgMass;
  }

  calculateDispatchedAnimalsAbattoirWeighbridgeMass() {
    const quantity = this.dispatchToAbattoirBatch.transport[0].quantityExpected;
    if (quantity === undefined || quantity <= 0) {
      for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.abattoirMassWeighbridge = undefined;
      return;
    }
    let abattoirWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "abattoir");
    const weighbridgeMass = abattoirWeighbridge?.mass;
    if (weighbridgeMass === undefined) {
      for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.abattoirMassWeighbridge = undefined;
      return;
    }
    const avgMass = weighbridgeMass / quantity;
    for (const dispatchedAnimal of this.dispatchToAbattoirBatch.dispatchedAnimals) dispatchedAnimal.abattoirMassWeighbridge = avgMass;
  }

  // calculateDispatchedAnimalAppliedPrice(index: number) {
  //   const dispatchedAnimal = this.dispatchToAbattoirBatch.dispatchedAnimals[index];
  //   const abattoirPrice = dispatchedAnimal.abattoirPrice;
  //   const abattoirMassColdCarcass = dispatchedAnimal.abattoirMassColdCarcass;
  //   if (abattoirPrice === undefined || abattoirMassColdCarcass === undefined) {
  //     this.$store.commit("updateUserLocationField", {
  //       path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].appliedPrice`,
  //       value: undefined,
  //     });
  //     return;
  //   }
  //   const appliedPrice = dispatchedAnimal.appliedPrice;
  //   if (appliedPrice !== abattoirPrice * abattoirMassColdCarcass)
  //     this.$store.commit("updateUserLocationField", {
  //       path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].appliedPrice`,
  //       value: abattoirPrice * abattoirMassColdCarcass,
  //     });
  // }

  currencyFormat(amt: any) {
    return wNumb({
      mark: ".",
      thousand: " ",
      prefix: "R ",
      decimals: 2,
    }).to(parseFloat(amt));
  }

  massFormat(amt: any) {
    return wNumb({
      mark: ".",
      thousand: " ",
      suffix: " kg",
      decimals: 2,
    }).to(parseFloat(amt));
  }

  get grades() {
    let ret: string[] = [];
    let t0 = ["A", "B", "AB", "C"];
    let t1: string[] = [];
    for (let i = 0; i <= 6; ++i) t1.push(`${i}`);
    for (const prefix of t0) for (const suffix of t1) ret.push(`${prefix}${suffix}`);
    return ret;
  }

  tagId = tagId; //imported function as a local function so that it can be used in Vue template

  dispatchedAnimalGradeInput(item: Models.DispatchedAnimal, grade: any) {
    const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex((da) => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
    if (index === -1) throw Error("index for dispatched animal not found!");
    this.$store.commit("updateUserLocationField", {
      path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].grade`,
      value: grade,
    });
  }

  dispatchedAnimalAbattoirPriceInput(item: Models.DispatchedAnimal, abattoirPrice: any) {
    const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex((da) => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
    if (index === -1) throw Error("index for dispatched animal not found!");
    abattoirPrice = parseFloat(abattoirPrice);
    if (isNaN(abattoirPrice)) abattoirPrice = undefined;
    this.$store.commit("updateUserLocationField", {
      path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirPrice`,
      value: abattoirPrice,
    });
    //look for any other animals with the same grade, update their prices
    // const grade = this.dispatchToAbattoirBatch.dispatchedAnimals[index].grade;
    // if (grade) {
    //   for (let i = 0; i < this.dispatchToAbattoirBatch.dispatchedAnimals.length; ++i) {
    //     if (i === index) continue;
    //     if (this.dispatchToAbattoirBatch.dispatchedAnimals[i].grade === grade) {
    //       this.$store.commit("updateUserLocationField", {
    //         path: `dispatchToAbattoirBatch.dispatchedAnimals[${i}].abattoirPrice`,
    //         value: abattoirPrice,
    //       });
    //       //Vue.set(this.dispatchToAbattoirBatch.dispatchedAnimals[i], "abattoirPrice", abattoirPrice);
    //       //this.calculateDispatchedAnimalAppliedPrice(i);
    //     }
    //   }
    // }
    //this.calculateDispatchedAnimalAppliedPrice(index);
  }

  dispatchedAnimalAbattoirMassColdCarcassInput(item: Models.DispatchedAnimal, abattoirMassColdCarcass: any) {
    const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex((da) => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
    if (index === -1) throw Error("index for dispatched animal not found!");
    abattoirMassColdCarcass = parseFloat(abattoirMassColdCarcass);
    if (isNaN(abattoirMassColdCarcass)) abattoirMassColdCarcass = undefined;
    this.$store.commit("updateUserLocationField", {
      path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirMassColdCarcass`,
      value: abattoirMassColdCarcass,
    });
    //this.calculateDispatchedAnimalAppliedPrice(index);
  }

  async getDispatchedAnimals() {
    this.buttonStateGetDispatchedAnimals = "busy";
    try {
      const gql = `
        query dispatchedAnimals($guid: String!, $dispatchBatchGuid: String!, $dispatchBatchReference: String!, $type: String!) {
          dispatchedAnimals(
            guid: $guid,
            dispatchBatchGuid: $dispatchBatchGuid,
            dispatchBatchReference: $dispatchBatchReference,
            type: $type)
          {
            sgtin
            breed
            gender
            owner
            dispatchTime
            dispatchMassIndividual
            dispatchMassWeighbridge
            abattoirMassWeighbridge
            dispatchType
            dispatchReference
            referenceGuid
          }
        }
      `;
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: gql,
          variables: {
            guid: this.$store.state.user.location.guid,
            dispatchBatchGuid: this.dispatchToAbattoirBatch.guid,
            dispatchBatchReference: this.dispatchToAbattoirBatch.reference,
            type: "abattoir",
          },
        },
        { root: true }
      );
      //console.log("graphQL: " + JSON.stringify(json));
      if (!json.data || !json.data.dispatchedAnimals) throw Error("No dispatchedAnimals returned.");
      let fromServerDispatchedAnimals: Models.DispatchedAnimal[] = json.data.dispatchedAnimals;
      console.dir(fromServerDispatchedAnimals);
      let dispatchedAnimals = this.dispatchToAbattoirBatch.dispatchedAnimals;

      //For now changed = true only if new animal added, not if existing animal changes somehow
      for (const fromServerDispatchedAnimal of fromServerDispatchedAnimals) {
        const dispatchedAnimalIndex = dispatchedAnimals.findIndex((da) => da.sgtin === fromServerDispatchedAnimal.sgtin);
        if (dispatchedAnimalIndex > -1) {
          //...dispatchedAnimals[dispatchedAnimalIndex] on right so that no entered values are lost
          dispatchedAnimals[dispatchedAnimalIndex] = { ...fromServerDispatchedAnimal, ...dispatchedAnimals[dispatchedAnimalIndex] };
        } else {
          dispatchedAnimals.push(fromServerDispatchedAnimal);
          this.changed = true;
        }
      }

      if (this.defaultGrade) {
        for (const da of dispatchedAnimals) {
          if (!da.grade) {
            da.grade = this.defaultGrade;
          }
        }
      }

      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.dispatchedAnimals", value: dispatchedAnimals });
      this.buttonStateGetDispatchedAnimals = "success";
      this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
      this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
    } catch (err) {
      console.log("getDispatchedAnimals error: ", err);
      this.buttonStateGetDispatchedAnimals = "error";
    }
  }

  dispatchAnimalGradeChange(item: Models.DispatchedAnimal) {
    const index = this.dispatchToAbattoirBatch.dispatchedAnimals.findIndex((da) => da.sgtin === item.sgtin && da.dispatchReference === item.dispatchReference);
    if (index === -1) throw Error("index for dispatched animal not found!");
    console.log("dispatchAnimalGradeChange: " + index);
    const dispatchedAnimal = this.dispatchToAbattoirBatch.dispatchedAnimals[index];
    const grade = dispatchedAnimal.grade;
    console.log(grade);
    if (!grade) return;

    const gp = this.gradePrices.find((o) => o.grade === grade);
    this.$store.commit("updateUserLocationField", {
      path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirPrice`,
      value: gp && gp.price !== "" ? gp.price : undefined,
    });
    this.dispatchedAnimalTableUpdateKey++;

    //find a different with same grade, use that animal's abattoirPrice
    //if anotherAbattoirPrice is undefined then no price has been set yet
    // const anotherAbattoirPrice = this.dispatchToAbattoirBatch.dispatchedAnimals.find((da, i) => da.grade === grade && index !== i)?.abattoirPrice;
    // this.$store.commit("updateUserLocationField", {
    //   path: `dispatchToAbattoirBatch.dispatchedAnimals[${index}].abattoirPrice`,
    //   value: anotherAbattoirPrice,
    // });
    //this.calculateDispatchedAnimalAppliedPrice(index);
  }

  destinationAbattoirChange(destinationAbattoir: any) {
    console.log(destinationAbattoir);
    if (destinationAbattoir) {
      const destination = {
        type: "Abattoir",
        guid: destinationAbattoir.guid,
        description: destinationAbattoir.name,
      };
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].destination", value: destination });
    } else this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].destination", value: undefined });
  }

  transportCost: string = "";
  transportCostChange(transportCost: any) {
    this.transportCost = transportCost;
    if (isNaN(transportCost)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToAbattoirBatch.transport[0].cost`, value: undefined });
      this.transportCost = "";
    } else this.$store.commit("updateUserLocationField", { path: `dispatchToAbattoirBatch.transport[0].cost`, value: parseFloat(transportCost) });
  }

  transportQuantityDead: string = "";
  transportQuantityDeadChange(transportQuantityDead: any) {
    this.transportQuantityDead = transportQuantityDead;
    if (transportQuantityDead === "" || transportQuantityDead === undefined || isNaN(transportQuantityDead)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToAbattoirBatch.transport[0].quantityDead`, value: undefined });
      this.transportQuantityDead = "";
    } else this.$store.commit("updateUserLocationField", { path: `dispatchToAbattoirBatch.transport[0].quantityDead`, value: parseInt(transportQuantityDead) });
    //this.calculateQuantityAlive();
  }

  transportQuantityExpected: string = "";
  transportQuantityExpectedChange(transportQuantityExpected: any) {
    this.transportQuantityExpected = transportQuantityExpected;
    if (isNaN(transportQuantityExpected)) {
      this.$store.commit("updateUserLocationField", { path: `dispatchToAbattoirBatch.transport[0].quantityExpected`, value: undefined });
      this.transportQuantityExpected = "";
    } else
      this.$store.commit("updateUserLocationField", {
        path: `dispatchToAbattoirBatch.transport[0].quantityExpected`,
        value: parseInt(transportQuantityExpected),
      });
    //this.calculateQuantityAlive();
    this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
    this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
  }

  get dispatchToAbattoirBatch(): Models.DispatchToAbattoirBatch {
    return this.$store.getters["storage"]().dispatchToAbattoirBatch;
  }

  mounted() {
    this.getDispatchedAnimals();
    this.calculateSlaughterPercentage();
  }

  save() {
    if (!this.dispatchToAbattoirBatch.reference) {
      this.$store.commit("popup/displayOk", `Can not save, Reference required.`);
      return;
    }
    if (!this.dispatchToAbattoirBatch.transport[0].customFeeder) {
      this.$store.commit("popup/displayOk", `Can not save, Owner / Custom Feeder required.`);
      return;
    }
    if (!this.selectedDestinationAbattoir) {
      this.$store.commit("popup/displayOk", `Can not save, Destination Abattoir required.`);
      return;
    }
    if (!this.dispatchToAbattoirBatch.transport[0].quantityExpected) {
      this.$store.commit("popup/displayOk", `Can not save, Quantity required.`);
      return;
    }
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().dispatchToAbattoirBatch);

          let dispatchToAbattoirBatchClone: Models.DispatchToAbattoirBatch = lodash.cloneDeep(this.$store.getters["storage"]().dispatchToAbattoirBatch);
          //Remove animals that have a different reference (reference changed after querying animals).
          dispatchToAbattoirBatchClone.dispatchedAnimals = dispatchToAbattoirBatchClone.dispatchedAnimals.filter(
            (da) => da.dispatchReference === dispatchToAbattoirBatchClone.reference
          );

          if ((dispatchToAbattoirBatchClone.totalColdCarcassMass as any) === "") dispatchToAbattoirBatchClone.totalColdCarcassMass = undefined;

          if (dispatchToAbattoirBatchClone && dispatchToAbattoirBatchClone.transport && dispatchToAbattoirBatchClone.transport.length) {
            let transport = dispatchToAbattoirBatchClone.transport[0];
            if ((transport.quantityExpected as any) === "") transport.quantityExpected = undefined;
            if ((transport.quantityAlive as any) === "") transport.quantityAlive = undefined;
            if ((transport.quantityDead as any) === "") transport.quantityDead = undefined;
          }

          if (
            dispatchToAbattoirBatchClone &&
            dispatchToAbattoirBatchClone.transport &&
            dispatchToAbattoirBatchClone.transport.length &&
            dispatchToAbattoirBatchClone.transport[0].transporter &&
            this.driver
          ) {
            dispatchToAbattoirBatchClone.transport[0].transporter.drivers = [this.driver];
          }

          dispatchToAbattoirBatchClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();

          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation dispatchToAbattoirBatch($guid: String!, $dispatchToAbattoirBatch: DispatchToAbattoirBatchInput!) {
                dispatchToAbattoirBatch(guid: $guid, dispatchToAbattoirBatch: $dispatchToAbattoirBatch) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, dispatchToAbattoirBatch: dispatchToAbattoirBatchClone },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let dispatchToAbattoirBatch = this.$store.getters["storage"]().dispatchToAbattoirBatch as Models.DispatchToAbattoirBatch;
          dispatchToAbattoirBatch.changeTracking = json.data.dispatchToAbattoirBatch;
          //await this.$store.dispatch("data/set", { objectStore: "DispatchToAbattoirBatch", items: [lodash.cloneDeep(dispatchToAbattoirBatch)] });
          //this.$router.push({ path: "list/dispatch-to-abattoir-batches" });
          this.changed = false;
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch", value: {} });
        this.$router.push({ path: "list/dispatch-to-abattoir-batches" });
      },
    });
  }

  ////////
  // Custom Feeders

  customFeederListItems: { guid: string; text: string }[] = [];
  selectedCustomFeederListItem: { guid: string; text: string } | "" = "";
  getCustomFeederListItemsButtonState: ButtonState = "ready";
  getSelectedCustomFeederButtonState: ButtonState = "ready";

  get modelSelectedCustomFeederListItem(): { guid: string; text: string } | "" {
    return this.selectedCustomFeederListItem;
  }

  set modelSelectedCustomFeederListItem(selectedCustomFeederListItem: { guid: string; text: string } | "") {
    if (selectedCustomFeederListItem === undefined) selectedCustomFeederListItem = "";

    if (
      this.selectedCustomFeederListItem &&
      selectedCustomFeederListItem &&
      selectedCustomFeederListItem.guid &&
      this.selectedCustomFeederListItem.guid === selectedCustomFeederListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedCustomFeederListItem = selectedCustomFeederListItem;
    this.getCustomFeederFromServer();
  }

  async getCustomFeederFromServer() {
    if (!this.selectedCustomFeederListItem || !this.selectedCustomFeederListItem.guid) return;
    try {
      this.getSelectedCustomFeederButtonState = "busy";
      const customFeeder = await getItemFromServer("CustomFeeder", this.selectedCustomFeederListItem.guid);
      this.customFeeder = customFeeder;
      this.getSelectedCustomFeederButtonState = "success";
    } catch (err) {
      this.getSelectedCustomFeederButtonState = "error";
      console.error(err);
      this.customFeeder = "";
    }
  }

  async getCustomFeederListItems() {
    try {
      this.getCustomFeederListItemsButtonState = "busy";
      this.customFeederListItems = [];
      let items = await listCustomFeeder(true);
      this.customFeederListItems = items.map((i: any) => ({ guid: i.guid, text: `${i.name}${i.companyName ? ` - ${i.companyName}` : ``}` }));
      this.customFeederListItems = this.customFeederListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getCustomFeederListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getCustomFeederListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.customFeeder && !this.customFeederListItems.find((c) => c.guid === (this.customFeeder as Models.CustomFeeder).guid)) {
      const cf = this.customFeeder as Models.CustomFeeder;
      this.customFeederListItems.push({ guid: cf.guid, text: cf.name });
    }
  }

  get customFeeder(): Models.CustomFeeder | "" {
    let ret: Models.CustomFeeder | "" =
      this.dispatchToAbattoirBatch &&
      this.dispatchToAbattoirBatch.transport &&
      this.dispatchToAbattoirBatch.transport.length &&
      this.dispatchToAbattoirBatch.transport[0].customFeeder
        ? this.dispatchToAbattoirBatch.transport[0].customFeeder
        : "";
    return ret;
  }

  set customFeeder(customFeeder: Models.CustomFeeder | "") {
    this.$store.commit("updateUserLocationField", {
      path: "dispatchToAbattoirBatch.transport[0].customFeeder",
      value: customFeeder === "" ? undefined : customFeeder,
    });
  }

  // Custom Feeders
  ////////
  // Destination Abattoirs

  destinationAbattoirs: { guid: string; name: string }[] = [];
  selectedDestinationAbattoir: any = null;

  buttonStateGetDestinationAbattoirs: ButtonState = "ready";
  isFetchingDestinationAbattoirs: boolean = false;

  async getDestinationAbattoirs() {
    this.isFetchingDestinationAbattoirs = true;
    this.buttonStateGetDestinationAbattoirs = "busy";
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `query destinationAbattoirs($guid: String!) {
        destinationAbattoirs(guid: $guid) {
          guid
          name
        }
      }`,
          variables: {
            guid: this.$store.state.user.location.guid,
          },
        },
        { root: true }
      );
      console.log("graphQL:");
      console.dir(json);
      this.destinationAbattoirs = json.data.destinationAbattoirs;
      this.destinationAbattoirs = this.destinationAbattoirs.sort((p1, p2) => (p1.name > p2.name ? 1 : -1));
      // return json.data.listAilment;
      this.buttonStateGetDestinationAbattoirs = "success";
    } catch (err) {
      this.buttonStateGetDestinationAbattoirs = "error";
      console.error(err);
    }
    this.isFetchingDestinationAbattoirs = false;
  }

  // Destination Abattoirs
  ////////
  // Transporters, Drivers

  transporterListItems: { guid: string; text: string }[] = [];
  selectedTransporterListItem: { guid: string; text: string } | "" = "";
  getTransporterListItemsButtonState: ButtonState = "ready";
  getSelectedTransporterButtonState: ButtonState = "ready";

  get modelSelectedTransporterListItem(): { guid: string; text: string } | "" {
    return this.selectedTransporterListItem;
  }

  set modelSelectedTransporterListItem(selectedTransporterListItem: { guid: string; text: string } | "") {
    if (selectedTransporterListItem === undefined) selectedTransporterListItem = "";

    if (
      this.selectedTransporterListItem &&
      selectedTransporterListItem &&
      selectedTransporterListItem.guid &&
      this.selectedTransporterListItem.guid === selectedTransporterListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedTransporterListItem = selectedTransporterListItem;
    this.getTransporterFromServer();
  }

  async getTransporterFromServer() {
    if (!this.selectedTransporterListItem || !this.selectedTransporterListItem.guid) return;
    try {
      this.getSelectedTransporterButtonState = "busy";
      const transporter = await getItemFromServer("Transporter", this.selectedTransporterListItem.guid);
      this.transporter = transporter;
      this.getSelectedTransporterButtonState = "success";
    } catch (err) {
      this.getSelectedTransporterButtonState = "error";
      console.error(err);
      this.transporter = "";
    }
  }

  async getTransporterListItems() {
    try {
      this.getTransporterListItemsButtonState = "busy";
      this.transporterListItems = [];
      let items = await listTransporter(true);
      this.transporterListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.transporterListItems = this.transporterListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getTransporterListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getTransporterListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.transporter && !this.transporterListItems.find((c) => c.guid === (this.transporter as Models.Transporter).guid)) {
      const t = this.transporter as Models.Transporter;
      this.transporterListItems.push({ guid: t.guid, text: t.name });
    }
  }

  get transporter(): Models.Transporter | "" {
    return this.dispatchToAbattoirBatch.transport && this.dispatchToAbattoirBatch.transport.length && this.dispatchToAbattoirBatch.transport[0].transporter
      ? this.dispatchToAbattoirBatch.transport[0].transporter
      : "";
  }

  set transporter(transporter: Models.Transporter | "") {
    this.$store.commit("updateUserLocationField", {
      path: "dispatchToAbattoirBatch.transport[0].transporter",
      value: transporter === "" ? undefined : lodash.cloneDeep(transporter),
    });
    if (transporter) {
      this.drivers = lodash.cloneDeep(transporter.drivers);
      if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";
    } else {
      this.drivers = [];
      this.driver = "";
    }
  }

  drivers: Models.Driver[] = [];

  set driver(driver: Models.Driver | "") {
    this.$store.commit("updateUserLocationField", { path: "selectedDriver", value: driver ? driver : undefined });
  }

  get driver(): Models.Driver | "" {
    const driver = this.$store.getters["getUserLocationField"]("selectedDriver");
    return driver ? driver : "";
  }

  // Transporters, Drivers
  ////////

  get dispatchWeighbridgeMass() {
    //console.log("get dispatchWeighbridgeMass");
    if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
    let dispatchWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    if (dispatchWeighbridge) return dispatchWeighbridge.mass;
    else return "";
  }

  set dispatchWeighbridgeMass(dispatchWeighbridgeMass) {
    //console.log("set dispatchWeighbridgeMass");
    let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "dispatch" });
    if (dispatchWeighbridgeMass === "") dispatchWeighbridgeMass = undefined;
    if (i >= 0) {
      weighbridges[i].mass = dispatchWeighbridgeMass;
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(dispatchWeighbridgeMass);
    this.calculateDispatchedAnimalsDispatchWeighbridgeMass();
    this.calculateSlaughterPercentage();
  }

  get dispatchWeighbridgeReference() {
    //console.log("get dispatchWeighbridgeReference");
    if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
    let dispatchWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "dispatch");
    if (dispatchWeighbridge) return dispatchWeighbridge.reference;
    else return "";
  }

  set dispatchWeighbridgeReference(dispatchWeighbridgeReference) {
    //console.log("set dispatchWeighbridgeReference");
    let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "dispatch" });
    if (dispatchWeighbridgeReference === "") dispatchWeighbridgeReference = undefined;
    if (i >= 0) {
      weighbridges[i].reference = dispatchWeighbridgeReference;
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(dispatchWeighbridgeReference);
  }

  get abattoirWeighbridgeMass() {
    //console.log("get abattoirWeighbridgeMass");
    if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
    let abattoirWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "abattoir");
    if (abattoirWeighbridge) return abattoirWeighbridge.mass;
    else return "";
  }

  set abattoirWeighbridgeMass(abattoirWeighbridgeMass) {
    //console.log("set abattoirWeighbridgeMass");
    let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
    let i = lodash.findIndex(this.dispatchToAbattoirBatch.transport[0].weighbridges, { location: "abattoir" });
    if (abattoirWeighbridgeMass === "") abattoirWeighbridgeMass = undefined;
    if (i >= 0) {
      weighbridges[i].mass = abattoirWeighbridgeMass;
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(abattoirWeighbridgeMass);
    this.calculateDispatchedAnimalsAbattoirWeighbridgeMass();
  }

  get abattoirWeighbridgeReference() {
    //console.log("get abattoirWeighbridgeReference");
    if (!this.dispatchToAbattoirBatch.transport || !this.dispatchToAbattoirBatch.transport.length) return "";
    let abattoirWeighbridge = lodash.find(this.dispatchToAbattoirBatch.transport[0].weighbridges, (o) => o.location === "abattoir");
    if (abattoirWeighbridge) return abattoirWeighbridge.reference;
    else return "";
  }

  set abattoirWeighbridgeReference(abattoirWeighbridgeReference) {
    //console.log("set abattoirWeighbridgeReference");
    let weighbridges = this.dispatchToAbattoirBatch.transport[0].weighbridges;
    let i = lodash.findIndex(this.dispatchToAbattoirBatch.transport[0].weighbridges, { location: "abattoir" });
    if (abattoirWeighbridgeReference === "") abattoirWeighbridgeReference = undefined;
    if (i >= 0) {
      weighbridges[i].reference = abattoirWeighbridgeReference;
      this.$store.commit("updateUserLocationField", { path: "dispatchToAbattoirBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(abattoirWeighbridgeReference);
  }
}
