






























































































































import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Home extends Vue {
  created() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });
  }
  async kraals() {
    throw Error("TODO: add idb object store for Kraal");
  }

  async feed() {}

  logout() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to log out?",
      yesAction: () => {
        this.$store.dispatch("user/signOut");
      },
    });
  }
}
