import { default as Vuex, Module, ActionContext } from "vuex";

class ScannerState {
    busyScanning: boolean = false;
}


export default new (class List implements Module<ScannerState, any> {
    namespaced = true;
    state: ScannerState = new ScannerState();
    mutations = {
        setBusyScanning(state: ScannerState, busy: any) {
            state.busyScanning = busy;
        }
        /*
        mutation(state: State, payload: any) {
          //no async calls
          state.data = payload;
        }*/

    };
    actions = {
        /*
        action(context: ActionContext<State, any>) {
          //async calls allowed, action can also be async
          //context.state, context.rootState, context.dispatch, context.commit
        }
        */
        async scanSgtin(context: ActionContext<ScannerState, any>) {
            context.commit("setBusyScanning", true);
            try {
                let response = await fetch(`https://orca.gigalot.systems:9000/HardwareServiceLayer/api/Rfid/sgtin`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },

                });
                if (response.ok) {
                    let json = await response.text();
                    console.log(json);
                    return json;
                }
            }
            catch (error) {
                throw error;
            }
            finally {
                context.commit("setBusyScanning", false);
            }

        },
    };
    getters = {
        /*
        getter(state: State, getters: any, rootState: any, rootGetters: any) {
          //return a function if you want the getter to receive input parameters
        }
        */
        // items(state: ListState, getters: any, rootState: any, rootGetters: any) {
        //   return () => {
        //     return items;
        //   };
        //return a function if you want the getter to receive input parameters
    };
})();
