

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["producer.name", "producer.surname", "producer.active"]),
  },
})
export default class Producer extends Vue {
  companies: Models.ProducerCompany[] = [];
  //intakeBatches: Models.IntakeBatch[] = [];

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "producer", value: {} });
        this.$router.push({ path: "list/producers" });
      },
    });
    let producer: Models.Producer = this.$store.getters["storage"]().producer;
    this.companies = producer.companies;
    //this.intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
  }

  @Watch("companies")
  onCompaniesChanged(companies: any) {
    console.log("companies: " + JSON.stringify(companies));
    this.$store.commit("updateUserLocationField", {
      path: "producer.companies",
      value: companies,
    });
  }

  addCompany() {
    this.changed = true;
    this.companies.push(new Models.ProducerCompany());
  }

  deleteCompany(index: number) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: async () => {
        this.changed = true;
        let producer = this.$store.getters["storage"]().producer;
        //const company = producer.companies[index];

        //check first that driver is not currently being used
        //if driver is being used then prevent deletion
        // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
        // for (const intakeBatch of intakeBatches) {
        //   for (const transport of intakeBatch.transport) {
        //     if (transport.producer && transport.producer.companies?.length && transport.producer.companies.find((c) => c.guid === company.guid)) {
        //       this.$store.commit("popup/displayOk", `Cannot delete producer's company, it is being used in intake batch reference ${intakeBatch.reference}`);
        //       return;
        //     }
        //   }
        // }

        //TODO: look in dispatch to abattoir batches as well

        producer.companies.splice(index, 1);

        this.$store.commit("updateUserLocationField", {
          path: "producer.companies",
          value: producer.companies,
        });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "producer", value: {} });
        this.$router.push({ path: "list/producers" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another producer with the same name (different guid) is found
          //TODO: popup if multiple producer companies share the same name
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().producer);
          let producerClone = lodash.cloneDeep(this.$store.getters["storage"]().producer);
          producerClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation producer($guid: String!, $producer: ProducerInput!) {
                producer(guid: $guid, producer: $producer) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, producer: producerClone as Models.Producer },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let producer = this.$store.getters["storage"]().producer as Models.Producer;
          producer.changeTracking = json.data.producer;
          //await this.$store.dispatch("data/set", { objectStore: "Producer", items: [lodash.cloneDeep(producer)] });

          // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
          // for (let intakeBatch of intakeBatches) {
          //   if (intakeBatch.transport.length && intakeBatch.transport[0].producer && intakeBatch.transport[0].producer.guid === producer.guid) {
          //     let companies = lodash.cloneDeep(intakeBatch.transport[0].producer.companies); //copy companies first
          //     intakeBatch.transport[0].producer = lodash.cloneDeep(producer); //update producer
          //     intakeBatch.transport[0].producer.companies = companies; //set companies to what was previously selected

          //     //check if intakeBatch.transport[0].producer.companies match producer.companies, update any found
          //     for (let i = 0; i < intakeBatch.transport[0].producer.companies.length; ++i) {
          //       //there should only be one company, just being careful
          //       let company = producer.companies.find((c) => c.guid === intakeBatch.transport[0].producer?.companies[i].guid);
          //       if (company) intakeBatch.transport[0].producer.companies[i] = lodash.cloneDeep(company);
          //     }

          //     await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //   }
          // }

          this.$router.push({ path: "list/producers" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  mounted() {}

  destroyed() {}
}
