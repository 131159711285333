import { default as Vuex, Module } from "vuex";
import Header from "@/data-table-header";

let items: any[] | undefined = []; //moving items to RAM

class ListState {
  title: string = "";
  headers?: Header[] = [];
  items?: any[] = [];
  typename!: string;
  //onEdit: (item: any) => void = (item: any) => {}; //WARNING: this doesn't work, vuex persist and functions don't work together
  onEdit: { routeName?: string; listUponEditPath?: string; } = {};
  onContinue?: { routeName?: string; listUponSelectionSetPath: string; };
  onBack?: { routeName?: string; };
  sortBy?: string;
  sortDesc?: boolean;
}

export default new (class List implements Module<ListState, any> {
  namespaced = true;
  state: ListState = new ListState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }*/
    clearItemsToFreeQuota(state: ListState) {
      state.items = undefined;
    },
    list(
      state: ListState,
      payload: {
        title: string;
        headers?: Header[];
        items?: any[];
        typename: string;
        onEdit: {
          routeName: string; //name of route where selected item can be edited.
          listUponEditPath: string; //the path to set in storage[uid][guid] upon edit
        };
        onContinue?: {
          routeName: string; //name of route where selected item can be selected.
          listUponSelectionSetPath: string; //the storage path to set to the selected item
        };
        onBack?: {
          routeName: string;
        };
        sortBy?: string;
        sortDesc?: boolean;
      }
    ) {
      state.title = payload.title;
      state.headers = payload.headers;
      //items in RAM
      items = payload.items;
      state.typename = payload.typename;
      state.onEdit = payload.onEdit;
      state.onContinue = payload.onContinue;
      state.onBack = payload.onBack;
      state.sortBy = payload.sortBy;
      state.sortDesc = payload.sortDesc;
      if (state.headers === undefined) {
        //lookup headers for typename
      }
      if (state.items === undefined) {
        //lookup items for typename
      }

      //lookup data
      //router.push
    },
    items(state: ListState, payload: any[]) {
      //state.items = items;
      //items in RAM
      items = payload;
    },
    clearList(state: ListState) {
      state.items = [];
    },
    onContinue(
      state: ListState,
      onContinue?: {
        routeName: string; //name of route where selected item can be selected.
        listUponSelectionSetPath: string; //the storage path to set to the selected item
      }
    ) {
      state.onContinue = onContinue;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    items(state: ListState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return items;
      };
      //return a function if you want the getter to receive input parameters
    }
  };
})();
