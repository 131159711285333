























import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Costs extends Vue {
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.push({ name: "home" });
      },
    });
  }

  async graphQlJson(gql: string) {
    return await this.$store.dispatch(
      "graphQl",
      {
        gql: gql,
        variables: { guid: this.$store.state.user.location.guid },
      },
      { root: true }
    );
  }
}
