







































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listAilmentCategory } from "@/helpers/graphql-list-items";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["ailment.ailment", /*"ailment.category",*/ "ailment.active"]),
  },
})
export default class Ailment extends Vue {
  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "ailment", value: {} });
        this.$router.push({ path: "list/ailments" });
      },
    });
    if (this.category) {
      const c = this.category;
      this.selectedAilmentCategoryListItem = { guid: c.guid, text: c.category ?? "" };
      this.getSelectedAilmentCategoryButtonState = "success";
    }
    this.getAilmentCategoryListItems();
  }

  ailmentCategoryListItems: { guid: string; text: string }[] = [];
  selectedAilmentCategoryListItem: { guid: string; text: string } | "" = "";
  getAilmentCategoryListItemsButtonState: ButtonState = "ready";
  getSelectedAilmentCategoryButtonState: ButtonState = "ready";

  get modelSelectedAilmentCategoryListItem(): { guid: string; text: string } | "" {
    return this.selectedAilmentCategoryListItem;
  }

  set modelSelectedAilmentCategoryListItem(selectedAilmentCategoryListItem: { guid: string; text: string } | "") {
    if (selectedAilmentCategoryListItem === undefined) selectedAilmentCategoryListItem = "";

    if (
      this.selectedAilmentCategoryListItem &&
      selectedAilmentCategoryListItem &&
      selectedAilmentCategoryListItem.guid &&
      this.selectedAilmentCategoryListItem.guid === selectedAilmentCategoryListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedAilmentCategoryListItem = selectedAilmentCategoryListItem;
    this.getAilmentCategoryFromServer();
  }

  async getAilmentCategoryFromServer() {
    if (!this.selectedAilmentCategoryListItem || !this.selectedAilmentCategoryListItem.guid) return;
    try {
      this.getSelectedAilmentCategoryButtonState = "busy";
      const ailmentCategory = await getItemFromServer("AilmentCategory", this.selectedAilmentCategoryListItem.guid);
      this.category = ailmentCategory;
      this.getSelectedAilmentCategoryButtonState = "success";
    } catch (err) {
      this.getSelectedAilmentCategoryButtonState = "error";
      console.error(err);
      this.category = "";
    }
  }

  async getAilmentCategoryListItems() {
    try {
      this.getAilmentCategoryListItemsButtonState = "busy";
      this.ailmentCategoryListItems = [];
      let items = await listAilmentCategory(true);
      this.ailmentCategoryListItems = items.map((i: any) => ({ guid: i.guid, text: i.category }));
      this.getAilmentCategoryListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getAilmentCategoryListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.category && !this.ailmentCategoryListItems.find((c) => c.guid === (this.category as Models.AilmentCategory).guid)) {
      const c = this.category as Models.AilmentCategory;
      this.ailmentCategoryListItems.push({ guid: c.guid, text: c.category ?? "" });
    }
  }

  get category(): Models.AilmentCategory | "" {
    const category = this.$store.getters["getUserLocationField"]("ailment.category");
    return category ?? "";
  }

  set category(ailmentCategory: Models.AilmentCategory | "") {
    this.$store.commit("updateUserLocationField", {
      path: "ailment.category",
      value: ailmentCategory === "" ? undefined : ailmentCategory,
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "ailment", value: {} });
        this.$router.push({ path: "list/ailments" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another ailment with the same ailment (different guid) is found
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().ailment);
          let ailmentClone = lodash.cloneDeep(this.$store.getters["storage"]().ailment);
          ailmentClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation ailment($guid: String!, $ailment: AilmentInput!) {
                ailment(guid: $guid, ailment: $ailment) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: {
                guid: this.$store.state.user.location.guid,
                ailment: ailmentClone as Models.Ailment,
              },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let ailment = this.$store.getters["storage"]().ailment as Models.Ailment;
          ailment.changeTracking = json.data.ailment;
          //await this.$store.dispatch("data/set", { objectStore: "Ailment", items: [lodash.cloneDeep(ailment)] });
          this.$router.push({ path: "list/ailments" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
