




















































































import { Component, Prop, Vue } from "vue-property-decorator";
import * as Models from "@gigalot/data-models";
import { massFormat, currencyFormat } from "@/helpers";

@Component
export default class IntakeBatchSummary extends Vue {
  @Prop() animals!: Models.Animal[];
  @Prop() purchasePrice!: number;
  @Prop() purchasePriceMale!: number;
  @Prop() purchasePriceFemale!: number;
  @Prop() averagePricePerKg!: any;
  // get moment() {
  //   return this.$store.state.moment;
  // }
  massFormat = massFormat;
  currencyFormat = currencyFormat;

  get animalsMale() {
    return this.animals.filter((a) => a.gender && a.gender.toLowerCase() === "male");
  }

  get animalsFemale() {
    return this.animals.filter((a) => a.gender && a.gender.toLowerCase() === "female");
  }
}
