export const changeTrackingComponentFragment = `
fragment changeTrackingFields on ChangeTrackComponent {
  current {
    guid
    hash
  }
  previous {
    guid
    hash
  }
  serverSaveTime
  save {
    uid
    time
  }
}`;
