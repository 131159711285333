











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import wNumb from "wnumb";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listBuyer, listCreditor, listCustomFeeder, listProducer, listTransporter } from "@/helpers/graphql-list-items";
import { getIncomingBatches } from "@/helpers/incoming-batches";
import IntakeBatchSummary from "@/components/intake-batch/IntakeBatchSummary.vue";
import { massFormat, currencyFormat } from "@/helpers";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { countries, updateTownsProvincesCountries } from "@/helpers/countries-provinces-towns";
import Map from "@/components/Map.vue";

@Component({
  components: {
    DimssaButton,
    IntakeBatchSummary,
    Map,
  },
  computed: {
    ...mapFields({
      reference: "intakeBatch.reference",
      description: "intakeBatch.description",
      notes: "intakeBatch.notes",
      settled: "intakeBatch.settled",
      invoiceNumber: "intakeBatch.transport[0].invoiceNumber",
      purchaseNumber: "intakeBatch.transport[0].purchaseNumber",
      markedForExport: "intakeBatch.transport[0].markedForExport",
      siteName: "intakeBatch.transport[0].origin.description",
    }),
  },
  mixins: [validationMixin],
  validations: {
    reference: { required },
    transportQuantityExpected: { required },
    modelSelectedCustomFeederListItem: { required },
  },
})
export default class IntakeBatch extends Vue {
  purchaseDateMenu: boolean = false;
  arrivalDateMenu: boolean = false;

  purchaseDateString: string = "";
  arrivalDateString: string = "";
  getShortCodeState: ButtonState = "ready";
  shortCodeDialogVisible = false;
  shortCode = "";
  refreshButtonState: ButtonState = "ready";

  manualCostTotal: string = "";
  manualCostTotalVatIncl: string = "";
  manualVatTotal: string = "";

  vat: number = 0.15;

  moment = this.$store.state.moment;

  tab: any = null;

  changed: boolean = false;

  updateTownsProvincesCountriesButtonState: ButtonState = "ready";
  async updateTownsProvincesCountries() {
    try {
      this.updateTownsProvincesCountriesButtonState = "busy";
      await updateTownsProvincesCountries();
      this.initializeOrigins();
      this.updateTownsProvincesCountriesButtonState = "success";
    } catch (err) {
      console.error(err);
      this.updateTownsProvincesCountriesButtonState = "error";
    }
  }

  selectableCountries: { country: string }[] = [];
  //selectableProvinces: string[] = countries.map((c) => c.provinces.map((p) => p.name)).reduce((prev, curr) => [...prev, ...curr], []);
  selectableProvinces: { province: string; country: string }[] = [];
  selectableTowns: { town: string; province: string; country: string }[] = [];

  @Watch("intakeBatch.reference")
  onReferenceChanged(val: any) {
    console.log("ref change");
  }

  set markerLatitude(lat: number) {
    if (lat) this.markerLat = lat;
  }
  get markerLatitude() {
    return parseFloat(this.markerLat.toFixed(3));
  }
  set markerLongitude(lng: number) {
    if (lng) this.markerLng = lng;
  }
  get markerLongitude() {
    return parseFloat(this.markerLng.toFixed(3));
  }

  markerLat = -32;
  markerLng = 32;
  zoom = 5;
  initialMapPosition = { lat: -29, lng: 25 };

  get position() {
    if (this.intakeBatch) {
      if (this.intakeBatch.transport[0]?.origin?.gps) {
        const defaultCoordinate = { lat: -29, lng: 25 };
        let coordinate = {
          lat: this.intakeBatch.transport[0]?.origin?.gps.lat ?? defaultCoordinate.lat,
          lng: this.intakeBatch.transport[0]?.origin?.gps.lon ?? defaultCoordinate.lng,
        };
        // this.markerLat = coordinate.lat ?? defaultCoordinate.lat;
        // this.markerLng = coordinate.lng ?? defaultCoordinate.lng;
        if (coordinate.lat) {
          this.initialMapPosition.lat = coordinate.lat;
        }
        if (coordinate.lng) {
          this.initialMapPosition.lng = coordinate.lng;
        }

        return coordinate; //{ lat: this.intakeBatch.transport[0]?.origin?.gps.lat, lng: this.intakeBatch.transport[0]?.origin?.gps.lon }
      }
    }
    return { lat: -32, lng: 32 };
  }

  get initPosition() {
    return this.initialMapPosition;
  }

  get zoomLevel() {
    return this.zoom;
  }

  updateLocationLatEntered(lat: number) {
    this.changed = true;
    console.log(lat);
    this.markerLat = lat;
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.gps`, value: { lat: this.markerLat, lon: this.markerLng } }); //lon not a typo
  }

  updateLocationLonEntered(lon: number) {
    this.changed = true;
    console.log(lon);
    //console.log("typeof lon:", typeof lon);
    this.markerLng = lon;
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.gps`, value: { lat: this.markerLat, lon: this.markerLng } }); //lon not a typo
  }

  async getShortCodeResponse(shortCode: string) {
    if (!shortCode.startsWith("https://goo.gl/maps/")) {
      throw new Error("Not a valid shortcode");
    }

    try {
      this.getShortCodeState = "busy";
      //  shortCode = "https://goo.gl/maps/jcKkYpouXSYioq5s7"; // Test shortcode -> Pretoria

      const user = this.$store.state.user.user;
      let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, { root: true });
      const gql = `query getShortCodeResponse($shortCode: String!) {
        getShortCodeResponse(shortCode: $shortCode)
      }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables: { shortCode: shortCode }, jwt, destination: "cloud" }, { root: true });
      console.log(JSON.stringify(json));
      let lat = JSON.parse(json?.data?.getShortCodeResponse).lat;
      let lng = JSON.parse(json?.data?.getShortCodeResponse).lng;
      this.locationMarkerChanged(lat, lng);
      let thispos = this.position;
      let fake = this.position.lat;
      this.getShortCodeState = "success";
    } catch (err) {
      this.getShortCodeState = "error";
    }

    this.shortCodeDialogVisible = false;
  }

  locationMarkerChanged(lat: number, lng: number) {
    this.changed = true;
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.gps`, value: { lat: lat, lon: lng } }); //lon not a typo
    this.initialMapPosition.lat = lat;
    this.initialMapPosition.lng = lng;
    this.markerLat = lat; //this.intakeBatch.transport[0]?.origin?.gps.lat;
    this.markerLng = lng; //this.intakeBatch.transport[0]?.origin?.gps.lon
    this.zoom = 15;
    console.log(lat);
    console.log(lng);
  }

  @Watch("changed")
  onChangeChanged(val: any) {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("updateUserLocationField", { path: "intakeBatch", value: {} });
          this.$router.push({ path: "list/intake-batches" });
        },
      });
  }

  async beforeCreate() {}

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "intakeBatch", value: {} });
        this.$router.push({ path: "list/intake-batches" });
      },
    });

    this.initializeOrigins();

    if (!this.intakeBatch.transport) this.intakeBatch.transport = [];

    // if (!this.intakeBatch.transport.length) {
    //   this.intakeBatch.transport[0] = new Models.Transport();
    //   this.intakeBatch.transport[0].weighbridges.push({ location: "supplier", reference: "", mass: undefined });
    //   this.intakeBatch.transport[0].weighbridges.push({ location: "arrival", reference: "", mass: undefined });

    //   //this.intakeBatch.transport[0].score.push({ type: "group", score: "" });
    //   this.intakeBatch.transport[0].score.push({ type: "condition", score: "" });
    //   this.intakeBatch.transport[0].score.push({ type: "genetics", score: "" });
    //   this.intakeBatch.transport[0].score.push({ type: "general-overview", score: "" });
    // }

    //TODO move to List.vue's factory()
    if (this.intakeBatch.transport[0].score.length === 1) {
      this.intakeBatch.transport[0].score[0].type = "condition";
      // this.intakeBatch.transport[0].score.push({ type: "condition", score: "" });
      this.intakeBatch.transport[0].score.push({ type: "genetics", score: "" });
      this.intakeBatch.transport[0].score.push({ type: "general-overview", score: "" });
    }
    //change "group" to "condition"

    // if (!this.intakeBatch.transport[0].origin) {
    //   this.intakeBatch.transport[0].origin = new Models.Origin();
    //   this.intakeBatch.transport[0].origin.country = "";
    //   this.intakeBatch.transport[0].origin.province = "";
    //   this.intakeBatch.transport[0].origin.town = "";
    //   this.intakeBatch.transport[0].origin.code = "";
    //   this.intakeBatch.transport[0].origin.gps = { lat: 0, lon: 0 };
    //   //    this.intakeBatch.transport[0].origin.description = "";
    // }
    if (!this.intakeBatch.transport[0].invoiceNumber) {
      this.intakeBatch.transport[0].invoiceNumber = "";
    }
    if (!this.intakeBatch.transport[0].purchaseNumber) {
      this.intakeBatch.transport[0].purchaseNumber = "";
    }
    if (!this.intakeBatch.transport[0].markedForExport) {
      this.intakeBatch.transport[0].markedForExport = false;
    }

    //this.transporters = ((await this.$store.dispatch("data/get", { objectStore: "Transporter" })) as Models.Transporter[]).filter((b) => b.active);
    // this.buyers = ((await this.$store.dispatch("data/get", { objectStore: "Buyer" })) as Models.Buyer[]).filter((b) => b.active);
    // this.producers = ((await this.$store.dispatch("data/get", { objectStore: "Producer" })) as Models.Producer[]).filter((b) => b.active);
    // this.creditors = ((await this.$store.dispatch("data/get", { objectStore: "Creditor" })) as Models.Creditor[]).filter((b) => b.active);
    //this.customFeeders = ((await this.$store.dispatch("data/get", { objectStore: "CustomFeeder" })) as Models.CustomFeeder[]).filter((b) => b.active);

    //this.manualCostTotal = this.intakeBatch.transport[0].manualCostTotal === undefined ? "" : this.intakeBatch.transport[0].manualCostTotal;
    this.manualCostTotalChange(this.intakeBatch.transport[0].manualCostTotal);

    this.transportCostChange(this.intakeBatch.transport[0].cost);
    this.transportDistanceChange(this.intakeBatch.transport[0].distance);
    this.transportCommissionChange(this.intakeBatch.transport[0].commission);
    this.transportQuantityExpectedChange(this.intakeBatch.transport[0].quantityExpected);
    this.transportQuantityDeadChange(this.intakeBatch.transport[0].quantityDead);
    //if (!this.intakeBatch.transport[0].origin) this.intakeBatch.transport[0].origin = {};

    if (!this.intakeBatch.transport[0].origin) throw Error("IntakeBatch.created(): No origin for IntakeBatch");
    const country = this.intakeBatch.transport[0].origin.country;
    const province = this.intakeBatch.transport[0].origin.province;
    const town = this.intakeBatch.transport[0].origin.town;
    if (country) {
      this.transportOriginCountryChange({ country: country });
      if (province) {
        this.transportOriginProvinceChange({ country: country, province: province });
        if (town) {
          this.transportOriginTownChange({ country: country, province: province, town: town });
        } else if (!town) {
          this.transportOriginTownChange("");
        }
      } else if (!province) {
        this.transportOriginProvinceChange("");
        this.transportOriginTownChange("");
      }
    } else if (!country) {
      this.transportOriginCountryChange("");
      this.transportOriginProvinceChange("");
      this.transportOriginTownChange("");
    }

    // if (!this.intakeBatch.transport[0].producer) {
    //   this.$store.commit("updateUserLocationField", {
    //     path: "intakeBatch.transport[0].producer",
    //     value: new Models.Producer()
    //   });
    // }

    // if (!this.intakeBatch.transport[0].creditor) {
    //   this.$store.commit("updateUserLocationField", {
    //     path: "intakeBatch.transport[0].creditor",
    //     value: new Models.Creditor()
    //   });
    // }

    // if (!this.intakeBatch.transport[0].customFeeder) {
    //   this.$store.commit("updateUserLocationField", {
    //     path: "intakeBatch.transport[0].customFeeder",
    //     value: new Models.CustomFeeder()
    //   });
    // }

    if (this.intakeBatch.transport[0].description && !this.intakeBatch.transport[0].origin.description) {
      // https://wares.gigalot.systems/gigalot/office-app/-/issues/147 (IntakeBatch view's Site Name is mapping to the incorrect variable.)
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].origin.description", value: this.intakeBatch.transport[0].description });
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].description", value: undefined });
    }

    if (this.intakeBatch.reference === undefined) {
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.reference", value: "" });
    }

    if (this.intakeBatch && this.intakeBatch.transport[0].arrivalDate) {
      this.arrivalDateString = this.moment(this.intakeBatch.transport[0].arrivalDate).format("YYYY-MM-DD");
    }

    if (this.intakeBatch && this.intakeBatch.purchaseDate) {
      this.purchaseDateString = this.moment(this.intakeBatch.purchaseDate).format("YYYY-MM-DD");
    }

    if (this.transporter) {
      //try to get drivers from server. If not successful then just use selected [driver] in this.transporter.drivers
      try {
        let t = await getItemFromServer("Transporter", this.transporter.guid);
        this.drivers = t.drivers;
      } catch (err) {
        this.drivers = this.transporter.drivers;
      }
    } else {
      this.drivers = [];
      this.driver = "";
    }

    if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";
    else if (this.intakeBatch.transport[0].transporter && this.intakeBatch.transport[0].transporter.drivers.length === 1) {
      this.driver = this.intakeBatch.transport[0].transporter.drivers[0];
    }

    if (this.producer) {
      try {
        let p = await getItemFromServer("Producer", this.producer.guid);
        this.producerCompanies = p.companies;
      } catch (err) {
        this.producerCompanies = this.producer.companies;
      }
    } else {
      this.producerCompanies = [];
      this.producerCompany = "";
    }

    if (this.producerCompany && !lodash.find(this.producerCompanies, { guid: this.producerCompany.guid })) this.producerCompany = "";

    if (this.intakeBatch.transport[0].producer && this.intakeBatch.transport[0].producer.companies.length === 1) {
      this.producerCompany = this.intakeBatch.transport[0].producer.companies[0];
    }

    //If already selected for this intake batch then populate guid and text into relevant ListItems
    if (this.buyer) {
      const b = this.buyer;
      this.selectedBuyerListItem = { guid: b.guid, text: b.name };
      this.getSelectedBuyerButtonState = "success";
    }
    this.getBuyerListItems();

    if (this.creditor) {
      const c = this.creditor;
      this.selectedCreditorListItem = { guid: c.guid, text: c.name };
      this.getSelectedCreditorButtonState = "success";
    }
    this.getCreditorListItems();

    if (this.customFeeder) {
      const cf = this.customFeeder;
      this.selectedCustomFeederListItem = { guid: cf.guid, text: `${cf.name}${cf.companies[0]?.name ? ` - ${cf.companies[0]?.name}` : ``}` };
      this.getSelectedCustomFeederButtonState = "success";
    }
    this.getCustomFeederListItems();

    if (this.producer) {
      const p = this.producer;
      this.selectedProducerListItem = { guid: p.guid, text: p.name };
      this.getSelectedProducerButtonState = "success";
    }
    this.getProducerListItems();

    if (this.transporter) {
      const t = this.transporter;
      this.selectedTransporterListItem = { guid: t.guid, text: t.name };
      this.getSelectedTransporterButtonState = "success";
    }
    this.getTransporterListItems();
  }

  currencyFormat = currencyFormat;

  massFormat = massFormat;

  currencyPerMassFormat(amt: any) {
    return wNumb({
      mark: ".",
      thousand: " ",
      prefix: "R ",
      suffix: "/kg",
      decimals: 2,
    }).to(parseFloat(amt));
  }

  // @Watch("$refs")
  // refsCahnged(newVal: any, oldVal: any) {
  //   console.log(`$refs change`);
  //   console.log(`newVal: ${newVal}`);
  //   console.log(`oldVal: ${oldVal}`);
  // }

  manualCostTotalChange(manualCostTotal: any) {
    this.manualCostTotal = manualCostTotal;
    if (manualCostTotal === "" || manualCostTotal === null || isNaN(manualCostTotal)) {
      //this.manualCostTotal = "";
      this.manualCostTotalVatIncl = "";
      this.manualVatTotal = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].manualCostTotal`, value: undefined });
    } else {
      let tempManualCostTotalExclVat = parseFloat(manualCostTotal);
      this.manualCostTotalVatIncl = (tempManualCostTotalExclVat * (1.0 + this.vat)).toFixed(2);
      this.manualVatTotal = (tempManualCostTotalExclVat * this.vat).toFixed(2);
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].manualCostTotal`, value: tempManualCostTotalExclVat });
    }
  }

  manualCostTotalVatInclChange(manualCostTotalVatIncl: any) {
    this.manualCostTotalVatIncl = manualCostTotalVatIncl;
    if (isNaN(manualCostTotalVatIncl)) {
      this.manualCostTotal = "";
      this.manualVatTotal = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].manualCostTotal`, value: undefined });
    } else {
      let tempManualCostTotalInclVat = parseFloat(manualCostTotalVatIncl);
      this.manualCostTotal = (tempManualCostTotalInclVat / (1.0 + this.vat)).toFixed(2);
      this.manualVatTotal = ((tempManualCostTotalInclVat / (1.0 + this.vat)) * this.vat).toFixed(2);
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].manualCostTotal`, value: parseFloat(this.manualCostTotal) });
    }
  }

  // set manualCostTotal(manualCostTotal: any) {
  //   if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) throw Error("set manualCostTotal called but no intake.transport[0]")
  // }

  // get manualCostTotal() {
  //   if (!this.intakeBatch.transport || !this.intakeBatch.transport.length || this.intakeBatch.transport[0].manualCostTotal === undefined) return "";
  //   else return this.intakeBatch.transport[0].manualCostTotal;
  // }

  get animalsMale() {
    return this.animals.filter((a) => a.gender.toLowerCase() === "male");
  }

  get animalsFemale() {
    return this.animals.filter((a) => a.gender.toLowerCase() === "female");
  }

  animals: any[] = [];

  calculateQuantityAlive() {
    let quantityExpected = this.intakeBatch.transport[0].quantityExpected;
    let quantityDead = this.intakeBatch.transport[0].quantityDead;
    let quantityAlive: number;
    if (quantityExpected === undefined || isNaN(quantityExpected)) {
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityAlive`, value: "" });
      this.transportQuantityAlive = "";
      return;
    } else quantityAlive = quantityExpected;

    if (quantityDead !== undefined && !isNaN(quantityDead)) quantityAlive -= quantityDead;

    //let quantityAlive: number = isNaN this.intakeBatch.transport[0].quantityExpected;
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityAlive`, value: quantityAlive });
    this.transportQuantityAlive = quantityAlive.toString();
  }

  transportCost: string = "";
  transportCostChange(transportCost: any) {
    this.transportCost = transportCost;
    if (isNaN(transportCost)) this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].cost`, value: undefined });
    else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].cost`, value: parseFloat(transportCost) });
  }
  transportDistance: string = "";
  transportDistanceChange(transportDistance: any) {
    this.transportDistance = transportDistance;
    if (isNaN(transportDistance)) this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].distance`, value: undefined });
    else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].distance`, value: parseFloat(transportDistance) });
  }
  transportCommission: string = "";
  transportCommissionChange(transportCommission: any) {
    this.transportCommission = transportCommission;
    if (isNaN(transportCommission)) this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].commission`, value: undefined });
    else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].commission`, value: parseFloat(transportCommission) });
  }
  transportQuantityExpected: string = "";
  transportQuantityExpectedChange(transportQuantityExpected: any) {
    this.transportQuantityExpected = transportQuantityExpected;
    if (isNaN(transportQuantityExpected))
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityExpected`, value: undefined });
    else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityExpected`, value: parseInt(transportQuantityExpected) });
    this.calculateQuantityAlive();
  }
  transportQuantityAlive: string = "";
  // transportQuantityAliveChange(transportQuantityAlive: any) {
  //   this.transportQuantityAlive = transportQuantityAlive;
  //   if (isNaN(transportQuantityAlive)) this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityAlive`, value: undefined });
  //   else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityAlive`, value: parseInt(transportQuantityAlive) });
  // }
  transportQuantityDead: string = "";
  transportQuantityDeadChange(transportQuantityDead: any) {
    this.transportQuantityDead = transportQuantityDead;
    if (transportQuantityDead === "" || transportQuantityDead === undefined || isNaN(transportQuantityDead)) {
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityDead`, value: undefined });
      this.transportQuantityDead = "";
    } else this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].quantityDead`, value: parseInt(transportQuantityDead) });
    this.calculateQuantityAlive();
  }

  initializeOrigins() {
    //Countries
    this.selectableCountries = countries.map((c) => ({ country: c.name }));

    //Provinces
    this.selectableProvinces = countries
      .map((c) => c.provinces.map((p) => ({ province: p.name, country: c.name })))
      .reduce((prev, curr) => [...prev, ...curr], []);

    //Towns
    this.selectableTowns = countries
      .map((c) => c.provinces.map((p) => p.towns.map((t) => ({ town: t.name, province: p.name, country: c.name }))))
      .reduce((prev, curr) => [...prev, ...curr], [])
      .reduce((prev, curr) => [...prev, ...curr], []);
  }

  transportOriginCountry: { country: string } | "" = "";
  transportOriginCountryChange(transportOriginCountry: { country: string } | "") {
    if (!transportOriginCountry) transportOriginCountry = "";

    if (!transportOriginCountry) {
      this.selectableProvinces = countries
        .map((c) => c.provinces.map((p) => ({ province: p.name, country: c.name })))
        .reduce((prev, curr) => [...prev, ...curr], []);
      this.selectableTowns = countries
        .map((c) => c.provinces.map((p) => p.towns.map((t) => ({ town: t.name, province: p.name, country: c.name }))))
        .reduce((prev, curr) => [...prev, ...curr], [])
        .reduce((prev, curr) => [...prev, ...curr], []);

      //this.transportOriginProvinceChange("");
      this.transportOriginProvince = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.province`, value: "" });
      this.transportOriginTown = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.town`, value: "" });
    } else if (this.transportOriginCountry !== transportOriginCountry) {
      const temp: { country: string } = transportOriginCountry;
      const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
      this.selectableProvinces = provinces.map((p) => ({ province: p.name, country: temp.country })) ?? [];
      this.selectableTowns = provinces
        .map((p) => p.towns.map((t) => ({ country: temp.country, province: p.name, town: t.name })))
        .reduce((prev, curr) => [...prev, ...curr], []);
      this.transportOriginProvince = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.province`, value: "" });
      this.transportOriginTown = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.town`, value: "" });
    }
    this.transportOriginCountry = transportOriginCountry;
    this.$store.commit("updateUserLocationField", {
      path: `intakeBatch.transport[0].origin.country`,
      value: transportOriginCountry ? transportOriginCountry.country : "",
    });
  }

  transportOriginProvince: { country: string; province: string } | "" = "";
  transportOriginProvinceChange(transportOriginProvince: { country: string; province: string } | "") {
    if (!transportOriginProvince) {
      this.transportOriginProvince = "";

      //this.transportOriginTownChange("");
      this.transportOriginTown = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.town`, value: "" });

      if (this.transportOriginCountry) {
        const temp = this.transportOriginCountry;
        const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
        this.selectableTowns = provinces
          .map((p) => p.towns.map((t) => ({ country: temp.country, province: p.name, town: t.name })))
          .reduce((prev, curr) => [...prev, ...curr], []);
      } else if (!this.transportOriginCountry) {
        this.selectableTowns = countries
          .map((c) => c.provinces.map((p) => p.towns.map((t) => ({ town: t.name, province: p.name, country: c.name }))))
          .reduce((prev, curr) => [...prev, ...curr], [])
          .reduce((prev, curr) => [...prev, ...curr], []);
      }
    } else if (transportOriginProvince !== this.transportOriginProvince) {
      const temp = transportOriginProvince;
      if (!this.transportOriginCountry) {
        //this.transportOriginCountryChange({ country: temp.country });
        this.transportOriginCountry = { country: temp.country };
        this.$store.commit("updateUserLocationField", {
          path: `intakeBatch.transport[0].origin.country`,
          value: temp.country,
        });

        const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
        this.selectableProvinces = provinces.map((p) => ({ province: p.name, country: temp.country })) ?? [];
        this.selectableTowns = provinces
          .map((p) => p.towns.map((t) => ({ country: temp.country, province: p.name, town: t.name })))
          .reduce((prev, curr) => [...prev, ...curr], []);
      }

      const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
      const province = provinces.find((p) => p.name === temp.province);
      this.selectableTowns = province?.towns?.map((t) => ({ country: temp.country, province: temp.province, town: t.name })) ?? [];

      //this.transportOriginTownChange("");
      this.transportOriginTown = "";
      this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.town`, value: "" });
    }
    this.transportOriginProvince = transportOriginProvince;
    this.$store.commit("updateUserLocationField", {
      path: `intakeBatch.transport[0].origin.province`,
      value: transportOriginProvince ? transportOriginProvince.province : "",
    });
  }

  transportOriginTown: { country: string; province: string; town: string } | "" = "";
  transportOriginTownChange(transportOriginTown: { country: string; province: string; town: string } | "") {
    this.transportOriginTown = transportOriginTown;
    this.$store.commit("updateUserLocationField", {
      path: `intakeBatch.transport[0].origin.town`,
      value: transportOriginTown ? transportOriginTown.town : "",
    });
    if (transportOriginTown) {
      const temp = transportOriginTown;
      if (!this.transportOriginProvince) {
        this.transportOriginProvince = { country: temp.country, province: temp.province };
        this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.province`, value: temp.province });

        const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
        const province = provinces.find((p) => p.name === temp.province);
        this.selectableTowns = province?.towns?.map((t) => ({ country: temp.country, province: temp.province, town: t.name })) ?? [];
      }
      if (!this.transportOriginCountry) {
        this.transportOriginCountry = { country: temp.country };
        this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.country`, value: temp.country });
        const provinces = countries.find((c) => c.name === temp.country)?.provinces ?? [];
        this.selectableProvinces = provinces.map((p) => ({ province: p.name, country: temp.country })) ?? [];
      }
    }
  }

  // transportOriginCode: string = "";
  // transportOriginCodeChange(transportOriginCode: any) {
  //   this.transportOriginCode = transportOriginCode;
  //   this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].origin.code`, value: transportOriginCode });
  // }

  animalsHeaders: any[] = [
    {
      text: "#",
      value: "numInQueue",
    },
    {
      text: "Tag ID",
      value: "visualTag",
    },
    {
      text: "Breed",
      value: "breed",
    },
    {
      text: "Gender",
      value: "gender",
    },
    {
      text: "Destination",
      value: "kraalType",
    },
    {
      text: "Kraal",
      value: "kraalId",
    },
    {
      text: "Sick",
      value: "isSick",
    },
    {
      text: "Vaccination",
      value: "vaccination",
    },
    {
      text: "Time",
      value: "time",
    },
    {
      text: "Processed Mass",
      value: "mass",
    },
    {
      text: "Calc. Supplier Mass",
      value: "calcSupplierMass",
    },
    {
      text: "Calc. Price Per kg",
      value: "calcPricePerKg",
    },
    {
      text: "Calc. Price",
      value: "calcPrice",
    },
  ];

  minMass(index: number) {
    let min = this.intakeBatch.transport[0].costing[2 * index].min;
    return min !== undefined ? min : "";
  }

  maxMass(index: number) {
    let max = this.intakeBatch.transport[0].costing[2 * index].max;
    //console.log(`maxMass index: ${index}, max: ${max}`);
    return max !== undefined ? max : "";
  }

  setMinMass(index: number, value: string) {
    console.log(parseFloat(value));
    let costing = this.intakeBatch.transport[0].costing;
    costing[index].min = isNaN(parseFloat(value)) || value === "" ? undefined : parseFloat(value);
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].costing`, value: costing });
  }

  setMaxMass(index: number, value: string) {
    // let costing = this.intakeBatch.transport[0].costing;
    // let max = isNaN(parseFloat(value)) || value === "" ? undefined : parseFloat(value);
    // costing[2 * index].max = max;
    // costing[2 * index + 1].max = max;
    // this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].costing`, value: costing });
    // this.$forceUpdate();

    let costing = this.intakeBatch.transport[0].costing;
    let isLastCosting = 2 * index + 1 == costing.length - 1;
    let max = isNaN(parseFloat(value)) || value === "" ? undefined : parseFloat(value);
    if (!isLastCosting && max !== undefined) {
      let nextMin = costing[2 * (index + 1)].min;
      let nextMax = costing[2 * (index + 1)].max;

      //if
      //max = 100;

      costing[2 * (index + 1)].min = max;
      costing[2 * (index + 1) + 1].min = max;
    }
    if (!isLastCosting && max === undefined) {
      // let nextMin = costing[2 * (index + 1)].min;
      // console.log("nextMin: " + (nextMin as number));
      // max = (nextMin as number) - 1;
      // console.log("max: " + max);
    }
    costing[2 * index].max = max;
    costing[2 * index + 1].max = max;
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].costing`, value: costing });
    // (this.$refs[`max-mass-input-${index}`] as any)[0].updateValue();
    this.calculatePurchasePrice();
    this.$forceUpdate();
  }

  setGender(index: number, value: string) {
    console.log("setGender", index, value);
    let costing = this.intakeBatch.transport[0].costing;
    let maleIndex = lodash.findIndex(costing[index].type, (t: any) => t === "male");
    let femaleIndex = lodash.findIndex(costing[index].type, (t: any) => t === "female");

    if (value === "male") {
      if (femaleIndex >= 0) costing[index].type[femaleIndex] = "male";
      else costing[index].type.push("male");
    } else if (value === "female") {
      if (maleIndex >= 0) costing[index].type[maleIndex] = "female";
      else costing[index].type.push("female");
    }
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].costing`, value: costing });
  }

  getGender(index: number) {
    if (this.intakeBatch.transport[0].costing[index].type.includes("male")) return "male";
    if (this.intakeBatch.transport[0].costing[index].type.includes("female")) return "female";
    return "";
  }

  getPrice(index: number) {
    let cost =
      this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].costing && this.intakeBatch.transport[0].costing.length
        ? this.intakeBatch.transport[0].costing[index].cost
        : undefined;
    return cost !== undefined ? cost : "";
  }

  setPrice(index: number, value: string) {
    console.log(`setPrice(${index},${value})`);
    let costing = this.intakeBatch.transport[0].costing;
    costing[index].cost = isNaN(parseFloat(value)) || value === "" ? undefined : parseFloat(value);
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.transport[0].costing`, value: costing });
    this.calculatePurchasePrice();
  }

  getPriceDifference(index: number) {
    //index i is price difference for costing 2*i and 2*i+1
    let costing = this.intakeBatch.transport[0].costing;
    let maleCost = costing[2 * index];
    let femaleCost = costing[2 * index + 1];
    if ([maleCost.cost, femaleCost.cost].includes(undefined)) return "";
    return ((maleCost.cost as number) - (femaleCost.cost as number)).toFixed(2);
  }

  setPriceDifference(index: number, value: string) {
    let costing = this.intakeBatch.transport[0].costing;
    let maleCost = costing[2 * index];
    let val = parseFloat(value);
    if (isNaN(val)) {
      if (maleCost.cost !== undefined) this.setPrice(2 * index + 1, (maleCost.cost as number).toString());
      return;
    }
    if (maleCost.cost === undefined) return;
    this.setPrice(2 * index + 1, ((maleCost.cost as number) - val).toString());
    // let femaleCost = costing[2 * index + 1];
    // if ([maleCost.cost, femaleCost.cost].includes(undefined)) return NaN;
    // return (maleCost.cost as number) - (femaleCost.cost as number);
  }

  addMassGenderCosting() {
    let latest = this.massGroupCostings[this.massGroupCostings.length - 1];
    this.intakeBatch.transport[0].costing.push({ type: ["male", "mass"], min: latest.male.max }, { type: ["female", "mass"], min: latest.female.max });
    this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].costing", value: this.intakeBatch.transport[0].costing });
    this.$forceUpdate();
  }

  removeMassGenderCosting(index: number) {
    //index 0 then remove 0 and 1
    //index 1 then remove 2 and 3
    //index 2 then remove 4 and 5
    //index i then remove (2*i) and (2*i)+1
    let costing = this.intakeBatch.transport[0].costing;
    costing.splice(2 * index, 2);
    this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].costing", value: costing });
    this.$forceUpdate();
  }

  calculateAnimalSupplierMass() {
    if (!this.supplierWeighbridgeMass) {
      this.animals = this.animals.map((a) => {
        return { ...a, calcSupplierMass: "N/A" };
      });
      return;
    }

    let totalMass = this.animals.map((a) => a.mass).reduce((acc, cur) => acc + cur, 0);
    if (totalMass === 0) return;
    this.animals = this.animals.map((a) => {
      return { ...a, calcSupplierMass: parseFloat(((a.mass / totalMass) * (this.supplierWeighbridgeMass as number)).toFixed(2)) };
    });
  }

  purchasePriceTypeItems: any[] = [
    { description: "A - Single Purchase Price", type: "single-price" },
    { description: "B - Male and Female Purchase Prices", type: "gender-price" },
    { description: "C - Purchase Price per Mass Group", type: "mass-price" },
  ];

  get massGroupCostings() {
    if (this.intakeBatch.transport[0].costing.length % 2 !== 0) throw Error("mass-price costings must be an even number (male female pairs)");

    let ret = [];
    for (let i = 0; i < this.intakeBatch.transport[0].costing.length; i += 2) {
      ret.push({ male: this.intakeBatch.transport[0].costing[i], female: this.intakeBatch.transport[0].costing[i + 1] });
    }
    return ret;
  }
  c = 0;
  get massGroupTotals() {
    let ret = [];

    for (let cost of this.massGroupCostings) {
      let maleTotal = this.animals.filter((a) => {
        if (!cost.male || cost.male.min === undefined || cost.male.max === undefined) return false;
        return cost.male.min < a.mass && a.mass <= cost.male.max && "male" === a.gender.toLowerCase();
      }).length;

      let femaleTotal = this.animals.filter((a) => {
        if (!cost.female || cost.female.min === undefined || cost.female.max === undefined) return false;
        return (cost.female.min as number) < a.mass && a.mass <= (cost.female.max as number) && "female" === a.gender.toLowerCase();
      }).length;

      ret.push({ male: maleTotal, female: femaleTotal, total: maleTotal + femaleTotal });
    }

    return ret;
  }

  purchasePriceTypeChange(purchasePriceType: any) {
    console.log(JSON.stringify(purchasePriceType));
    switch (purchasePriceType.type) {
      case "single-price": {
        console.log("single price");
        if (this._calculatePurchasePriceType().type === "single-price") break;
        //this.intakeBatch.transport[0].costing = [{ type: ["single"] }];
        let costing = [{ type: ["single"] }];
        this.intakeBatch.transport[0].costing = costing;
        this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].costing", value: costing });
        break;
      }
      case "gender-price": {
        console.log("gender price");
        if (this._calculatePurchasePriceType().type === "gender-price") break;
        let costing = [{ type: ["male"] }, { type: ["female"] }];
        this.intakeBatch.transport[0].costing = costing;
        this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].costing", value: costing });
        break;
      }
      case "mass-price": {
        console.log("mass price");
        if (this._calculatePurchasePriceType().type === "mass-price") break;
        /*
          index 0 male, index 1 female, both on first (0th) row
          index 2 male, index 3 female, both on second row
          etc...
          Math.floor(index/2) will give row num
        */

        let costing: Models.Costing[] = [
          { type: ["male", "mass"], min: 0 },
          { type: ["female", "mass"], min: 0 },
        ];
        this.intakeBatch.transport[0].costing = costing;
        this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].costing", value: costing });
        break;
      }
    }

    this.purchasePriceType = purchasePriceType;
    this.calculatePurchasePrice();
  }

  purchasePriceType: any = "";
  purchasePrice: number | "" = "";
  purchasePriceMale: number | "" = "";
  purchasePriceFemale: number | "" = "";
  averagePricePerKg: any = "N/A";

  calculatePurchasePrice() {
    console.log("calculatePurchasePrice()");
    if (!this.purchasePriceType) {
      this.purchasePrice = "";
      this.purchasePriceMale = "";
      this.purchasePriceFemale = "";
    }
    console.log("purchasePriceType.type: " + JSON.stringify(this.purchasePriceType.type));
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return;
    let costing = this.intakeBatch.transport[0].costing;
    switch (this.purchasePriceType.type) {
      case "single-price": {
        let totalMass = this.animals.map((a) => a.mass).reduce((acc, cur) => acc + cur, 0);
        let totalMaleMass = this.animals
          .filter((a) => a.gender.toLowerCase() === "male")
          .map((a) => a.mass)
          .reduce((acc, cur) => acc + cur, 0);
        let totalFemaleMass = this.animals
          .filter((a) => a.gender.toLowerCase() === "female")
          .map((a) => a.mass)
          .reduce((acc, cur) => acc + cur, 0);
        if (!costing[0] || costing[0].cost === undefined) {
          this.purchasePrice = "";
          this.purchasePriceMale = "";
          this.purchasePriceFemale = "";
          this.averagePricePerKg = "N/A";
          this.animals = this.animals.map((a) => {
            return { ...a, calcPricePerKg: "N/A", calcPrice: "N/A" };
          });
          return;
        }
        this.purchasePrice = costing[0].cost * totalMass;
        this.purchasePriceMale = costing[0].cost * totalMaleMass;
        this.purchasePriceFemale = costing[0].cost * totalFemaleMass;
        this.averagePricePerKg = costing[0].cost;
        this.animals = this.animals.map((a) => {
          return { ...a, calcPricePerKg: costing[0].cost as number, calcPrice: (costing[0].cost as number) * a.mass };
        });
        break;
      }
      case "gender-price": {
        let males = this.animals.filter((a) => a.gender.toLowerCase() === "male");
        let totalMaleMass = males.map((a) => a.mass).reduce((acc, cur) => acc + cur, 0);
        let maleCost = costing.find((c) => c.type.includes("male"));
        if (!maleCost || maleCost.cost === undefined) {
          this.purchasePrice = "";
          this.purchasePriceMale = "";
          this.purchasePriceFemale = "";
          this.averagePricePerKg = "N/A";
          this.animals = this.animals.map((a) => {
            return { ...a, calcPricePerKg: "N/A", calcPrice: "N/A" };
          });
          return;
        }
        let females = this.animals.filter((a) => a.gender.toLowerCase() === "female");
        let totalFemaleMass = females.map((a) => a.mass).reduce((acc, cur) => acc + cur, 0);
        let femaleCost = costing.find((c) => c.type.includes("female"));
        if (!femaleCost || femaleCost.cost === undefined) {
          this.purchasePrice = "";
          this.purchasePriceMale = "";
          this.purchasePriceFemale = "";
          this.averagePricePerKg = "N/A";
          this.animals = this.animals.map((a) => {
            return { ...a, calcPricePerKg: "N/A", calcPrice: "N/A" };
          });
          return;
        }

        this.purchasePrice = maleCost.cost * totalMaleMass + femaleCost.cost * totalFemaleMass;
        this.purchasePriceMale = maleCost.cost * totalMaleMass;
        this.purchasePriceFemale = femaleCost.cost * totalFemaleMass;

        this.averagePricePerKg = (maleCost.cost + femaleCost.cost) / 2.0;
        this.animals = this.animals.map((a) => {
          let cost = a.gender.toLowerCase() === "male" ? (maleCost as Models.Costing).cost : (femaleCost as Models.Costing).cost;
          return { ...a, calcPricePerKg: cost as number, calcPrice: (cost as number) * a.mass };
        });
        console.log("totalMaleMass: " + JSON.stringify(totalMaleMass));
        console.log("maleCost: " + JSON.stringify(maleCost));
        console.log("totalFemaleMass: " + JSON.stringify(totalFemaleMass));
        console.log("femaleCost: " + JSON.stringify(femaleCost));
        break;
      }
      case "mass-price": {
        this.purchasePrice = "";
        this.purchasePriceMale = "";
        this.purchasePriceFemale = "";
        this.averagePricePerKg = "N/A";

        this.animals = this.animals.map((a) => {
          //let cost = a.gender.toLowerCase() === "male" ? (maleCost as Models.Costing).cost : (femaleCost as Models.Costing).cost;
          let calcPricePerKg: any = "N/A";
          let calcPrice: any = "N/A";

          let cost = costing.find((c, index) => {
            if (c.min === undefined) return false;
            if (c.max === undefined) return false;
            //c.max must be equal to next cost's c.min (first c.min is 0)
            let ret = c.min < a.mass && a.mass <= c.max && c.type.find((t) => t.toLowerCase() === a.gender.toLowerCase());
            //if (ret) console.log("index % 2: " + (index % 2));
            return ret;
          });

          if (cost?.cost) {
            //console.log("costy costoky: " + JSON.stringify(cost));
            calcPricePerKg = cost.cost as number;
            calcPrice = (cost.cost as number) * a.mass;
          }

          return { ...a, calcPricePerKg, calcPrice };
        });

        //if animals has no calcPrice that is "N/A" then set this.purchasePrice and this.averagePricePerKg
        if (this.animals.find((a) => a.calcPricePerKg === "N/A" || a.calcPrice === "N/A")) {
          this.purchasePrice = "";
          this.averagePricePerKg = "N/A";
        } else {
          let o = { count: 0, calcPricePerKgTotal: 0, calcPriceTotal: 0, calcMalePriceTotal: 0, calcFemalePriceTotal: 0 };
          this.animals.forEach((a) => {
            o.count++;
            o.calcPricePerKgTotal += a.calcPricePerKg;
            o.calcPriceTotal += a.calcPrice;
            if (a.gender.toLowerCase() === "male") o.calcMalePriceTotal += a.calcPrice;
            else if (a.gender.toLowerCase() === "female") o.calcFemalePriceTotal += a.calcPrice;
          });
          this.purchasePrice = o.calcPriceTotal;
          this.purchasePriceMale = o.calcMalePriceTotal;
          this.purchasePriceFemale = o.calcFemalePriceTotal;
          if (o.count > 0) this.averagePricePerKg = o.calcPricePerKgTotal / o.count;
          else this.averagePricePerKg = "N/A";
        }

        break;
      }
    }
  }

  calculatePurchasePriceType() {
    this.purchasePriceType = this._calculatePurchasePriceType();
  }

  _calculatePurchasePriceType() {
    let purchasePriceType;
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length || !this.intakeBatch.transport[0].costing) {
      purchasePriceType = "";
      return purchasePriceType;
    }
    let singlePrice = lodash.find(this.intakeBatch.transport[0].costing, (c) => c.type.includes("single"));
    let massPrice = lodash.find(this.intakeBatch.transport[0].costing, (c) => c.type.includes("mass"));
    let malePrice = lodash.find(this.intakeBatch.transport[0].costing, (c) => c.type.includes("male"));
    let femalePrice = lodash.find(this.intakeBatch.transport[0].costing, (c) => c.type.includes("female"));
    if (singlePrice) purchasePriceType = lodash.find(this.purchasePriceTypeItems, (p) => p.type === "single-price");
    else if (massPrice) purchasePriceType = lodash.find(this.purchasePriceTypeItems, (p) => p.type === "mass-price");
    else if (malePrice) purchasePriceType = lodash.find(this.purchasePriceTypeItems, (p) => p.type === "gender-price");
    else purchasePriceType = "";
    return purchasePriceType;
  }

  async refresh() {
    this.refreshButtonState = "busy";
    console.log("refresh " + this.intakeBatch.guid);
    if (!this.intakeBatch.guid) {
      this.refreshButtonState = "error";
      return;
    }
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.processingResults,
          variables: { guid: this.$store.state.user.location.guid, batchGuid: this.intakeBatch.guid },
        },
        { root: true }
      );
      //console.log("graphQL: " + JSON.stringify(json));
      if (!json.data || !json.data.processingResults) throw Error("No processing results returned from query");
      let processingResults: Models.ProcessingResult[] = json.data.processingResults;
      let animals: any[] = [];
      for (let processingResult of processingResults) {
        let i = 0;

        json = await this.$store.dispatch(
          "graphQl",
          {
            gql: Models.gql.queries.processedAnimals,
            variables: { guid: this.$store.state.user.location.guid, processingResultGuid: processingResult.guid },
          },
          { root: true }
        );

        let processedAnimals: Models.ProcessedAnimal[] = [...json.data.processedAnimals, ...processingResult.processedAnimals];

        //TODO: get animals using processingResult.guid
        //for (let animal of processingResult.processedAnimals) {
        for (let animal of processedAnimals) {
          animals.unshift({
            numInQueue: ++i,
            guid: animal.guid,
            sgtin: animal.sgtin,
            //visualTag: animal.sgtin ? animal.sgtin[36] + animal.sgtin.slice(-5) : "",
            visualTag: animal.sgtin ? (animal.sgtin[36] + animal.sgtin.slice(-5)).replace(/^0+/, "") : "",
            time: this.moment(animal.time).format("YYYY-MM-DD HH:mm:ss"),
            mass: animal.mass,
            calcSupplierMass: "TODO",
            calcPricePerKg: "TODO",
            calcPrice: "TODO",
            gender: animal.gender,
            breed: animal.breed,
            kraalType: animal.sorting && animal.sorting.destination ? animal.sorting.destination.type : "N/A",
            kraalId: animal.sorting && animal.sorting.destination ? animal.sorting.destination.kraalId : "N/A",
            isSick: animal.vaccinations.find((dav) => dav.description === "sick") ? "Y" : "",
            vaccination:
              animal.vaccinations && animal.vaccinations.length && animal.vaccinations[0].vaccination
                ? animal.vaccinations[0].vaccination.vaccination.description
                : "N/A",
          });
        }
      }
      this.animals = animals;
      this.calculateAnimalSupplierMass();
      this.calculatePurchasePrice();
      this.refreshButtonState = "success";
      //this.$store.commit("updateUserLocationField", { path: "producers", value: json.data.producers });
    } catch (err) {
      this.refreshButtonState = "error";
      console.log(err);
      throw err;
    }
  }

  // transporters: Models.Transporter[] = [];
  // buyers: Models.Buyer[] = [];
  // producers: Models.Producer[] = [];
  // creditors: Models.Creditor[] = [];

  async mounted() {
    console.log("mounted");
    console.log(JSON.stringify(this.intakeBatch));
    this.animals = [];
    this.refresh();
    this.calculatePurchasePriceType();
    this.calculatePurchasePrice();

    this.getAllIncomingBatches();
    this.getSelectedIncomingBatches();
  }

  get intakeBatch(): Models.IntakeBatch {
    const ret: Models.IntakeBatch = this.$store.getters["storage"]().intakeBatch;
    if (!ret.incomingBatchGuids) ret.incomingBatchGuids = [];
    return ret;
  }

  ////////
  // Buyers

  buyerListItems: { guid: string; text: string }[] = [];
  selectedBuyerListItem: { guid: string; text: string } | "" = "";
  getBuyerListItemsButtonState: ButtonState = "ready";
  getSelectedBuyerButtonState: ButtonState = "ready";

  get modelSelectedBuyerListItem(): { guid: string; text: string } | "" {
    return this.selectedBuyerListItem;
  }

  set modelSelectedBuyerListItem(selectedBuyerListItem: { guid: string; text: string } | "") {
    if (selectedBuyerListItem === undefined) selectedBuyerListItem = "";

    if (this.selectedBuyerListItem && selectedBuyerListItem && selectedBuyerListItem.guid && this.selectedBuyerListItem.guid === selectedBuyerListItem.guid)
      return; // if same already selected then do nothing

    this.selectedBuyerListItem = selectedBuyerListItem;
    this.getBuyerFromServer();
  }

  async getBuyerFromServer() {
    if (!this.selectedBuyerListItem || !this.selectedBuyerListItem.guid) return;
    try {
      this.getSelectedBuyerButtonState = "busy";
      const buyer = await getItemFromServer("Buyer", this.selectedBuyerListItem.guid);
      this.buyer = buyer;
      this.getSelectedBuyerButtonState = "success";
    } catch (err) {
      this.getSelectedBuyerButtonState = "error";
      console.error(err);
      this.buyer = "";
    }
  }

  async getBuyerListItems() {
    try {
      this.getBuyerListItemsButtonState = "busy";
      this.buyerListItems = [];
      let items = await listBuyer(true);
      this.buyerListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.buyerListItems = this.buyerListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getBuyerListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getBuyerListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.buyer && !this.buyerListItems.find((c) => c.guid === (this.buyer as Models.Buyer).guid)) {
      const b = this.buyer as Models.Buyer;
      this.buyerListItems.push({ guid: b.guid, text: b.name });
    }
  }

  get buyer(): Models.Buyer | "" {
    let ret: Models.Buyer | "" =
      this.intakeBatch && this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].buyer
        ? this.intakeBatch.transport[0].buyer
        : "";
    return ret;
  }

  set buyer(buyer: Models.Buyer | "") {
    this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].buyer", value: buyer === "" ? undefined : buyer });
  }

  // Buyers
  ////////
  // Creditors

  creditorListItems: { guid: string; text: string }[] = [];
  selectedCreditorListItem: { guid: string; text: string } | "" = "";
  getCreditorListItemsButtonState: ButtonState = "ready";
  getSelectedCreditorButtonState: ButtonState = "ready";

  get modelSelectedCreditorListItem(): { guid: string; text: string } | "" {
    return this.selectedCreditorListItem;
  }

  set modelSelectedCreditorListItem(selectedCreditorListItem: { guid: string; text: string } | "") {
    if (selectedCreditorListItem === undefined) selectedCreditorListItem = "";

    if (
      this.selectedCreditorListItem &&
      selectedCreditorListItem &&
      selectedCreditorListItem.guid &&
      this.selectedCreditorListItem.guid === selectedCreditorListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedCreditorListItem = selectedCreditorListItem;
    this.getCreditorFromServer();
  }

  async getCreditorFromServer() {
    if (!this.selectedCreditorListItem || !this.selectedCreditorListItem.guid) return;
    try {
      this.getSelectedCreditorButtonState = "busy";
      const creditor = await getItemFromServer("Creditor", this.selectedCreditorListItem.guid);
      this.creditor = creditor;
      this.getSelectedCreditorButtonState = "success";
    } catch (err) {
      this.getSelectedCreditorButtonState = "error";
      console.error(err);
      this.creditor = "";
    }
  }

  async getCreditorListItems() {
    try {
      this.getCreditorListItemsButtonState = "busy";
      this.creditorListItems = [];
      let items = await listCreditor(true);
      this.creditorListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.getCreditorListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getCreditorListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.creditor && !this.creditorListItems.find((c) => c.guid === (this.creditor as Models.Creditor).guid)) {
      const c = this.creditor as Models.Creditor;
      this.creditorListItems.push({ guid: c.guid, text: c.name });
    }

    this.creditorListItems.sort((i1, i2) => (i1.text < i2.text ? -1 : 1));
  }

  get creditor(): Models.Creditor | "" {
    let ret: Models.Creditor | "" =
      this.intakeBatch && this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].creditor
        ? this.intakeBatch.transport[0].creditor
        : "";
    return ret;
  }

  set creditor(creditor: Models.Creditor | "") {
    this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].creditor", value: creditor === "" ? undefined : creditor });
  }

  // Creditors
  ////////
  // Custom Feeders

  customFeederListItems: { guid: string; text: string }[] = [];
  selectedCustomFeederListItem: { guid: string; text: string } | "" = "";
  getCustomFeederListItemsButtonState: ButtonState = "ready";
  getSelectedCustomFeederButtonState: ButtonState = "ready";

  get modelSelectedCustomFeederListItem(): { guid: string; text: string } | "" {
    return this.selectedCustomFeederListItem;
  }

  set modelSelectedCustomFeederListItem(selectedCustomFeederListItem: { guid: string; text: string } | "") {
    if (selectedCustomFeederListItem === undefined) selectedCustomFeederListItem = "";

    if (
      this.selectedCustomFeederListItem &&
      selectedCustomFeederListItem &&
      selectedCustomFeederListItem.guid &&
      this.selectedCustomFeederListItem.guid === selectedCustomFeederListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedCustomFeederListItem = selectedCustomFeederListItem;
    this.getCustomFeederFromServer();
  }

  async getCustomFeederFromServer() {
    if (!this.selectedCustomFeederListItem || !this.selectedCustomFeederListItem.guid) return;
    try {
      this.getSelectedCustomFeederButtonState = "busy";
      const customFeeder = await getItemFromServer("CustomFeeder", this.selectedCustomFeederListItem.guid);
      this.customFeeder = customFeeder;
      this.getSelectedCustomFeederButtonState = "success";
    } catch (err) {
      this.getSelectedCustomFeederButtonState = "error";
      console.error(err);
      this.customFeeder = "";
    }
  }

  async getCustomFeederListItems() {
    try {
      this.getCustomFeederListItemsButtonState = "busy";
      this.customFeederListItems = [];
      let items = await listCustomFeeder(true);
      this.customFeederListItems = items.map((i: any) => ({ guid: i.guid, text: `${i.name}${i.companyName ? ` - ${i.companyName}` : ``}` }));
      this.customFeederListItems = this.customFeederListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getCustomFeederListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getCustomFeederListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.customFeeder && !this.customFeederListItems.find((c) => c.guid === (this.customFeeder as Models.CustomFeeder).guid)) {
      const cf = this.customFeeder as Models.CustomFeeder;
      this.customFeederListItems.push({ guid: cf.guid, text: cf.name });
    }
  }

  get customFeeder(): Models.CustomFeeder | "" {
    let ret: Models.CustomFeeder | "" =
      this.intakeBatch && this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].customFeeder
        ? this.intakeBatch.transport[0].customFeeder
        : "";
    return ret;
  }

  set customFeeder(customFeeder: Models.CustomFeeder | "") {
    // if (customFeeder) {
    //   (this.$refs["input-owner"] as any).error = false;
    //   (this.$refs["input-owner"] as any).errorMessages = [];
    // } else {
    //   (this.$refs["input-owner"] as any).error = false;
    //   (this.$refs["input-owner"] as any).errorMessages = ["Required."];
    // }
    this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].customFeeder", value: customFeeder === "" ? undefined : customFeeder });
  }

  // Custom Feeders
  ////////
  // Producers

  producerListItems: { guid: string; text: string }[] = [];
  selectedProducerListItem: { guid: string; text: string } | "" = "";
  getProducerListItemsButtonState: ButtonState = "ready";
  getSelectedProducerButtonState: ButtonState = "ready";

  get modelSelectedProducerListItem(): { guid: string; text: string } | "" {
    return this.selectedProducerListItem;
  }

  set modelSelectedProducerListItem(selectedProducerListItem: { guid: string; text: string } | "") {
    if (selectedProducerListItem === undefined) {
      selectedProducerListItem = "";
      this.producerCompanies = [];
      this.producerCompany = "";
    }

    if (
      this.selectedProducerListItem &&
      selectedProducerListItem &&
      selectedProducerListItem.guid &&
      this.selectedProducerListItem.guid === selectedProducerListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedProducerListItem = selectedProducerListItem;
    this.getProducerFromServer();
  }

  async getProducerFromServer() {
    if (!this.selectedProducerListItem || !this.selectedProducerListItem.guid) return;
    try {
      this.getSelectedProducerButtonState = "busy";
      const producer = await getItemFromServer("Producer", this.selectedProducerListItem.guid);
      this.producer = producer;
      this.getSelectedProducerButtonState = "success";
    } catch (err) {
      this.getSelectedProducerButtonState = "error";
      console.error(err);
      this.producer = "";
    }
  }

  async getProducerListItems() {
    try {
      this.getProducerListItemsButtonState = "busy";
      this.producerListItems = [];
      let items = await listProducer(true);
      this.producerListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.producerListItems = this.producerListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getProducerListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getProducerListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.producer && !this.producerListItems.find((c) => c.guid === (this.producer as Models.Producer).guid)) {
      const p = this.producer as Models.Producer;
      this.producerListItems.push({ guid: p.guid, text: p.name });
    }
  }

  get producer(): Models.Producer | "" {
    let ret: Models.Producer | "" =
      this.intakeBatch && this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].producer
        ? this.intakeBatch.transport[0].producer
        : "";
    return ret;
  }

  set producer(producer: Models.Producer | "") {
    if (producer === undefined) producer = "";
    // if (this.producer && producer && this.producer.guid === producer.guid) {
    //   return;
    // }
    this.$store.commit("updateUserLocationField", {
      path: "intakeBatch.transport[0].producer",
      value: producer === "" ? undefined : lodash.cloneDeep(producer),
    });
    if (producer) {
      this.producerCompanies = lodash.cloneDeep(producer.companies);
      if (this.producerCompany && !lodash.find(this.producerCompanies, { guid: this.producerCompany.guid })) this.producerCompany = "";
    } else {
      this.producerCompanies = [];
      this.producerCompany = "";
    }
  }

  producerCompanies: Models.ProducerCompany[] = [];

  set producerCompany(producerCompany: Models.ProducerCompany | "") {
    this.$store.commit("updateUserLocationField", { path: "selectedProducerCompany", value: producerCompany ? producerCompany : undefined });
  }

  get producerCompany(): Models.ProducerCompany | "" {
    const producerCompany = this.$store.getters["getUserLocationField"]("selectedProducerCompany");
    return producerCompany ? producerCompany : "";
  }

  // Producers
  ////////
  // Transporters, Drivers

  transporterListItems: { guid: string; text: string }[] = [];
  selectedTransporterListItem: { guid: string; text: string } | "" = "";
  getTransporterListItemsButtonState: ButtonState = "ready";
  getSelectedTransporterButtonState: ButtonState = "ready";

  get modelSelectedTransporterListItem(): { guid: string; text: string } | "" {
    return this.selectedTransporterListItem;
  }

  set modelSelectedTransporterListItem(selectedTransporterListItem: { guid: string; text: string } | "") {
    if (selectedTransporterListItem === undefined) {
      selectedTransporterListItem = "";
      this.drivers = [];
      this.driver = "";
    }

    if (
      this.selectedTransporterListItem &&
      selectedTransporterListItem &&
      selectedTransporterListItem.guid &&
      this.selectedTransporterListItem.guid === selectedTransporterListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedTransporterListItem = selectedTransporterListItem;
    this.getTransporterFromServer();
  }

  async getTransporterFromServer() {
    if (!this.selectedTransporterListItem || !this.selectedTransporterListItem.guid) return;
    try {
      this.getSelectedTransporterButtonState = "busy";
      const transporter = await getItemFromServer("Transporter", this.selectedTransporterListItem.guid);
      this.transporter = transporter;
      this.getSelectedTransporterButtonState = "success";
    } catch (err) {
      this.getSelectedTransporterButtonState = "error";
      console.error(err);
      this.transporter = "";
    }
  }

  async getTransporterListItems() {
    try {
      this.getTransporterListItemsButtonState = "busy";
      this.transporterListItems = [];
      let items = await listTransporter(true);
      this.transporterListItems = items.map((i: any) => ({ guid: i.guid, text: i.name }));
      this.transporterListItems = this.transporterListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getTransporterListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getTransporterListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.transporter && !this.transporterListItems.find((c) => c.guid === (this.transporter as Models.Transporter).guid)) {
      const t = this.transporter as Models.Transporter;
      this.transporterListItems.push({ guid: t.guid, text: t.name });
    }
  }

  get transporter(): Models.Transporter | "" {
    return this.intakeBatch.transport && this.intakeBatch.transport.length && this.intakeBatch.transport[0].transporter
      ? this.intakeBatch.transport[0].transporter
      : "";
  }

  set transporter(transporter: Models.Transporter | "") {
    this.$store.commit("updateUserLocationField", {
      path: "intakeBatch.transport[0].transporter",
      value: transporter === "" ? undefined : lodash.cloneDeep(transporter),
    });
    if (transporter) {
      this.drivers = lodash.cloneDeep(transporter.drivers);
      if (this.driver && !lodash.find(this.drivers, { guid: this.driver.guid })) this.driver = "";
    } else {
      this.drivers = [];
      this.driver = "";
    }
  }

  drivers: Models.Driver[] = [];

  set driver(driver: Models.Driver | "") {
    this.$store.commit("updateUserLocationField", { path: "selectedDriver", value: driver ? driver : undefined });
  }

  get driver(): Models.Driver | "" {
    const driver = this.$store.getters["getUserLocationField"]("selectedDriver");
    return driver ? driver : "";
  }

  // Transporters, Drivers
  ////////
  // Weighbridges

  get supplierWeighbridgeMass() {
    //console.log("get supplierWeighbridgeMass");
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return "";
    let supplierWeighbridge = lodash.find(this.intakeBatch.transport[0].weighbridges, (o) => o.location === "supplier");
    if (supplierWeighbridge) return supplierWeighbridge.mass;
    else return "";
  }

  set supplierWeighbridgeMass(supplierWeighbridgeMass) {
    //console.log("set supplierWeighbridgeMass");
    let weighbridges = this.intakeBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "supplier" });
    if (supplierWeighbridgeMass === "") supplierWeighbridgeMass = undefined;
    if (i >= 0) {
      weighbridges[i].mass = supplierWeighbridgeMass;
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].weighbridges", value: weighbridges });
    }
    this.calculateAnimalSupplierMass();
    //console.log(supplierWeighbridgeMass);
  }

  get supplierWeighbridgeReference() {
    //console.log("get supplierWeighbridgeReference");
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return "";
    let supplierWeighbridge = lodash.find(this.intakeBatch.transport[0].weighbridges, (o) => o.location === "supplier");
    if (supplierWeighbridge) return supplierWeighbridge.reference;
    else return "";
  }

  set supplierWeighbridgeReference(supplierWeighbridgeReference) {
    //console.log("set supplierWeighbridgeReference");
    let weighbridges = this.intakeBatch.transport[0].weighbridges;
    let i = lodash.findIndex(weighbridges, { location: "supplier" });
    if (supplierWeighbridgeReference === "") supplierWeighbridgeReference = undefined;
    if (i >= 0) {
      weighbridges[i].reference = supplierWeighbridgeReference;
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(supplierWeighbridgeReference);
  }

  get arrivalWeighbridgeMass() {
    //console.log("get arrivalWeighbridgeMass");
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return "";
    let arrivalWeighbridge = lodash.find(this.intakeBatch.transport[0].weighbridges, (o) => o.location === "arrival");
    if (arrivalWeighbridge) return arrivalWeighbridge.mass;
    else return "";
  }

  set arrivalWeighbridgeMass(arrivalWeighbridgeMass) {
    //console.log("set arrivalWeighbridgeMass");
    let weighbridges = this.intakeBatch.transport[0].weighbridges;
    let i = lodash.findIndex(this.intakeBatch.transport[0].weighbridges, { location: "arrival" });
    if (arrivalWeighbridgeMass === "") arrivalWeighbridgeMass = undefined;
    if (i >= 0) {
      weighbridges[i].mass = arrivalWeighbridgeMass;
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(arrivalWeighbridgeMass);
  }

  get arrivalWeighbridgeReference() {
    //console.log("get arrivalWeighbridgeReference");
    if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return "";
    let arrivalWeighbridge = lodash.find(this.intakeBatch.transport[0].weighbridges, (o) => o.location === "arrival");
    if (arrivalWeighbridge) return arrivalWeighbridge.reference;
    else return "";
  }

  set arrivalWeighbridgeReference(arrivalWeighbridgeReference) {
    //console.log("set arrivalWeighbridgeReference");
    let weighbridges = this.intakeBatch.transport[0].weighbridges;
    let i = lodash.findIndex(this.intakeBatch.transport[0].weighbridges, { location: "arrival" });
    if (arrivalWeighbridgeReference === "") arrivalWeighbridgeReference = undefined;
    if (i >= 0) {
      weighbridges[i].reference = arrivalWeighbridgeReference;
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].weighbridges", value: weighbridges });
    }
    //console.log(arrivalWeighbridgeReference);
  }

  // Weighbridges
  ////////
  // Animal group scores

  animalGroupScoreUiKey: number = 0;

  getAnimalScore(type: "condition" | "genetics" | "general-overview") {
    // if (!this.intakeBatch.transport || !this.intakeBatch.transport.length) return "";
    if (!this.intakeBatch.transport.length) return "";
    let animalGroupScore = lodash.find(this.intakeBatch.transport[0].score, (o) => o.type === type);
    // if (animalGroupScore) return animalGroupScore.score;
    // else return "";
    return animalGroupScore?.score ?? "";
  }

  setAnimalScore(type: "condition" | "genetics" | "general-overview", score: string) {
    let scores = this.intakeBatch.transport[0].score;
    let animalGroupScoreIndex = lodash.findIndex(scores, (o) => o.type === type);
    if (animalGroupScoreIndex >= 0) {
      let scores = this.intakeBatch.transport[0].score;
      scores[animalGroupScoreIndex].score = score;
      this.$store.commit("updateUserLocationField", { path: "intakeBatch.transport[0].score", value: scores });
      this.animalGroupScoreUiKey++;
    } else {
      throw Error("group score not found");
    }
  }

  get animalConditionScore() {
    return this.getAnimalScore("condition");
  }

  set animalConditionScore(score) {
    this.setAnimalScore("condition", score);
  }

  get animalGeneticsScore() {
    return this.getAnimalScore("genetics");
  }

  set animalGeneticsScore(score) {
    this.setAnimalScore("genetics", score);
  }

  get animalGeneralOverviewScore() {
    return this.getAnimalScore("general-overview");
  }

  set animalGeneralOverviewScore(score) {
    this.setAnimalScore("general-overview", score);
  }

  // Animal group scores
  ////////
  // Incoming Batches

  get addIncomingBatchButtonState(): ButtonState {
    return this.incomingBatchSelectedFromAvailable.length > 0 ? "ready" : "disabled";
  }

  addIncomingBatch() {
    if (this.incomingBatchSelectedFromAvailable.length === 0) return;

    this.changed = true;

    const [incomingBatch] = this.incomingBatchSelectedFromAvailable;
    this.incomingBatchGuids.push(incomingBatch.guid);
    this.selectedIncomingBatches.push(incomingBatch);
    this.$store.commit("updateUserLocationField", { path: `intakeBatch.incomingBatchGuids`, value: this.incomingBatchGuids });
    this.incomingBatchSelectedFromAvailable = [];
  }

  removeIncomingBatch(incomingBatch: Models.IncomingBatch) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        this.changed = true;

        const index = this.incomingBatchGuids.findIndex((guid) => guid === incomingBatch.guid);
        //remove from this.incomingBatchGuids
        const [deletedGuid] = this.incomingBatchGuids.splice(index, 1);

        //remove from this.selectedIncomingBatches
        const selectedIndex = this.selectedIncomingBatches.findIndex((b) => b && b.guid === deletedGuid);
        if (selectedIndex > -1) this.selectedIncomingBatches.splice(selectedIndex, 1);

        this.$store.commit("updateUserLocationField", {
          path: "intakeBatch.incomingBatchGuids",
          value: this.incomingBatchGuids,
        });
      },
    });
  }

  incomingBatches: Models.IncomingBatch[] = [];
  selectedIncomingBatches: (Models.IncomingBatch | "")[] = [];

  //incoming batch selected from list of available incoming batches
  incomingBatchSelectedFromAvailable: Models.IncomingBatch[] = [];

  set incomingBatchGuids(incomingBatchGuids: string[]) {
    this.intakeBatch.incomingBatchGuids = incomingBatchGuids;
  }

  get incomingBatchGuids() {
    return this.intakeBatch.incomingBatchGuids;
  }

  getIncomingBatchesButtonState: ButtonState = "ready";
  //Gets all incoming batches that can be selected
  async getAllIncomingBatches() {
    this.getIncomingBatchesButtonState = "busy";
    try {
      this.incomingBatches = await getIncomingBatches();
      this.getIncomingBatchesButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getIncomingBatchesButtonState = "error";
    }
  }

  getSelectedIncomingBatchesButtonState: ButtonState = "ready";
  //Gets the incoming batches that have already been selected (we only have their guids we need to fetch the data)
  async getSelectedIncomingBatches() {
    this.getSelectedIncomingBatchesButtonState = "busy";
    try {
      this.selectedIncomingBatches = await getIncomingBatches(this.incomingBatchGuids);
      this.getSelectedIncomingBatchesButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getSelectedIncomingBatchesButtonState = "error";
    }
  }

  // Incoming Batches
  ////////

  save() {
    if (!this.intakeBatch.reference) {
      this.$store.commit("popup/displayOk", `Can not save, Reference required.`);
      return;
    }
    if (!this.intakeBatch.transport[0].quantityExpected) {
      this.$store.commit("popup/displayOk", `Can not save, Quantity Expected required.`);
      return;
    }
    if (!this.intakeBatch.transport[0].customFeeder) {
      this.$store.commit("popup/displayOk", `Can not save, Owner / Custom Feeder required.`);
      return;
    }
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().intakeBatch);
          let intakeBatchClone: Models.IntakeBatch = lodash.cloneDeep(this.$store.getters["storage"]().intakeBatch);
          if (intakeBatchClone && intakeBatchClone.transport && intakeBatchClone.transport.length) {
            let transport = intakeBatchClone.transport[0];
            if ((transport.quantityExpected as any) === "") transport.quantityExpected = undefined;
            if ((transport.quantityAlive as any) === "") transport.quantityAlive = undefined;
            if ((transport.quantityDead as any) === "") transport.quantityDead = undefined;
          }
          if (intakeBatchClone && intakeBatchClone.transport && intakeBatchClone.transport.length && intakeBatchClone.transport[0].transporter && this.driver) {
            intakeBatchClone.transport[0].transporter.drivers = [this.driver];
          }
          if (
            intakeBatchClone &&
            intakeBatchClone.transport &&
            intakeBatchClone.transport.length &&
            intakeBatchClone.transport[0].producer &&
            this.producerCompany
          ) {
            intakeBatchClone.transport[0].producer.companies = [this.producerCompany];
          }

          //Filter out any empty incomingBatchGuids
          intakeBatchClone.incomingBatchGuids = intakeBatchClone.incomingBatchGuids.filter((s) => s);

          intakeBatchClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation intakeBatch($guid: String!, $intakeBatch: IntakeBatchInput!) {
                intakeBatch(guid: $guid, intakeBatch: $intakeBatch) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, intakeBatch: intakeBatchClone },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let intakeBatch = this.$store.getters["storage"]().intakeBatch as Models.IntakeBatch;
          intakeBatch.changeTracking = json.data.intakeBatch;
          //await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //this.$router.push({ path: "list/intake-batches" });
          this.changed = false;
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "intakeBatch", value: {} });
        this.$router.push({ path: "list/intake-batches" });
      },
    });
  }
}
